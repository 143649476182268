import React from 'react';
import Chart from 'react-apexcharts';

const TotalsBarChart = ({ data }) => {
  const { single, double, trixie, patent, lucky15, total } = data;

  const series = [
    {
      name: '£/Placed',
      data: [
        single.placed,
        double.placed,
        trixie.placed,
        patent.placed,
        lucky15.placed,
        total.placed,
      ],
    },
    {
      name: '£/Winnings',
      data: [
        single.winnings,
        double.winnings,
        trixie.winnings,
        patent.winnings,
        lucky15.winnings,
        total.winnings,
      ],
    },
  ];

  const options = {
    chart: {
      height: '100%',
      backgrounhd: '#f4f4f4',
      foreColor: document.body.classList.toString().includes('white-content')
        ? 'black'
        : 'white',
    },
    xaxis: {
      categories: ['Single', 'Double', 'Trixie', 'Patent', 'Lucky15', 'Total'],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: 'top',
        },
      },
      dataLabels: {},
    },
    fill: { colors: ['#1C8680', '#1C8680'] },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        colors: document.body.classList.toString().includes('white-content')
          ? ['black']
          : ['white'],
      },
    },
    // title: {
    //   text: 'Bet Summary',
    //   align: 'center',
    //   margin: 20,
    //   offsetY: 20,
    //   style: { fontSize: '25px' },
    // },
    legend: { show: false },
    stroke: {
      show: true,
      width: 1,
      colors: ['#333333'],
    },
  };

  return <Chart options={options} series={series} type="bar" height="400" />;
};

export default TotalsBarChart;
