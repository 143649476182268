import React, { useState } from 'react';
import subDays from 'date-fns/subDays';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import moment from 'moment';
import { Auth } from 'aws-amplify';
import axiosRetry from 'axios-retry';
import NotificationAlert from 'react-notification-alert';

import {
  ButtonGroup,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Spinner,
} from 'reactstrap';
import { CSVLink } from 'react-csv';
import 'react-datepicker/dist/react-datepicker.css';

import { bookieListGenerator } from 'components/dropdownGenerator';
import { syndicateListGenerator } from 'components/dropdownGenerator';
const syndArr = syndicateListGenerator();
const bookieArr = bookieListGenerator('analytics');

import Select from 'react-select';

axiosRetry(axios, { retries: 10 });

const SyndProfitReport = () => {
  const [startDate, setStartDate] = useState(new Date(subDays(new Date(), 2)));
  const [endDate, setEndDate] = useState(new Date(subDays(new Date(), 1)));
  const [selectBookie, setSelectBookie] = useState('');
  const [selectSyndicate, setSelectSyndicate] = useState([]);
  const formattedStart = moment(startDate).format('YYYY-MM-DD');
  const formattedEnd = moment(endDate).format('YYYY-MM-DD');
  const [loading, setLoading] = useState(false);
  const [sourceReportData, setSourceReportData] = useState([]);
  const notificationAlertRef = React.useRef(null);
  const days = moment(endDate).diff(moment(startDate), 'days');

  const notify = (place, msg) => {
    let colour = 'danger';
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>Error: {msg}</div>
        </div>
      ),
      type: colour,
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const addAll = () =>
    setSelectSyndicate(() => {
      const list = syndArr.map((s) => {
        return { value: s, label: s };
      });
      return list;
    });

  const addAllSyndicatesButton = () => {
    return (
      <Button
        onClick={() => {
          addAll();
        }}
      >
        Retrieve All Syndicates
      </Button>
    );
  };
  const bookieSelectOptions = () => {
    const selectOptions = bookieArr.sort().map((bookie) => {
      return { value: bookie, label: bookie };
    });
    return selectOptions;
  };

  const syndicateSelectOptions = () => {
    const selectOptions = syndArr.sort().map((syndicate) => {
      return { value: syndicate, label: syndicate };
    });
    return selectOptions;
  };

  const loadingSpinner = loading ? (
    <>
      {' '}
      <Spinner size="sm"></Spinner>
    </>
  ) : null;

  const tableHeaders = [
    { label: 'Partner', key: 'synd' },
    { label: '#Bets', key: 'bets' },
    { label: 'Stake', key: 'stake' },
    { label: 'Winnings', key: 'winnings' },
    { label: 'Profit', key: 'profit' },
  ];

  const syndReportSubmit =
    selectSyndicate.length > 0 ? (
      <>
        <Button
          color="default"
          onClick={async () => {
            setLoading(true);

            const user = await Auth.currentSession();
            const token = user.getIdToken().getJwtToken();
            const array = selectSyndicate.map((item) => {
              return item.label;
            });
            if (days > 31 && array.length > 1) {
              notify(
                'br',
                'For date ranges over a month only one syndicate may be selected'
              );
              setLoading(false);
              return;
            }
            try {
              const res = await axios.post(
                'https://api.valueservices.uk/reporting/getSyndicateProfitReport',
                {
                  syndArray: array,
                  startDate: formattedStart,
                  endDate: formattedEnd,
                },
                {
                  headers: { Authorization: token },
                }
              );

              setSourceReportData(res.data);
            } catch (e) {
              console.log(e);
              notify('br', e.message);
            }

            setLoading(false);
          }}
        >
          Submit Request
          {loadingSpinner}
        </Button>
      </>
    ) : (
      <Button disabled color="default">
        Submit Request
      </Button>
    );

  const reportTable =
    sourceReportData.length === 0 ? (
      <Card>
        <CardBody></CardBody>
      </Card>
    ) : (
      <Card>
        <CardBody>
          <Table className="tablesorter" responsive>
            <thead className="text-primary">
              <tr>
                <th rowSpan={2} width={100}>
                  Partner
                </th>
                <th rowSpan={2} width={100}>
                  #Bets
                </th>
                <th rowSpan={2} width={50}>
                  Total Stake
                </th>
                <th rowSpan={2} width={100}>
                  Total Winnings
                </th>
                <th rowSpan={2} width={100}>
                  Total Profit
                </th>
              </tr>
            </thead>
            <tbody>
              {sourceReportData.map((item) => (
                <tr>
                  <td>{item.synd}</td>
                  <td>{item.bets}</td>
                  <td>{item.stake.toFixed(2)}</td>
                  <td>{item.winnings.toFixed(2)}</td>
                  <td>{item.profit.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );

  const syndReportLink =
    sourceReportData.length > 0 ? (
      <>
        <br />
        <Button>
          <CSVLink
            filename={`${moment(startDate).format('YYYY-MM-DD')}_${moment(
              endDate
            ).format('YYYY-MM-DD')}`}
            data={sourceReportData}
            headers={tableHeaders}
          >
            {`Download Report`}
          </CSVLink>
        </Button>
      </>
    ) : (
      <>
        <br />
        <Button disabled color="default">
          Download Report
        </Button>
      </>
    );

  const dateRangePicker = (
    <>
      <div>Start Date:</div>
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        showYearDropdown
        scrollableYearDropdown
        dateFormat="dd/MM/yyyy"
      />

      <div>End Date:</div>
      <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        maxDate={endDate}
        showYearDropdown
        scrollableYearDropdown
        dateFormat="dd/MM/yyyy"
      />
    </>
  );

  const syndOptions = syndicateSelectOptions();
  const bookieOptions = bookieSelectOptions();

  return (
    <>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  {dateRangePicker}
                  {addAllSyndicatesButton()}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md="4">
                  <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    onChange={(value) => {
                      setSelectSyndicate(value);
                    }}
                    value={selectSyndicate}
                    aria-label="Default select example"
                    placeholder="View Profit report For... (Syndicate)"
                    options={syndOptions}
                    isMulti
                  />
                  <ButtonGroup>
                    {syndReportSubmit}
                    {syndReportLink}
                  </ButtonGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <NotificationAlert ref={notificationAlertRef} />
      </Row>
      <Row>{reportTable}</Row>
    </>
  );
};

export default SyndProfitReport;
