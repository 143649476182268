import React from 'react';
import { useState } from 'react';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
} from 'reactstrap';
import RaceCardModalRow from './RaceCardModalRow';

const RaceCardModal = ({ venue, race, selections, handleChangeRunners }) => {
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(false);

  const rows = selections.map((selection) => {
    return (
      <RaceCardModalRow
        key={selection.selectionId}
        sel={selection}
        handleChangeRunners={handleChangeRunners}
      />
    );
  });
  return (
    <>
      <span
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setOpenModal(true);
        }}
      >
        {race.formattedTime}
      </span>
      <Modal
        isOpen={openModal}
        fade={true}
        toggle={handleClose}
        keyboard={false}
      >
        <ModalHeader>
          <Row>
            <Col>
              <h2 style={{ color: 'black' }}>
                {race.formattedTime} - {venue}
              </h2>
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody>
          <Col>{rows}</Col>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default RaceCardModal;
