import React, { useEffect } from 'react';

import { Button, CustomInput } from 'reactstrap';

const FixedPlugin = (props) => {
  const [classes, setClasses] = React.useState('dropdown');
  const [darkMode, setDarkMode] = React.useState(
    Boolean(localStorage.getItem('darkmode'))
  );
  const handleActiveMode = () => {
    if (!darkMode) {
      localStorage.setItem('darkmode', Boolean(!darkMode));
    } else {
      localStorage.removeItem('darkmode');
    }

    setDarkMode(Boolean(!darkMode));
  };
  useEffect(() => {
    if (darkMode) {
      document.body.classList.remove('white-content');
    } else {
      document.body.classList.add('white-content');
    }
  }, [darkMode]);
  return (
    <CustomInput
      type="switch"
      id="switch-2"
      onChange={handleActiveMode}
      value={darkMode}
      defaultChecked={darkMode}
      className=""
    />
  );
};

export default FixedPlugin;
