import React, { useState, useEffect, useRef } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import moment from 'moment';
import NotificationAlert from 'react-notification-alert';

import DoughnutChartApex from '../components/Charts/DoughnutChartApex';
import RaceCardHorse from '../components/RaceCardHorse/RaceCardHorse';
import TotalsBarChart from '../components/Charts/TotalsBarChart';
import DatePicker from 'react-datepicker';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import ReactTable from '../components/Tables/ReactTableBets';
// core components
import SourceElement from '../components/SourceElement';

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return 0;
  }, [delay]);
};

export const DashBoard = () => {
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [activityData, setActivityData] = useState([]);
  const [runnerData, setRunnerData] = useState([]);

  const [betfairBalance, setBetfairBalance] = useState('');
  const [betfairApiStatus, setBetfairApiStatus] = useState(true);

  const notificationAlertRef = React.useRef(null);

  const notify = (place, msg) => {
    const options = {
      place: place,
      message: (
        <div>
          <div>{msg}</div>
        </div>
      ),
      type: 'danger',
      icon: 'tim-icons icon-coins',
      autoDismiss: 20,
    };
    if (betfairBalance !== '' && betfairBalance < 100) {
      notificationAlertRef.current.notificationAlert(options);
    }
  };

  const sumPlaced = (betArr) => {
    const total = betArr.reduce((accumulator, object) => {
      return accumulator + object.stake;
    }, 0);
    return total.toFixed(2);
  };

  const sumTotals = (betArr) => {
    const filteredArray = betArr.filter((bet) => bet.winnings !== undefined);

    const total =
      filteredArray.length > 0
        ? filteredArray.reduce((accumulator, object) => {
            return accumulator + object.winnings;
          }, 0)
        : 0;

    return total.toFixed(2);
  };

  const formattedDate = moment(selectedDate).format('yyyy-MM-DD');

  const getBetfairBalance = async () => {
    const user = await Auth.currentSession();
    const token = user.getIdToken().getJwtToken();
    const { data: balance } = await axios.get(
      'https://api.valueservices.uk/betfair/getBalance',
      { headers: { Authorization: token } }
    );

    if (balance.balance < 500) {
      notify('tr', 'BETFAIR EXCHANGE BALANCE IS LOW');
    }

    setBetfairBalance(balance.balance);
  };

  const getBetfairAccountKeys = async () => {
    const user = await Auth.currentSession();
    const token = user.getIdToken().getJwtToken();
    const { data } = await axios.get(
      'https://api.valueservices.uk/betfair/getBetfairKeys',
      { headers: { Authorization: token } }
    );

    setBetfairApiStatus(data.delayData);
  };

  const getSourceData = async () => {
    const user = await Auth.currentSession();
    const token = user.getIdToken().getJwtToken();

    const { data: sourceArray } = await axios.get(
      'https://api.valueservices.uk/horsemx/getActivity',
      { headers: { Authorization: token } }
    );

    setActivityData(sourceArray);
  };

  const getRunnerCounts = async () => {
    const user = await Auth.currentSession();
    const token = user.getIdToken().getJwtToken();
    const runnerRes = await axios.get(
      `https://api.valueservices.uk/horsebettingmx/getRacingInfo`,
      { headers: { Authorization: token } }
    );

    setRunnerData(runnerRes.data[0]);
  };

  const getVBs = async () => {
    const user = await Auth.currentSession();
    const token = user.getIdToken().getJwtToken();
    const betres = await axios.get(
      `https://api.valueservices.uk/horsebettingmx/getBets/${formattedDate}`,
      { headers: { Authorization: token } }
    );

    const parsedData = betres.data.map((bet) => {
      // PARSE RESPONSE DATA FOR BET TABLE - MUST ALL BE PLAIN STRING DATA FOR SEARCH FUNCTION TO WORK
      // TO USE JS BREAK LINE ESCAPE CHARACTERS {white-space: pre-line;} MUST BE USED AS A CSS STYLE ON <td>
      const selectionArr = bet.selections.map((sel) => {
        const odds = sel.finalOdds ? sel.finalOdds : sel.expectedOdds;
        const selString = `${sel.eventName}: ${sel.horseName} @${odds} (BF: ${
          sel.atl[0].price
        } EX: ${sel.expectedOdds} VP:${(sel.vp * 100).toFixed(
          0
        )} QS: ${sel.qs.toFixed(0)})\r\n`;
        return `${selString}`;
      });

      const atlArr = bet.selections.map((sel) => {
        const matrix = sel.atl.map((a) => `${a.price}(£${a.size}):`);
        const atlLine = matrix.join('') + `LP:${sel.lp} `;
        const atbLine =
          atlLine + `ATB:${sel.atb[0].price ? sel.atb[0].price : ''}\r\n`;
        // const atlLine = matrix.join(' ') + ` LP:${''}\r\n`;
        return atbLine;
      });

      const resultArr = bet.selections.map((sel) => {
        if (sel.status === 'WINNER') {
          return 'win\n';
        }
        if (sel.status === 'LOSER') {
          return 'lose\n';
        }
        if (sel.status === 'REMOVED') {
          return 'nonrunner\n';
        }
        if (sel.status === 'pending') {
          return 'pending\n';
        }
        return 'deadheat\n';
      });

      return {
        time: moment(bet.createdAt).format('HH:mm:ss'),
        bookie: bet.bookie,
        duration: `${bet.betDuration}`,
        name: bet.name.replace(' ', '\n'),
        betTableName: `${bet.displayName.replace(' ', '\n')}`,
        selections: selectionArr,
        atl: atlArr,
        stake: bet.stake,
        type: bet.type,
        returns: bet.returns,
        reason: bet.reason,
        winnings: bet.winnings,
        result: resultArr,
        status: bet.status,
        message: bet.message,
        // message: "",
      };
    });
    setData(parsedData);
  };

  const placementData = () => {
    const splitArrayEvenly = (array) => {
      const totalItems = array.length;
      const middleIndex = Math.ceil(totalItems / 2);

      const firstHalf = array.slice(0, middleIndex);
      const secondHalf = array.slice(middleIndex);

      return [firstHalf, secondHalf];
    };

    const createTable = (group) => {
      return (
        <Table striped bordered>
          <thead>
            <tr>
              <th>Bookie</th>
              <th>Total</th>
              <th>Placed</th>
              <th>Failed</th>
              <th>Rejected</th>
            </tr>
          </thead>
          <tbody>
            {group.map((row) => {
              const failPercent =
                (row.failedBetsByBookie.length / row.betsByBookie.length) * 100;
              const rejectedPercent =
                (row.rejectedBetsByBookie.length / row.betsByBookie.length) *
                100;
              const placed = row.placedBetsByBookie.length;
              const placedPercent = (placed / row.betsByBookie.length) * 100;
              let failCellColour =
                placed < 6 && failPercent > 50
                  ? 'cell-danger'
                  : placed >= 6 && failPercent > 20
                  ? 'cell-danger'
                  : '';

              let rejectCellColour = rejectedPercent > 50 ? 'cell-warning' : '';

              let placedCellColour = placedPercent >= 50 ? 'cell-placed' : '';

              if (failPercent + rejectedPercent > placedPercent) {
                failCellColour = failPercent > 0 ? 'cell-danger' : '';
                rejectCellColour = rejectedPercent > 0 ? 'cell-warning' : '';
                placedCellColour = '';
              }
              if (failPercent + rejectedPercent < placedPercent) {
                placedCellColour = 'cell-placed';
              }
              return (
                <tr>
                  <td>
                    {row.bookie === 'betfairsportsbook'
                      ? 'betfair'
                      : row.bookie}
                  </td>
                  <td>{row.betsByBookie.length}</td>
                  <td className={placedCellColour}>
                    {row.placedBetsByBookie.length}
                  </td>
                  <td className={failCellColour}>
                    {row.failedBetsByBookie.length}
                  </td>
                  <td className={rejectCellColour}>
                    {row.rejectedBetsByBookie.length}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      );
    };

    if (data && data.length > 0) {
      const filteredBookies = [
        ...new Set(
          data.map((item) => {
            return item.bookie;
          })
        ),
      ].sort();

      const sortedBetsByBookie = filteredBookies.map((bookie) => {
        const betsByBookie = data.filter((item) => item.bookie === bookie);
        const failedBetsByBookie = data.filter(
          (item) => item.bookie === bookie && item.status === 'failed'
        );
        const placedBetsByBookie = data.filter(
          (item) => item.bookie === bookie && item.status === 'placed'
        );
        const rejectedBetsByBookie = data.filter(
          (item) => item.bookie === bookie && item.status === 'rejected'
        );

        return {
          bookie,
          betsByBookie,
          failedBetsByBookie,
          placedBetsByBookie,
          rejectedBetsByBookie,
        };
      });

      const [groupOne, groupTwo] = splitArrayEvenly(sortedBetsByBookie);
      const tableOne = createTable(groupOne);
      const tableTwo = createTable(groupTwo);

      return (
        <>
          <Col md="6">{tableOne}</Col>
          <Col md="6">{tableTwo}</Col>
        </>
      );
    }
    return <></>;
  };

  useEffect(() => {
    getBetfairBalance();
    getBetfairAccountKeys();
  }, []);

  useEffect(() => {
    getSourceData();
    getVBs();
    getRunnerCounts();
  }, [selectedDate]);

  useInterval(async () => {
    getSourceData();
    getVBs();
    getRunnerCounts();
    getBetfairBalance();
  }, 30000);

  //  DATA FILTER FOR BAR CHARTS
  const barSingles = data.filter(
    (bet) => bet.type === 'single' && bet.status === 'placed'
  );
  const barDoubles = data.filter(
    (bet) => bet.type === 'double' && bet.status === 'placed'
  );
  const barTrixies = data.filter(
    (bet) => bet.type === 'trixie' && bet.status === 'placed'
  );
  const barPatents = data.filter(
    (bet) => bet.type === 'patent' && bet.status === 'placed'
  );
  const barLucky15 = data.filter(
    (bet) => bet.type === 'lucky15' && bet.status === 'placed'
  );
  const barTotals = data.filter((bet) => bet.status === 'placed');

  //  DATA FILTER FOR DONUT CHARTS
  const singles = data.filter((bet) => bet.type === 'single');
  const doubles = data.filter((bet) => bet.type === 'double');
  const trixies = data.filter((bet) => bet.type === 'trixie');
  const patents = data.filter((bet) => bet.type === 'patent');
  const lucky15 = data.filter((bet) => bet.type === 'lucky15');

  //CALCULATE VALUES FOR BARCHART
  const columnData = {
    single: { placed: sumPlaced(barSingles), winnings: sumTotals(barSingles) },
    double: {
      placed: sumPlaced(barDoubles),
      winnings: sumTotals(barDoubles),
    },
    trixie: { placed: sumPlaced(barTrixies), winnings: sumTotals(barTrixies) },
    patent: { placed: sumPlaced(barPatents), winnings: sumTotals(barPatents) },
    lucky15: { placed: sumPlaced(barLucky15), winnings: sumTotals(barLucky15) },
    total: { placed: sumPlaced(barTotals), winnings: sumTotals(barTotals) },
  };

  return (
    <>
      <div className="content">
        <NotificationAlert ref={notificationAlertRef} />
        {/* Row Containing RaceCard and Bet Summaray components */}
        <Row>
          <Col xs={12} md={6}>
            <Card style={{ height: '500px' }}>
              <CardBody>
                <CardTitle>Race Card</CardTitle>
                <RaceCardHorse />
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card style={{ height: '500px' }}>
              <CardBody>
                <CardTitle>Bet Summary</CardTitle>
                <TotalsBarChart data={columnData} />
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Donut Chart Row */}
        <Row>
          <Col xs={6} md={4} lg={2}>
            <Card>
              <CardBody>
                <CardTitle>Single</CardTitle>
                <DoughnutChartApex bets={singles} />
              </CardBody>
            </Card>
          </Col>
          <Col xs={6} md={4} lg={2}>
            <Card>
              <CardBody>
                <CardTitle>Double</CardTitle>
                <DoughnutChartApex bets={doubles} />
              </CardBody>
            </Card>
          </Col>
          <Col xs={6} md={4} lg={2}>
            <Card>
              <CardBody>
                <CardTitle>Trixie</CardTitle>
                <DoughnutChartApex bets={trixies} />
              </CardBody>
            </Card>
          </Col>
          <Col xs={6} md={4} lg={2}>
            <Card>
              <CardBody>
                <CardTitle>Patent</CardTitle>
                <DoughnutChartApex bets={patents} />
              </CardBody>
            </Card>
          </Col>
          <Col xs={6} md={4} lg={2}>
            <Card>
              <CardBody>
                <CardTitle>Lucky 15</CardTitle>
                <DoughnutChartApex bets={lucky15} />
              </CardBody>
            </Card>
          </Col>
          <Col xs={6} md={4} lg={2}>
            <Card>
              <CardBody>
                <CardTitle>Total</CardTitle>
                <DoughnutChartApex bets={data} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* DatePicker Row */}
        <Row>
          <Col md="4">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Bet Date</CardTitle>
              </CardHeader>
              <CardBody>
                <DatePicker
                  selected={selectedDate}
                  className="form-control"
                  onChange={(date) => {
                    setSelectedDate(date);
                  }}
                  dateFormat="dd/MM/yyyy"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* Table Component Row */}
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <ReactTable
                  data={data}
                  filterable={false}
                  resizable={false}
                  columns={[
                    {
                      Header: 'Time',
                      accessor: 'time',
                      width: 45,
                      disableFilters: true,
                    },
                    {
                      Header: 'Dur',
                      accessor: 'duration',
                      width: 40,
                      disableFilters: true,
                    },
                    {
                      Header: 'Name',
                      accessor: 'betTableName',
                      width: 50,
                      disableSortBy: true,
                    },
                    {
                      Header: 'Boookie',
                      accessor: 'bookie',
                      width: 50,
                      disableSortBy: true,
                    },
                    {
                      Header: 'Selections',
                      accessor: 'selections',
                      width: 350,
                      disableSortBy: true,
                    },
                    {
                      Header: 'ATL',
                      accessor: 'atl',
                      width: 220,
                      disableFilters: true,
                    },
                    {
                      Header: 'Stake',
                      accessor: 'stake',
                      width: 50,
                      disableFilters: true,
                    },
                    {
                      Header: 'Type',
                      accessor: 'type',
                      width: 55,
                      disableSortBy: true,
                    },
                    {
                      Header: 'Returns',
                      accessor: 'returns',
                      width: 70,
                      disableFilters: true,
                    },
                    {
                      Header: 'Win',
                      accessor: 'winnings',
                      width: 50,
                      disableFilters: true,
                    },
                    {
                      Header: 'Res`',
                      accessor: 'result',
                      width: 60,
                      disableFilters: true,
                    },
                    {
                      Header: 'Status',
                      accessor: 'status',
                      width: 60,
                      disableSortBy: true,
                    },
                    {
                      Header: 'Message',
                      accessor: 'message',
                      width: 150,
                      disableSortBy: true,
                    },
                    {
                      Header: 'Reason',
                      accessor: 'reason',
                      widht: 40,
                      disableSortBy: true,
                    },
                  ]}
                  defaultPageSize={50}
                  showPaginationTop={false}
                  showPaginationBottom={true}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="4">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Betfair Account Info:</CardTitle>
              </CardHeader>
              <CardBody>
                <div>
                  Acccount Balance:<strong>{` £${betfairBalance}`}</strong>
                </div>
                <div>API Key: </div>
                <div>
                  <strong>
                    {betfairApiStatus
                      ? 'DELAYED - Potential Issue'
                      : 'REALTIME - No Delay'}
                  </strong>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Betfair Selection Data:</CardTitle>
              </CardHeader>
              <CardBody>
                <div>
                  Total Selections:{' '}
                  <strong>{`${
                    runnerData && runnerData.totalSelections
                      ? runnerData.totalSelections
                      : ' -'
                  }`}</strong>
                </div>
                <div>
                  Active Runners:{' '}
                  <strong>{`${
                    runnerData && runnerData.activeSelections
                      ? runnerData.activeSelections
                      : ' -'
                  }`}</strong>
                </div>
                <div>
                  Non-Runners:{' '}
                  <strong>{`${
                    runnerData && runnerData.nonRunners
                      ? runnerData.nonRunners
                      : ' -'
                  }`}</strong>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Betfair Event Value: </CardTitle>
              </CardHeader>
              <CardBody>
                <div>
                  Race Count{' '}
                  <strong>{`${
                    runnerData && runnerData.racesEvaluated
                      ? runnerData.racesEvaluated
                      : ' -'
                  }`}</strong>
                </div>
                <div>
                  {' '}
                  Total Matched Value:{' '}
                  <strong>{`£${
                    runnerData && runnerData.totalMatched
                      ? runnerData.totalMatched
                      : ' -'
                  }`}</strong>
                </div>
                <div>
                  {' '}
                  Avg / race:{' '}
                  <strong>{`£${
                    runnerData && runnerData.totalMatchedPerRace
                      ? runnerData.totalMatchedPerRace
                      : ' -'
                  }`}</strong>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Bet Placement Summary</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>{placementData()}</Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="col-md-12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Source Data - {activityData.length} bookies
                </CardTitle>
              </CardHeader>

              <Row>
                {activityData && activityData.length > 0 && (
                  <SourceElement activityData={activityData} />
                )}
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
