import { Auth } from 'aws-amplify';
import axios from 'axios';
import axiosRetry from 'axios-retry';

axiosRetry(axios, { retries: 3 });

export const millisToTime = (millis) => {
  const hour = Math.floor(millis / 3600000);
  const minutes = Math.floor(millis % 3600000) / 60000;
  return (
    hour.toString().padStart(2, 0) + ':' + minutes.toString().padStart(2, 0)
  );
};

export const timeToMillis = (time) => {
  const parsedTime = time.split(':');
  const hourMillis = parseInt(parsedTime[0]) * 3600000;
  const minutesMillis = parseInt(parsedTime[1]) * 60000;
  return hourMillis + minutesMillis;
};

export const axiosPut = async (obj) => {
  const user = await Auth.currentSession();
  const token = user.getIdToken().getJwtToken();

  if (obj.query.bookie) {
    try {
      const res = await axios.put(
        'https://api.value-services.uk/syndicateMX/updateSyndicateAccounts',
        obj,
        {
          headers: {
            Authorization: token,
          },
          timeout: 3000,
        }
      );
      return res;
    } catch (e) {
      return e;
    }
  }
};

export const axiosGet = async (url) => {
  const user = await Auth.currentSession();
  const token = user.getIdToken().getJwtToken();
  try {
    const res = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return res.data;
  } catch (e) {
    return e;
  }
};

export const applyFiltersForPausedAcc = (pausedFilter, data) => {
  let filteredData;
  if (pausedFilter === 'paused') {
    filteredData = data.filter(
      (item) => item.enabled === false && item.type === 'Active'
    );
  }

  if (pausedFilter === 'high') {
    filteredData = data
      .filter((item) => item.balance >= 500)
      .sort((a, b) => b.balance - a.balance);
  }

  if (pausedFilter === 'low') {
    filteredData = data
      .filter((item) => item.balance < 100 && item.type === 'Active')
      .sort((a, b) => a.balance - b.balance);
  }
  return filteredData;
};

export const compareObjects = (obj1, obj2) => {
  ['createdAt', 'updatedAt'].forEach((key) => {
    if (key in obj1) {
      delete obj1[key];
    }

    if (key in obj2) {
      delete obj2[key];
    }
  });

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    console.log('Number of keys does not match');
    return false;
  }

  for (let key of keys1) {
    if (!obj2.hasOwnProperty(key)) {
      console.log(`Key '${key}' is missing in the second object`);
      return false;
    }

    const value1 = obj1[key];
    const value2 = obj2[key];

    if (typeof value1 === 'object' && typeof value2 === 'object') {
      if (!compareObjects(value1, value2)) {
        console.log(`Values for key '${key}' do not match`);
        return false;
      }
    } else if (Array.isArray(value1) && Array.isArray(value2)) {
      if (!compareArrays(value1, value2)) {
        console.log(`Values for key '${key}' do not match`);
        return false;
      }
    } else if (value1 !== value2) {
      console.log(`Values for key '${key}' do not match`);
      return false;
    }
  }

  return true;
};

const compareArrays = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    const element1 = arr1[i];
    const element2 = arr2[i];

    if (typeof element1 === 'object' && typeof element2 === 'object') {
      if (!compareObjects(element1, element2)) {
        return false;
      }
    } else if (element1 !== element2) {
      return false;
    }
  }

  return true;
};

export const findHoneymoonNotes = (arr, string) => {
  if (arr.length === 0) {
    return false;
  }

  // want to ignore honeymoon notes over 30 days old
  const currentDate = new Date();
  const thirtyDays = 30 * 24 * 60 * 60 * 1000;

  return arr.some((element) => {
    const dateString = element.split(' : ')[0].trim();
    const itemDate = new Date(dateString);
    if (currentDate - itemDate <= thirtyDays) {
      return element.toLowerCase().includes(string);
    }
    return false;
  });
};

// disable default scrolling of Input type="number"
export const preventScroll = (e) => {
  e.target.addEventListener(
    'wheel',
    (e) => {
      e.preventDefault();
    },
    { passive: false }
  );
};
