import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import ReactWizard from 'react-bootstrap-wizard';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import {
  Alert,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  Label,
} from 'reactstrap';
import Slider from 'rc-slider';
import { Auth } from 'aws-amplify';

import { bookieListGenerator } from 'components/dropdownGenerator';
const bookieDefaults = bookieListGenerator('createAccounts');

axiosRetry(axios, { retries: 3 });

// time string/milliseconds conversion funcs
const millisToTime = (millis) => {
  const hour = Math.floor(millis / 3600000);
  const minutes = Math.floor(millis % 3600000) / 60000;
  return (
    hour.toString().padStart(2, 0) + ':' + minutes.toString().padStart(2, 0)
  );
};
const timeToMillis = (time) => {
  const parsedTime = time.split(':');
  const hourMillis = parseInt(parsedTime[0]) * 3600000;
  const minutesMillis = parseInt(parsedTime[1]) * 60000;
  return hourMillis + minutesMillis;
};

const FirstStep = forwardRef((props, ref) => {
  const [syndSelect, setSyndSelect] = useState({
    label: 'Syndicate',
    value: '',
  });
  const [syndName, setSyndName] = useState('');
  const [existingOrNot, setExistingOrNot] = useState('existing');
  const [tempSyndName, setTempSyndName] = useState('');

  const [alert, setAlert] = useState(null);
  const [syndList, setSyndList] = useState([]);
  const hideAlert = () => {
    setAlert(null);
  };

  useEffect(() => {
    const getsynds = async () => {
      const user = await Auth.currentSession();
      const token = user.getIdToken().getJwtToken();
      const res = await axios.get(
        'https://api.valueservices.uk/syndicateMX/getAllSyndicates',
        { headers: { Authorization: token } }
      );

      const syndOptions = res.data.map((synd) => {
        return { label: synd.displayName, value: synd.displayName };
      });
      const sortedSynd = syndOptions.sort((a, b) => {
        return a.value.localeCompare(b.value);
      });

      setSyndList(sortedSynd);
    };

    getsynds();
  }, []);

  const basicAlert = (message) => {
    setAlert(
      <ReactBSAlert
        style={{ display: 'block', marginTop: '-100px' }}
        title={`${message}`}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      ></ReactBSAlert>
    );
  };

  const validateAndSend = async () => {
    const val = tempSyndName;
    const regex = /^[a-zA-Z\s]*$/;
    const isValid = regex.test(val);

    const splitName = val.trim().split(' ');
    if (splitName.length < 2) {
      basicAlert('Please type a first name and a last name');
      return;
    }

    const capitalisedName = splitName
      .map((word) => {
        return word[0].toUpperCase() + word.substr(1);
      })
      .join(' ');

    const existingSyndCheck = syndList.findIndex(
      (name) => name === capitalisedName
    );
    if (existingSyndCheck > -1) {
      basicAlert(
        `${capitalisedName} already exists. Click next to continue with ${capitalisedName}`
      );
      setSyndName(capitalisedName);
      return;
    }

    if (!isValid) {
      basicAlert(`There are invalid characters in that name`);
      return;
    } else {
      const user = await Auth.currentSession();
      const token = user.getIdToken().getJwtToken();
      const config = {
        method: 'post',
        url: 'https://api.valueservices.uk/syndicateMX/createSyndicate',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        data: { name: capitalisedName, displayName: capitalisedName },
      };

      axios(config)
        .then(function (response) {
          setSyndName(capitalisedName);
          basicAlert(`Created: ${response.data.displayName}`);
        })
        .catch(function (error) {
          basicAlert(error);
        });
    }
  };

  const addSyndButton = () => {
    return tempSyndName.length > 0 ? (
      <Button
        onClick={() => {
          validateAndSend();
        }}
        color="primary"
      >
        Add New Syndicate
      </Button>
    ) : (
      <Button>Add New Syndicate</Button>
    );
  };

  const isValidated = () => {
    return syndName.length > 0 ? true : false;
  };

  useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    state: {
      syndName,
    },
  }));
  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <Col className="mr-auto ml-auto" md="8">
              <h1 className="title text-center">First Choose a Syndicate</h1>
              <hr />
              <ButtonGroup>
                <Button
                  onClick={() => {
                    setExistingOrNot('existing');
                    setTempSyndName('');
                    setSyndName('');
                  }}
                >
                  Use Existing Syndicate
                </Button>
                <Button
                  onClick={() => {
                    setExistingOrNot('');
                    setSyndName('');
                    setSyndSelect({
                      label: 'Choose Syndicate',
                      value: '',
                    });
                  }}
                >
                  Create New Syndicate
                </Button>
              </ButtonGroup>
              <hr />

              {existingOrNot === 'existing' ? (
                <>
                  <p>
                    If the syndicate you need isn't in the list, click the{' '}
                    <strong>'Create New Syndicate'</strong> button.
                  </p>
                  <Row>
                    <Col>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        placeholder="Choose Syndicate..."
                        name="syndicateNameSelect"
                        closeMenuOnSelect={true}
                        value={syndSelect}
                        onChange={(name) => {
                          setSyndSelect(name);
                          setSyndName(name.value);
                        }}
                        options={syndList}
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <FormGroup>
                    <Input
                      placeholder="Create New Syndicate Here..."
                      name="SyndicateName"
                      type="text"
                      onChange={(e) => {
                        setTempSyndName(e.target.value);
                      }}
                    />
                  </FormGroup>
                  {addSyndButton()}
                  {alert}
                </>
              )}
            </Col>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
});

const SecondStep = forwardRef((props, ref) => {
  if ('syndicate' in props.wizardData) {
    const [bookieSingleSelect, setBookieSingleSelect] = useState(null);
    const [proxy, setProxy] = useState('');

    let initStart = timeToMillis('08:00');
    let initEnd = timeToMillis('10:00');

    const [startValue, setStartValue] = useState([initStart, initEnd]);
    const [syndicateSingleSelect, setSyndicateSingleSelect] = useState(null);

    const [betTypeSelect, setBetTypeSelect] = useState(null);
    const [password, setPassword] = useState('');
    const [userName, setUserName] = useState('');
    const [days, setDays] = useState([]);
    const [startTime, setStartTime] = useState('07:00');
    const [endTime, setEndTime] = useState('17:00');
    const [betTypes, setBetTypes] = useState([]);
    const [maxBets, setMaxBets] = useState(null);
    const [alert, setAlert] = useState(null);
    const [bookieSelect, setBookieSelect] = useState([]);

    useEffect(() => {
      const getBookies = async () => {
        const user = await Auth.currentSession();
        const token = user.getIdToken().getJwtToken();
        const sourceBookieList = Object.keys(bookieDefaults);

        const accountRes = await axios.get(
          `https://api.valueservices.uk/syndicateMX/getAccountsByName/${name
            .replace(' ', '%23')
            .toLowerCase()}`,
          { headers: { Authorization: token } }
        );

        if (accountRes.data[0]) {
          const { username, password, proxy } = accountRes.data[0];
          setUserName(username);
          setPassword(password);
          setProxy(proxy);
        }

        const bookieList = accountRes.data.map((account) => {
          return account.bookie;
        });

        const editedBookieList = sourceBookieList
          .sort()
          .filter((s) => !bookieList.includes(s));

        const bookieOptions = editedBookieList.map((bookie) => {
          return { label: bookie, value: bookie };
        });

        setBookieSelect(bookieOptions);
      };

      getBookies();
    }, [props]);
    const name = props.wizardData.syndicate.syndName;

    useEffect(() => {
      if (bookieSingleSelect) {
        setDays(bookieDefaults[bookieSingleSelect.value].days);
      }
    }, [bookieSingleSelect]);

    // set slider scale - miliseconds since midnight
    const sliderProps = {
      min: 21600000,
      max: 79200000,
      step: 300000,
      marks: {
        21600000: '06:00',
        25200000: '7',
        28800000: '8',
        32400000: '9',
        36000000: '10',
        39600000: '11',
        43200000: '12',
        46800000: '13',
        50400000: '14',
        54000000: '15',
        57600000: '16',
        61200000: '17',
        64800000: '18',
        68400000: '19',
        72000000: '20',
        75600000: '21',
        79200000: '22:00',
      },
    };

    const hideAlert = () => {
      setAlert(null);
    };

    const basicAlert = (message) => {
      setAlert(
        <ReactBSAlert
          style={{ display: 'block', marginTop: '-100px' }}
          title={`${message}`}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="success"
          btnSize=""
        ></ReactBSAlert>
      );
    };

    // function to handle adding days to days array
    function addDays(value) {
      let tempArray = days;
      const index = days.indexOf(value);
      index === -1 ? tempArray.push(value) : tempArray.splice(index, 1);
      tempArray.sort();
      setDays([...tempArray]);
    }

    // update time state from slider
    const update = (value) => {
      setStartValue(value);
      setStartTime(millisToTime(value[0]));
      setEndTime(millisToTime(value[1]));
    };

    useEffect(() => {
      if (bookieSingleSelect) {
        update([
          timeToMillis(bookieDefaults[bookieSingleSelect.value].startTime),
          timeToMillis(bookieDefaults[bookieSingleSelect.value].endTime),
        ]);
      }
    }, [bookieSingleSelect]);

    // Slider to select time for account to login
    const accountSlider = () => {
      return (
        <div className="m-2">
          <span>{`${millisToTime(startValue[0].toFixed(2))} - `}</span>
          <span>{`${millisToTime(startValue[1].toFixed(2))}`}</span>

          <Row>
            <Col className="col-md-10 ml-auto mr-auto mb-5">
              <Slider
                range
                value={startValue}
                allowCross={false}
                defaultValue={startValue}
                pushable={900000}
                onChange={update}
                {...sliderProps}
              />
            </Col>
          </Row>
        </div>
      );
    };

    // Submit new syndicate account
    const handleSubmit = async () => {
      const user = await Auth.currentSession();
      const token = user.getIdToken().getJwtToken();

      const accountObj = {
        bookie: bookieSingleSelect.value,
        name: syndicateSingleSelect.value.toLowerCase().replace(' ', '#'),
        balance: 0,
        betTypes: betTypes,
        days: days,
        displayName: syndicateSingleSelect.value,
        enabled: false,
        endTime: endTime,
        maxBets: parseInt(maxBets),
        notes: '',
        type: 'Active',
        password: password,
        startTime: startTime,
        username: userName,
        pauseReason: '',
      };

      var config = {
        method: 'post',
        url: 'https://api.valueservices.uk/syndicateMX/createSyndicateAccount',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        data: accountObj,
      };

      axios(config)
        .then(function (response) {
          basicAlert(
            `Created: ${response.data.displayName} - ${response.data.bookie}`
          );
        })
        .catch(function (error) {
          basicAlert(error);
        });
      return;
    };

    // Create Submit Button
    const button = () => {
      const element =
        syndicateSingleSelect &&
        bookieSingleSelect &&
        betTypeSelect &&
        password &&
        userName &&
        days.length > 0 &&
        startTime &&
        endTime &&
        betTypes.length > 0 &&
        stake &&
        maxBets ? (
          <Button
            color="primary"
            onClick={() => {
              handleSubmit();
            }}
          >
            CreateNew Account
          </Button>
        ) : (
          <Button disabled>Create New Account</Button>
        );
      return element;
    };

    // Create programatic elements
    const slider = accountSlider();
    const submit = button();

    const isValidated = () => {
      if (
        bookieSingleSelect.value.length > 0 &&
        password &&
        userName &&
        startTime &&
        endTime &&
        days.length > 0
      ) {
        return true;
      }
      return false;
    };
    useImperativeHandle(ref, () => ({
      isValidated: () => {
        return isValidated();
      },
      state: {
        bookie: bookieSingleSelect || '',
        password,
        userName,
        startTime,
        endTime,
        days,
        proxy,
      },
    }));
    return (
      <Col className="mr-auto ml-auto" md="10">
        <h1 className="title text-center">
          Next choose a bookie to create the account for and assign it a
          schedule.
        </h1>
        <hr />
        <Card>
          <CardHeader>
            <CardTitle tag="h3">Choose Bookie For {`${name}`}</CardTitle>
          </CardHeader>
          <CardBody>
            <Form>
              <Row>
                <Col>
                  <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={bookieSingleSelect}
                    onChange={(value) => setBookieSingleSelect(value)}
                    options={bookieSelect}
                    placeholder="Bookie"
                  />
                </Col>
              </Row>
            </Form>
          </CardBody>

          <hr />
          <CardHeader>
            <CardTitle tag="h3">Input Account Credentials</CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <label>Username</label>
                <FormGroup>
                  <Input
                    type="text"
                    value={userName}
                    onChange={(e) => {
                      setUserName(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <label>Password</label>
                <FormGroup>
                  <Input
                    type="text"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <hr />
          </CardBody>

          <CardHeader>
            <CardTitle tag="h3">Set Account Schedule</CardTitle>
          </CardHeader>
          <CardBody>
            <CardTitle tag="h4">Select Active Days</CardTitle>
            <Row>
              <Col>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={days.includes(0) ? true : false}
                      onChange={() => {
                        addDays(0);
                      }}
                    />
                    <span className="form-check-sign" />
                    Sun
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={days.includes(1) ? true : false}
                      onChange={() => {
                        addDays(1);
                      }}
                    />
                    <span className="form-check-sign" />
                    Mon
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={days.includes(2) ? true : false}
                      onChange={() => {
                        addDays(2);
                      }}
                    />
                    <span className="form-check-sign" />
                    Tue
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={days.includes(3) ? true : false}
                      onChange={() => {
                        addDays(3);
                      }}
                    />
                    <span className="form-check-sign" />
                    Wed
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={days.includes(4) ? true : false}
                      onChange={() => {
                        addDays(4);
                      }}
                    />
                    <span className="form-check-sign" />
                    Thu
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={days.includes(5) ? true : false}
                      onChange={() => {
                        addDays(5);
                      }}
                    />
                    <span className="form-check-sign" />
                    Fri
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={days.includes(6) ? true : false}
                      onChange={() => {
                        addDays(6);
                      }}
                    />
                    <span className="form-check-sign" />
                    Sat
                  </Label>
                </FormGroup>
                <hr />
                <CardTitle tag="h4">Select Login/Logout Times</CardTitle>
                {slider}
                <hr />
              </Col>
            </Row>

            <Row>
              <Col className="col-md-3 ml-auto">{submit}</Col>
            </Row>
            {alert}
          </CardBody>
        </Card>
      </Col>
    );
  }
});

const ThirdStep = forwardRef((props, ref) => {
  if ('account' in props.wizardData) {
    const bookieAccount = props.wizardData.account.bookie.value;

    const [currentBookie, setCurrentBookie] = useState('');
    const [betTypeSelect, setBetTypeSelect] = useState(null);
    const [stake, setStake] = useState(null);
    const [betTypes, setBetTypes] = useState([]);
    const [alert, setAlert] = useState(null);
    const [maxBets, setMaxBets] = useState('');

    const isValidated = () => {
      return betTypes.length > 0 && maxBets > 0;
    };

    useEffect(() => {
      setMaxBets(
        bookieDefaults[bookieAccount]
          ? bookieDefaults[bookieAccount].betType.maxBets
          : ''
      );
      setBetTypes(
        bookieDefaults[bookieAccount]
          ? [bookieDefaults[bookieAccount].betType]
          : []
      );
      setCurrentBookie(props.wizardData.account.bookie.value);
    }, [props]);

    useImperativeHandle(ref, () => ({
      isValidated: () => {
        return isValidated();
      },
      state: {
        betTypes,
        maxBets,
      },
    }));

    const basicAlert = (message) => {
      setAlert(
        <ReactBSAlert
          style={{ display: 'block', marginTop: '-100px' }}
          title={`${message}`}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="success"
          btnSize=""
        ></ReactBSAlert>
      );
    };

    const hideAlert = () => {
      setAlert(null);
    };

    // add new bet type and stake to betType state
    const updateBetTypes = (type, stake) => {
      if (betTypes.length > 0) {
        let betExists = betTypes.find((b) => type === b.betTypeName);
        if (betExists) {
          basicAlert(`Already Created a ${type} Bet`);
          return;
        }
      }
      if (stake < 0) {
        basicAlert('Cant Have a Stake Less Than Zero');
        return;
      }
      if (!stake) {
        basicAlert('Stake Value is Required');
        return;
      }

      const bet = {
        stake: parseFloat(stake),
        betTypeName: type,
        order: betTypes.length + 1,
      };

      setBetTypes([...betTypes, bet]);
    };

    //  delete selected bet type alerts
    function handleBetClick(type, stake) {
      const tempArray = betTypes;
      let index = tempArray.findIndex((item) => {
        return item.betTypeName === type && item.stake === stake;
      });
      tempArray.splice(index, 1);
      setBetTypes([...tempArray]);
    }

    const displayBets = () => {
      const betElements = betTypes.map((bet) => {
        return (
          <Alert color="info" fade={true}>
            <Row>
              <Col>
                <>
                  <b>{`${bet.betTypeName}`}</b>
                  {` @ £${bet.stake} / line`}
                </>
              </Col>
              <Col
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleBetClick(bet.betTypeName, bet.stake);
                }}
                className="col-md-2 ml-auto"
              >
                Remove
              </Col>
            </Row>
          </Alert>
        );
      });

      const result =
        betElements.length > 0 ? (
          betElements
        ) : (
          <>
            <Row>
              <Col>
                <Select
                  style={{ color: 'black' }}
                  classNamePrefix="react-select"
                  name="betSelect"
                  value={betTypeSelect}
                  onChange={(value) => setBetTypeSelect(value)}
                  options={[
                    { value: 'single', label: 'Single' },
                    { value: 'double', label: 'Double' },
                    { value: 'trixie', label: 'Trixie' },
                    { value: 'patent', label: 'Patent' },
                    { value: 'lucky15', label: 'Lucky15' },
                    { value: 'lucky31', label: 'Lucky31' },
                  ]}
                  placeholder="Select Bet Type"
                />
              </Col>
              <Col>
                <FormGroup>
                  <Input
                    placeholder="Price/line"
                    name="number"
                    type="number"
                    onChange={(e) => {
                      setStake(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <Button
                  style={{ paddingBottom: '8px', marginTop: '0px' }}
                  onClick={() => {
                    updateBetTypes(betTypeSelect.value, parseFloat(stake));
                  }}
                >
                  Add Bet Type
                </Button>
              </Col>
            </Row>
            <Alert color="info" fade={true}>
              <Row>
                <Col>
                  <>
                    <b>{`Add a bet type from the dropdown and type a stake value...`}</b>
                  </>
                </Col>
              </Row>
            </Alert>
          </>
        );

      return result;
    };
    const displayedBets = displayBets();

    return (
      <Col className="mr-auto ml-auto" md="12">
        <h1 className="title text-center">Choose Bet Types for this account</h1>
        <Row>
          <Col className="col-md-11 ml-auto mr-auto">
            <hr />
            <Row>
              <Col className="col-md-12">
                <CardTitle tag="h4">
                  Bet Type Assigned To This Account...
                </CardTitle>
                {displayedBets}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <CardBody>
              <CardTitle tag="h4">Set Max Bets/Day</CardTitle>
              {/* <label>Total Max Bets Per Day For This Account</label> */}
              <FormGroup>
                <Input
                  name="number"
                  type="number"
                  min="1"
                  step="1"
                  value={maxBets}
                  onChange={(e) => {
                    const value = e.target.value.replace('/[^d.]/', '');
                    if (parseInt(value) !== 0) {
                      setMaxBets(value);
                    }
                  }}
                />
              </FormGroup>
            </CardBody>
          </Col>
        </Row>
        {alert}
      </Col>
    );
  }
});

const FourthStep = forwardRef((props, ref) => {
  let returnState;
  if ('bets' in props.wizardData) {
    const { account, bets, syndicate } = props.wizardData;
    const dayList = () => {
      const days = account.days.map((day) => {
        return day === 0 ? (
          <strong>{'Sun'} - </strong>
        ) : day === 1 ? (
          <strong>{'Mon'} - </strong>
        ) : day === 2 ? (
          <strong>{'Tue'} - </strong>
        ) : day === 3 ? (
          <strong>{'Wed'} - </strong>
        ) : day === 4 ? (
          <strong>{'Thu'} - </strong>
        ) : day === 5 ? (
          <strong>{'Fri'} - </strong>
        ) : (
          <strong>{'Sat'}</strong>
        );
      });
      return days;
    };

    const betList = () => {
      const betList = bets.betTypes.map((bet) => {
        return (
          <strong>
            <p>
              £{bet.stake}-{bet.betTypeName}
            </p>
          </strong>
        );
      });
      return betList;
    };

    returnState = {
      bookie: account.bookie.value,
      name: syndicate.syndName.toLowerCase().replace(' ', '#'),
      balance: 0,
      betTypes: bets.betTypes,
      days: account.days,
      displayName: syndicate.syndName,
      enabled: false,
      endTime: account.endTime,
      maxBets: parseInt(bets.maxBets),
      notes: '',
      password: account.password,
      pauseReason: '',
      startTime: account.startTime,
      type: 'Active',
      username: account.userName,
    };

    returnState = account.proxy
      ? { ...returnState, proxy: account.proxy }
      : returnState;

    const isValidated = () => {
      return true;
    };
    useImperativeHandle(ref, () => ({
      isValidated: () => {
        return isValidated();
      },
      state: {
        returnState,
      },
    }));

    return (
      <Col className="mr-auto ml-auto" md="10">
        <h1 className="title text-center">Summary of New Account</h1>
        <Row>
          <hr />
          <Col>
            <h4>Syndicate:</h4>
            <strong>{syndicate.syndName}</strong>
          </Col>
          <Col>
            <h4>Bookie:</h4>
            <strong>{account.bookie.value}</strong>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h4>Username:</h4>
            <strong>{account.userName}</strong>
          </Col>
          <Col>
            <h4>Password:</h4>
            <strong>{account.password}</strong>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h4>Active Between</h4>
            <strong>
              {account.startTime} - {account.endTime}
            </strong>
          </Col>
          <Col>
            <h4>Days Active</h4>
            {dayList()}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h4>Account Bets</h4>
            <strong>{betList()}</strong>
          </Col>
          <Col>
            <h4>Bets Per Day</h4>
            <strong>{bets.maxBets}</strong>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h4>Proxy</h4>
            <strong>
              {account.proxy ? account.proxy : 'No Proxy assigned'}
            </strong>
          </Col>
        </Row>
      </Col>
    );
  }
});

var steps = [
  {
    stepName: 'syndicate',
    stepIcon: 'tim-icons icon-single-02',
    component: FirstStep,
  },
  {
    stepName: 'account',
    stepIcon: 'tim-icons icon-laptop',
    component: SecondStep,
  },
  {
    stepName: 'bets',
    stepIcon: 'tim-icons icon-paper',
    component: ThirdStep,
  },
  {
    stepName: 'summary',
    stepIcon: 'tim-icons icon-check-2',
    component: FourthStep,
  },
];

function CreateAccount() {
  const [alert, setAlert] = useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  const basicAlert = (message) => {
    setAlert(
      <ReactBSAlert
        style={{ display: 'block', marginTop: '-100px' }}
        title={`${message}`}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      ></ReactBSAlert>
    );
  };

  const finishButtonClick = async (allStates) => {
    var data = allStates.summary.returnState;
    const user = await Auth.currentSession();
    const token = user.getIdToken().getJwtToken();
    var config = {
      method: 'post',
      url: 'https://api.valueservices.uk/syndicateMX/createSyndicateAccount',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        basicAlert('Account Created');
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        basicAlert(error.response.data);
        console.log(error);
      });
  };

  return (
    <>
      <div className="content">
        <Col className="mr-auto ml-auto" md="8">
          <ReactWizard
            steps={steps}
            navSteps
            validate
            title="Create New Bookie Account"
            description=""
            headerTextCenter
            finishButtonClasses="btn-wd btn-info"
            nextButtonClasses="btn-wd btn-info"
            previousButtonClasses="btn-wd"
            progressbar
            color="blue"
            finishButtonClick={finishButtonClick}
          />
        </Col>
        {alert}
      </div>
    </>
  );
}

export default CreateAccount;
