import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';

const RaceCardModalRow = ({ sel, handleChangeRunners }) => {
  const [toggle, setToggle] = useState(sel.autoUpdate);
  return (
    <>
      <Row>
        <Col>
          {' '}
          <input
            type="checkbox"
            defaultChecked={!toggle}
            onClick={() => {
              setToggle(!toggle);
              handleChangeRunners(
                sel.selectionId,
                !sel.autoUpdate,
                'autoUpdate'
              );
            }}
          />{' '}
          {sel.name}{' '}
        </Col>
        <Col className="ml-auto" md="4">
          <select
            onChange={(e) =>
              handleChangeRunners(
                sel.selectionId,
                e.target.value,
                'status',
                sel
              )
            }
            defaultValue={sel.status}
            disabled={toggle}
          >
            <option value="PENDING">PENDING</option>
            <option value="ACTIVE">ACTIVE</option>
            <option value="LOSER">LOSER</option>
            <option value="WINNER">WINNER</option>
            <option value="REMOVED">REMOVED</option>
          </select>
        </Col>
      </Row>
      <hr />
    </>
  );
};

export default RaceCardModalRow;
