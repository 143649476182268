import React, { useState } from 'react';
import {
  Button,
  Spinner,
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
  Table,
} from 'reactstrap';
import { axiosGet } from '../utils';

import { syndicateListGenerator } from 'components/dropdownGenerator';
import { bookieListGenerator } from 'components/dropdownGenerator';
const activeBookieArray = bookieListGenerator('analytics');
const syndicates = syndicateListGenerator('finalAccounts');

const FinalAccounts = () => {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [syndicateData, setSyndicatData] = useState([]);

  const loadingSpinner = loading ? (
    <>
      {' '}
      <Spinner size="sm"></Spinner>
    </>
  ) : (
    'Load'
  );

  const hideAlert = () => {
    setAlert(null);
  };

  const basicAlert = (message) => {
    setAlert(
      <ReactBSAlert
        style={{ display: 'block', marginTop: '-100px' }}
        title={`${message}`}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      ></ReactBSAlert>
    );
  };

  const loadSyndicates = async () => {
    setLoading(true);
    const syndicateData = await Promise.all(
      syndicates.map(async (item) => {
        const url = `https://api.valueservices.uk/syndicateMX/getAccountsByName/${item
          .toLowerCase()
          .replace(/ /g, '%23')}`;
        const response = await axiosGet(url);
        if (response.name === 'AxiosError') {
          basicAlert(response.message);
          return [];
        }
        return response;
      })
    );
    setSyndicatData(syndicateData);
    setLoading(false);
  };

  const tableRow = ({
    bookie,
    displayName,
    type,
    enabled,
    pauseReason,
    notes,
  }) => {
    const rowColour = type === 'Active' ? 'table-success' : 'table-danger';

    return (
      <tr className={rowColour}>
        <td>{displayName}</td>
        <td>{bookie}</td>
        <td>{type}</td>
        <td>
          {enabled === true ? 'Placing' : enabled === false ? 'Paused' : 'N/A'}
        </td>
        <td>{enabled === true ? '' : enabled === false ? pauseReason : ''}</td>
        <td>{notes[notes.length - 1]}</td>
      </tr>
    );
  };

  const currentAccounts = () => {
    let tableItems = [];
    if (syndicateData && syndicateData.length > 0) {
      for (let i = 0; i < syndicateData.length; i++) {
        if (syndicateData[i].length > 0) {
          const existingAccounts = [];
          const name = syndicateData[i][0].displayName;
          for (let j = 0; j < syndicateData[i].length; j++) {
            if (activeBookieArray.includes(syndicateData[i][j].bookie)) {
              existingAccounts.push(syndicateData[i][j]);
            }
          }

          const potentialAccounts = existingAccounts.filter((acc) => {
            return acc.type === 'Active';
          });

          const syndsBookies = [
            ...new Set(
              existingAccounts.map((acc) => {
                return acc.bookie;
              })
            ),
          ];

          const missingEntries = activeBookieArray.filter((item) => {
            return syndsBookies.findIndex((i) => item === i) === -1;
          });

          for (let i = 0; i < missingEntries.length; i++) {
            potentialAccounts.push({
              bookie: missingEntries[i],
              displayName: name,
              type: 'Not Created',
              enabled: 'N/A',
              pauseRason: 'N/A',
              notes: [''],
            });
          }

          if (
            potentialAccounts &&
            potentialAccounts.length <= 2 &&
            potentialAccounts.length > 0
          ) {
            tableItems.push(potentialAccounts);
          }
        }
      }
    }

    if (tableItems) {
      return tableItems.flatMap((i) => i).map((acc) => tableRow(acc));
    }
    return <></>;
  };

  return (
    <>
      <div className="content">
        <Card>
          <Col className="mr-auto ml-auto mt-3 mb-2" md="12">
            <CardTitle tag="h3">Final Accounts </CardTitle>
            <CardBody>
              <Row>
                <Button
                  onClick={async () => {
                    await loadSyndicates(true);
                  }}
                >
                  {loadingSpinner}
                </Button>
              </Row>
              <Row>
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Bookie</th>
                      <th>Status</th>
                      <th>Placing</th>
                      <th>Pause Reason</th>
                      <th>Notes</th>
                    </tr>
                  </thead>
                  <tbody>{currentAccounts()}</tbody>
                </Table>
              </Row>
            </CardBody>
          </Col>
        </Card>
      </div>
      {alert}
    </>
  );
};

export default FinalAccounts;
