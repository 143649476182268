import React, { useEffect, useState } from 'react';
// reactstrap components
import { CardBody, Row, Col } from 'reactstrap';
import moment from 'moment';
import { Auth } from 'aws-amplify';

// core components
import SortingTable from '../RaceCardTable.js';
import axios from 'axios';
import axiosRetry from 'axios-retry';

import RaceCardModal from './RaceCardModal';
import NotificationAlert from 'react-notification-alert';
axiosRetry(axios, { retries: 3 });

const RaceCard = () => {
  const [runners, setRunners] = useState([]);
  const [venues, setVenues] = useState([]);
  const [fullEvents, setfullEvents] = useState([]);
  const [alert, setAlert] = useState(null);

  const notificationAlertRef = React.useRef(null);

  const notify = (place, msg) => {
    let colour = 'danger';

    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{msg}</div>
        </div>
      ),
      type: colour,
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 60,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  useEffect(() => {
    raceCardResponse();
  }, []);

  useEffect(() => {
    const events = runners.map((runner) => {
      return {
        venue: runner.venue,
        time: runner.dateTime,
        selections: [],
      };
    });

    const uniqVenues = [
      ...events
        .reduce((map, obj) => map.set(obj.venue, obj.venue), new Map())
        .values(),
    ];

    setVenues(uniqVenues);

    const uniqEvents = [
      ...events
        .reduce((map, obj) => map.set(obj.time, obj), new Map())
        .values(),
    ];

    const raceCardEvents = uniqEvents.map((event) => {
      const raceCardSelections = runners.filter(
        (runner) =>
          event.venue === runner.venue && runner.dateTime === event.time
      );
      return {
        event: event.venue,
        time: event.time,
        formattedTime: moment(event.time, 'YYYY-MM-DDTHH:mm:SSSZ').format(
          'HH:mm'
        ),
        selections: raceCardSelections,
      };
    });
    setfullEvents(raceCardEvents);
  }, [runners]);

  const raceCardResponse = async () => {
    const user = await Auth.currentSession();
    const token = user.getIdToken().getJwtToken();
    const { data } = await axios.get(
      'https://api.valueservices.uk/betfair/getRaceCard',
      { headers: { Authorization: token } }
    );

    const uniqueVenues = [
      ...new Set(
        data
          .map((d) => {
            return d.venue;
          })
          .sort()
      ),
    ];

    const venues = await axios.get(
      'https://api.valueservices.uk/horsemx/getVenues',
      { headers: { Authorization: token } }
    );
    const storedVenues = [
      ...new Set(
        venues.data
          .map((d) => {
            return d.name;
          })
          .sort()
      ),
    ];

    if (uniqueVenues && storedVenues) {
      uniqueVenues.forEach((v) => {
        if (!storedVenues.includes(v)) {
          notify('br', `Issue with venue: ${v}`);
        }
      });
    }

    setRunners(data);
  };

  const handleChangeRunners = async (id, updateStatus, field, sel) => {
    if (field === 'autoUpdate') {
      const tempRunners = [...runners];
      const updatedRunners = tempRunners.map((r) => {
        if (r.selectionId === id) {
          r.autoUpdate = updateStatus;
        }
        return r;
      });

      setRunners(updatedRunners);
      const user = await Auth.currentSession();
      const token = user.getIdToken().getJwtToken();
      await axios.post(
        'https://api.valueservices.uk/betfair/autoUpdateToggle',
        {
          ids: [id],
        },
        { headers: { Authorization: token } }
      );
    } else if (field === 'status') {
      const tempRunners = [...runners];
      const updatedRunners = tempRunners.map((r) => {
        if (r.selectionId === id) {
          r.status = updateStatus;
        }
        return r;
      });
      const user = await Auth.currentSession();
      const token = user.getIdToken().getJwtToken();
      setRunners(updatedRunners);
      await axios.post(
        'https://api.valueservices.uk/betfair/updateRaceCard',
        {
          card: sel,
        },
        { headers: { Authorization: token } }
      );
    }
  };

  const raceTimes = (races) =>
    races.map((race) => (
      <span key={race.formattedTime}>
        <span> </span>
        <RaceCardModal
          venue={race.venueName}
          race={race}
          selections={race.selections}
          handleChangeRunners={handleChangeRunners}
        />
        <span> | </span>
      </span>
    ));

  return (
    <>
      <Row>
        <NotificationAlert ref={notificationAlertRef} />
        <Col className="mb-2" md="12">
          <CardBody>
            {venues.length > 0 && fullEvents.length > 0 && (
              <SortingTable
                thead={[{ text: 'Venue' }, { text: 'Race Times' }]}
                tbody={venues.map((v) => {
                  return {
                    data: [
                      { text: v },
                      {
                        component: raceTimes(
                          fullEvents
                            .filter((ue) => ue.event === v)
                            .map((f) => ({
                              formattedTime: f.formattedTime,
                              venueName: v,
                              selections: f.selections,
                            }))
                            .sort((a, b) =>
                              a.formattedTime === b.formattedTime
                                ? 0
                                : a.formattedTime > b.formattedTime
                                ? 1
                                : -1
                            )
                        ),
                        text: '',
                      },
                    ],
                  };
                })}
              />
            )}
          </CardBody>
        </Col>
      </Row>
    </>
  );
};

export default RaceCard;
