const data = {
  fullSyndList: [
    'Adam Shaw',
    'Aidan Gale',
    'Alex Brown',
    'Alex Johnston',
    'Alexandra ChappleHyam',
    'Alexandra Loizou',
    'Alfio Monterosso',
    'Amanda ConnellyHayward',
    'Anacleto Gualini',
    'Andrew McCulloch',
    'Andrew McCusker',
    'Andrew Smith',
    'Andy Newham',
    'Ann Campbell',
    'Aviva Caller',
    'Barry Holliday',
    'Billy Bearder',
    'Carl Palmer',
    'Cathrine Lounds',
    'Charles Stock',
    'Chloe Holmes',
    'Chris Johnson',
    'Chris Traill',
    'Christie Loannou',
    'Christopher Ashton',
    'Colin Wormleighton',
    'Craig Ellis',
    'Craig Henson',
    'Craig Kerr',
    'Danny Brown',
    'David Froggatt',
    'David Kelly',
    'David LeneveRoff',
    'David Mackessack',
    'David Sykes',
    'Dominic Abbott',
    'Donna Bailey',
    'Elaine Dixon',
    'Evrol Campbell',
    'Gary Forsyth',
    'Gary Thornley',
    'Gianluca Milani',
    'Hayley Porter',
    'Helen Hill',
    'Holly Ledgeway',
    'Ian Green',
    'Ian Lilley',
    'Jack Malone',
    'Jade Simpson',
    'James Nightingale',
    'James Walton',
    'Jason Woodward',
    'John Grieve',
    'John Hales',
    'John Heeson',
    'John Whetton',
    'Jonathan Hunt',
    'Julie Pitchford',
    'Karen Clarke',
    'Kathryn Northridge',
    'Kavita Kachiwala',
    'Kayleigh Palmer',
    'Keegan Gorewoda',
    'Kelly Goudie',
    'Kevin Delapena',
    'Kevin Heeley',
    'Kevin Storey',
    'Kevin Ward',
    'Larissa Hawkridge',
    'Lee Hogan',
    'Lewis Noble',
    'Louise Blackshaw',
    'Lucy Porter',
    'Luke Todd',
    'Maggie Fallows',
    'Marcel Bechara',
    'Mark Elvin',
    'Mark Smyth',
    'Michaela Coulling',
    'Neil Smyth',
    'Niall Russell',
    'Nicholas Long',
    'Olly Hancock',
    'Paul Hearson',
    'Paul Newell',
    'Peter Russell',
    'Phil Newell',
    'Rachel Branch',
    'Rainford Ricketts',
    'Richard Tomlinson',
    'Rob Leal',
    'Robert Dewick',
    'Robin Penrice',
    'Samuel Northridge',
    'Sandra Swinscoe',
    'Sarah Haddon',
    'Scott Deacon',
    'Simon Collinson',
    'Sofia Spyrou',
    'Stuart Hamilton',
    'Thomas Cast',
    'Thomas Townend',
    'Tracy Krishnan',
    'Tristan Siddons',
    'Tyler Porter',
    'Wayne Jones',
    'William Archer',
  ],
  fullBookieList: [
    'akbets',
    'coral',
    'betfairsportsbook',
    'ladbrokes',
    'williamhill',
    '32red',
    'betfred',
    '888sport',
    'betvictor',
    'paddypower',
    'youwin',
    'boylesports',
    'skybet',
    'unibet',
    'betway',
    'betbright',
    '10bet',
    'spreadex',
    'toals',
    'sportnation',
    'redzone',
    'bwin',
    'bet365',
    'mrgreen',
    'mansionbet',
    'vbet',
    'virginbet',
    'novibet',
    'pokerstars',
    'netbet',
    'starsports',
    'betregal',
    'betiton',
    'jenningsbet',
    'redbet',
    'vickersbet',
    'hollywoodbet',
    'karamba',
    'markjarvis',
    'hopa',
    'bet600',
    'fitzdares',
    'partypoker',
    'sportingindex',
    'nebet',
    '21',
    'grosvenor',
    'geoffbanks',
    'quinnbet',
    'livescorebet',
    'fafabet',
    'parimatch',
    'rhinobet',
    'chisholmbet',
    'corbettsports',
    'bettarget',
    'mrrex',
    'highbet',
    'mrmega',
    'sportpesa',
    'marathonbet',
    'sportingbet',
    'bethard',
    'moplay',
    'mrplay',
    'pokerstarssports',
  ],
  syndRes: [
    {
      notes: '',
      displayName: 'Adam Shaw',
      type: 'Active',
      bookie: 'bwin',
      enabled: true,
      password: 'Numbers97',
      balance: 20.0,
      name: 'adam#shaw',
      days: [1, 2, 3, 4, 5, 6],
      startTime: '12:05',
      betTypes: [
        {
          stake: 4,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '19:15',
      updatedAt: 1658314667878,
      username: 'shawadam97',
      __key: {
        bookie: 'bwin',
        name: 'adam#shaw',
      },
    },
    {
      notes: 'Zeroed///19-07-22 14:05 - some stuff ',
      displayName: 'Adam Shaw',
      type: 'Active',
      bookie: '21',
      enabled: false,
      password: 'Numbers97',
      balance: 100.0,
      name: 'adam#shaw',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '09:20',
      betTypes: [
        {
          stake: 25,
          betTypeName: 'single',
          order: 1,
        },
      ],
      endTime: '15:30',
      updatedAt: 1658235954372,
      username: 'Damwash',
      __key: {
        bookie: '21',
        name: 'adam#shaw',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Adam Shaw',
      type: 'Inactive',
      bookie: 'jenningsbet',
      enabled: false,
      password: 'a887eab1',
      balance: 17.3,
      name: 'adam#shaw',
      days: [0, 2, 3, 4, 5, 6],
      startTime: '10:45',
      betTypes: [
        {
          stake: 2,
          betTypeName: 'lucky15',
          order: 1,
        },
      ],
      endTime: '14:30',
      updatedAt: 1655994702724,
      username: 'damwash',
      __key: {
        bookie: 'jenningsbet',
        name: 'adam#shaw',
      },
    },
    {
      notes:
        '///19-07-22 14:40 - Account Opened///19-07-22 15:18 - Zeroed Already///21-07-22 09:21 - a note from the droplet!',
      displayName: 'Adam Shaw',
      type: 'Inactive',
      bookie: '10bet',
      enabled: false,
      password: 'flangebag',
      balance: 0,
      name: 'adam#shaw',
      days: [1, 2, 5, 6, 7],
      startTime: '10:35',
      betTypes: [
        {
          stake: 3,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '14:00',
      updatedAt: 1658391673907,
      username: 'dogbreath2',
      __key: {
        bookie: '10bet',
        name: 'adam#shaw',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Adam Shaw',
      type: 'Inactive',
      bookie: 'sportpesa',
      enabled: false,
      password: 'Numbers97',
      balance: 0,
      name: 'adam#shaw',
      days: [1, 2, 3, 4, 5, 6],
      startTime: '10:00',
      betTypes: [
        {
          stake: 1,
          betTypeName: 'lucky15',
          order: 1,
        },
      ],
      endTime: '13:00',
      updatedAt: 1655994591759,
      username: 'damwash',
      __key: {
        bookie: 'sportpesa',
        name: 'adam#shaw',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Adam Shaw',
      type: 'Inactive',
      bookie: 'sportingindex',
      enabled: false,
      password: 'Numbers975',
      balance: 70.5,
      name: 'adam#shaw',
      days: [1, 2, 3, 4, 5],
      startTime: '10:00',
      betTypes: [
        {
          stake: 20,
          betTypeName: 'single',
          order: 1,
        },
      ],
      endTime: '11:30',
      updatedAt: 1655994746590,
      username: 'JW706',
      __key: {
        bookie: 'sportingindex',
        name: 'adam#shaw',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Adam Shaw',
      type: 'Inactive',
      bookie: 'marathonbet',
      enabled: false,
      password: 'Numbers97',
      balance: 100,
      name: 'adam#shaw',
      days: [2, 3, 4, 5, 6],
      startTime: '13:00',
      betTypes: [
        {
          stake: 2.3,
          betTypeName: 'lucky15',
          order: 1,
        },
      ],
      endTime: '15:00',
      updatedAt: 1655994434115,
      username: 'shawadam97',
      __key: {
        bookie: 'marathonbet',
        name: 'adam#shaw',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Adam Shaw',
      type: 'Inactive',
      bookie: 'netbet',
      enabled: false,
      password: 'Numbers97',
      balance: 50.0,
      name: 'adam#shaw',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '08:45',
      betTypes: [
        {
          stake: 1,
          betTypeName: 'single',
          order: 1,
        },
      ],
      endTime: '11:00',
      updatedAt: 1655994746132,
      username: 'adamshawrugby@gmail.com',
      __key: {
        bookie: 'netbet',
        name: 'adam#shaw',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Adam Shaw',
      type: 'Inactive',
      bookie: 'sportingbet',
      enabled: false,
      password: 'Numbers95',
      balance: 0,
      name: 'adam#shaw',
      days: [1, 2, 3, 4, 5, 6],
      startTime: '10:10',
      betTypes: [
        {
          stake: 4.5,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '15:20',
      updatedAt: 1655994396578,
      username: 'adamshawrugby',
      __key: {
        bookie: 'sportingbet',
        name: 'adam#shaw',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Adam Shaw',
      type: 'Inactive',
      bookie: 'bethard',
      enabled: false,
      password: 'Numbers97!',
      balance: 0,
      name: 'adam#shaw',
      days: [1, 2, 3, 4, 5, 6],
      startTime: '13:00',
      betTypes: [
        {
          stake: 2,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '14:00',
      updatedAt: 1655994539252,
      username: 'adamshawrugby@gmail.com',
      __key: {
        bookie: 'bethard',
        name: 'adam#shaw',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Adam Shaw',
      type: 'Inactive',
      bookie: 'unibet',
      enabled: false,
      password: 'Numbers97',
      balance: 0,
      name: 'adam#shaw',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '08:10',
      betTypes: [
        {
          stake: 0.5,
          betTypeName: 'lucky15',
          order: 1,
        },
      ],
      endTime: '11:10',
      updatedAt: 1655994434230,
      username: 'adamshawrugby@gmail.com',
      __key: {
        bookie: 'unibet',
        name: 'adam#shaw',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Adam Shaw',
      type: 'Inactive',
      bookie: 'betfred',
      enabled: false,
      password: 'number97',
      balance: 60.2,
      name: 'adam#shaw',
      days: [1, 2, 3, 4, 5, 6],
      startTime: '09:25',
      betTypes: [
        {
          stake: 2,
          betTypeName: 'lucky15',
          order: 1,
        },
      ],
      endTime: '11:51',
      updatedAt: 1655994410908,
      username: 'shawadam97',
      __key: {
        bookie: 'betfred',
        name: 'adam#shaw',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Adam Shaw',
      type: 'Inactive',
      bookie: 'geoffbanks',
      enabled: false,
      password: 'Numbers97',
      balance: 0,
      name: 'adam#shaw',
      days: [0, 2, 3, 4, 5, 6],
      startTime: '10:00',
      betTypes: [
        {
          stake: 2,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '14:15',
      updatedAt: 1655994745791,
      username: 'DAMWASH',
      __key: {
        bookie: 'geoffbanks',
        name: 'adam#shaw',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Adam Shaw',
      type: 'Inactive',
      bookie: 'fitzdares',
      enabled: false,
      password: 'Numbers95',
      balance: 0,
      name: 'adam#shaw',
      days: [1, 2, 3, 4, 5],
      startTime: '10:20',
      betTypes: [
        {
          stake: 20,
          betTypeName: 'single',
          order: 1,
        },
      ],
      endTime: '15:00',
      updatedAt: 1655994745681,
      username: 'ShawAdam97',
      __key: {
        bookie: 'fitzdares',
        name: 'adam#shaw',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Adam Shaw',
      type: 'Inactive',
      bookie: 'toals',
      enabled: false,
      password: 'Numbers97',
      balance: 10.0,
      name: 'adam#shaw',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '12:00',
      betTypes: [
        {
          stake: 1,
          betTypeName: 'lucky15',
          order: 1,
        },
      ],
      endTime: '14:30',
      updatedAt: 1655994495413,
      username: 'shawadam97',
      __key: {
        bookie: 'toals',
        name: 'adam#shaw',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Adam Shaw',
      type: 'Inactive',
      bookie: 'vickersbet',
      enabled: false,
      password: 'Numbers95&',
      balance: 0,
      name: 'adam#shaw',
      days: [0, 1, 3, 4, 5, 6],
      startTime: '10:15',
      betTypes: [
        {
          stake: 10,
          betTypeName: 'single',
          order: 1,
        },
      ],
      endTime: '14:30',
      updatedAt: 1655994747164,
      username: 'adamshawrugby@gmail.com',
      __key: {
        bookie: 'vickersbet',
        name: 'adam#shaw',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Adam Shaw',
      type: 'Inactive',
      bookie: '888sport',
      enabled: false,
      password: 'Numbers97',
      balance: 0,
      name: 'adam#shaw',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '13:30',
      betTypes: [
        {
          stake: 4,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '17:30',
      updatedAt: 1655994443932,
      username: 'shawadam97',
      __key: {
        bookie: '888sport',
        name: 'adam#shaw',
      },
    },
    {
      notes: 'Verification_Pending',
      displayName: 'Adam Shaw',
      type: 'Inactive',
      bookie: 'vbet',
      enabled: false,
      password: 'Numbers99',
      balance: 0,
      name: 'adam#shaw',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '07:00',
      betTypes: [
        {
          stake: 3,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '11:00',
      updatedAt: 1655994747047,
      username: 'shawadam97',
      __key: {
        bookie: 'vbet',
        name: 'adam#shaw',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Adam Shaw',
      type: 'Inactive',
      bookie: 'mrgreen',
      enabled: false,
      password: 'Numbers97',
      balance: 0,
      name: 'adam#shaw',
      days: [1, 2, 3, 4, 5],
      startTime: '08:00',
      betTypes: [
        {
          stake: 25,
          betTypeName: 'single',
          order: 1,
        },
      ],
      endTime: '09:41',
      updatedAt: 1655994489666,
      username: 'adamshawrugby@gmail.com',
      __key: {
        bookie: 'mrgreen',
        name: 'adam#shaw',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Adam Shaw',
      type: 'Inactive',
      bookie: 'moplay',
      enabled: false,
      password: 'Numbers97',
      balance: 45.0,
      name: 'adam#shaw',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '11:45',
      betTypes: [
        {
          stake: 3,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '13:45',
      updatedAt: 1655994539138,
      username: 'shawadam97',
      __key: {
        bookie: 'moplay',
        name: 'adam#shaw',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Adam Shaw',
      type: 'Inactive',
      bookie: 'sportnation',
      enabled: false,
      password: 'Numbers97',
      balance: 0,
      name: 'adam#shaw',
      days: [1, 2, 3, 4, 5],
      startTime: '12:15',
      betTypes: [
        {
          stake: 3,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '16:55',
      updatedAt: 1655994646846,
      username: 'damwash',
      __key: {
        bookie: 'sportnation',
        name: 'adam#shaw',
      },
    },
    {
      notes: '',
      displayName: 'Adam Shaw',
      type: 'Active',
      bookie: 'mrplay',
      enabled: true,
      password: 'Numbers95',
      balance: 0,
      name: 'adam#shaw',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '08:45',
      betTypes: [
        {
          stake: 4,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '11:15',
      updatedAt: 1655994746026,
      username: 'Damwash',
      __key: {
        bookie: 'mrplay',
        name: 'adam#shaw',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Adam Shaw',
      type: 'Inactive',
      bookie: 'boylesports',
      enabled: false,
      password: 'Numbers97',
      balance: 0,
      name: 'adam#shaw',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '11:50',
      betTypes: [
        {
          stake: 2,
          betTypeName: 'lucky15',
          order: 1,
        },
      ],
      endTime: '14:45',
      updatedAt: 1655994387565,
      username: 'adamshaw23',
      __key: {
        bookie: 'boylesports',
        name: 'adam#shaw',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Adam Shaw',
      type: 'Inactive',
      bookie: 'betvictor',
      enabled: false,
      password: 'Numbers95',
      balance: 0,
      name: 'adam#shaw',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '12:10',
      betTypes: [
        {
          stake: 2,
          betTypeName: 'lucky15',
          order: 1,
        },
      ],
      endTime: '16:25',
      updatedAt: 1655994379528,
      username: 'adamshawrugby@gmail.com',
      __key: {
        bookie: 'betvictor',
        name: 'adam#shaw',
      },
    },
    {
      notes: 'Verification_Pending',
      displayName: 'Adam Shaw',
      type: 'Inactive',
      bookie: 'pokerstarssports',
      enabled: false,
      password: 'Numbers95',
      balance: 92.63,
      name: 'adam#shaw',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '07:00',
      betTypes: [
        {
          stake: 3,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '11:00',
      updatedAt: 1655994746354,
      username: 'Damwash',
      __key: {
        bookie: 'pokerstarssports',
        name: 'adam#shaw',
      },
    },
  ],
  bookieRes: [
    {
      notes: '',
      displayName: 'Adam Shaw',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: true,
      password: 'Numbers97',
      balance: 34.7,
      name: 'adam#shaw',
      days: [1, 2, 3, 4, 5, 6],
      startTime: '12:05',
      betTypes: [
        {
          stake: 4,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '19:15',
      updatedAt: 1658314667878,
      username: 'shawadam97',
      __key: {
        bookie: 'bwin',
        name: 'adam#shaw',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Aidan Gale',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Hammer88',
      balance: 100.0,
      name: 'aidan#gale',
      days: [1, 2, 3, 4, 5],
      startTime: '12:00',
      betTypes: [
        {
          stake: 10,
          betTypeName: 'single',
          order: 1,
        },
      ],
      endTime: '15:20',
      updatedAt: 1655994594576,
      username: 'AGale11',
      __key: {
        bookie: 'bwin',
        name: 'aidan#gale',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Alex Brown',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Hammer69',
      balance: 0,
      name: 'alex#brown',
      days: [1, 2, 3, 4, 5],
      startTime: '11:10',
      betTypes: [
        {
          stake: 3,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '15:10',
      updatedAt: 1655994531265,
      username: 'abrown69',
      __key: {
        bookie: 'bwin',
        name: 'alex#brown',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Alex Johnston',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Captain92',
      balance: 25.0,
      name: 'alex#johnston',
      days: [2, 3, 4, 5, 6],
      startTime: '14:30',
      betTypes: [
        {
          stake: 3,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '17:10',
      updatedAt: 1655994534283,
      username: 'Alexjohn92',
      __key: {
        bookie: 'bwin',
        name: 'alex#johnston',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Alexandra ChappleHyam',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Fishtank99',
      balance: 0,
      name: 'alexandra#chapplehyam',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '11:20',
      betTypes: [
        {
          stake: 10,
          betTypeName: 'single',
          order: 1,
        },
      ],
      endTime: '16:30',
      updatedAt: 1655994588110,
      username: 'HyAlex99',
      __key: {
        bookie: 'bwin',
        name: 'alexandra#chapplehyam',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Alexandra Loizou',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Firework121',
      balance: 0,
      name: 'alexandra#loizou',
      days: [1, 2, 3, 4, 5],
      startTime: '13:00',
      betTypes: [
        {
          stake: 10,
          betTypeName: 'single',
          order: 1,
        },
      ],
      endTime: '16:20',
      updatedAt: 1655994642106,
      username: 'Loizou16',
      __key: {
        bookie: 'bwin',
        name: 'alexandra#loizou',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Alfio Monterosso',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Washington69',
      balance: 67.4,
      name: 'alfio#monterosso',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '15:10',
      betTypes: [
        {
          stake: 3,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '17:00',
      updatedAt: 1655994451476,
      username: 'alfiomonte69',
      __key: {
        bookie: 'bwin',
        name: 'alfio#monterosso',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Amanda ConnellyHayward',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Diamond29',
      balance: 0,
      name: 'amanda#connellyhayward',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '13:00',
      betTypes: [
        {
          stake: 2,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '15:25',
      updatedAt: 1655994549529,
      username: 'connellyam29',
      __key: {
        bookie: 'bwin',
        name: 'amanda#connellyhayward',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Anacleto Gualini',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Capri1952',
      balance: 0,
      name: 'anacleto#gualini',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '12:00',
      betTypes: [
        {
          stake: 2.5,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '16:00',
      updatedAt: 1655994494811,
      username: 'Anacletoguali',
      __key: {
        bookie: 'bwin',
        name: 'anacleto#gualini',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Andrew McCusker',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Halloween19',
      balance: 72.5,
      name: 'andrew#mccusker',
      days: [1, 2, 3, 4, 5, 6],
      startTime: '12:10',
      betTypes: [
        {
          stake: 3.5,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '16:10',
      updatedAt: 1655994510702,
      username: 'Mccusker60',
      __key: {
        bookie: 'bwin',
        name: 'andrew#mccusker',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Andrew Smith',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Candle73',
      balance: 10.0,
      name: 'andrew#smith',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '13:45',
      betTypes: [
        {
          stake: 10,
          betTypeName: 'single',
          order: 1,
        },
      ],
      endTime: '15:45',
      updatedAt: 1655994648165,
      username: 'PMWTGADCX123@gmail.com',
      __key: {
        bookie: 'bwin',
        name: 'andrew#smith',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Andy Newham',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Numbers55',
      balance: 95.0,
      name: 'andy#newham',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '10:00',
      betTypes: [
        {
          stake: 4,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '18:00',
      updatedAt: 1655994424117,
      username: 'andynew55',
      __key: {
        bookie: 'bwin',
        name: 'andy#newham',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Ann Campbell',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: true,
      password: 'Tomsoup99',
      balance: 0,
      name: 'ann#campbell',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '14:30',
      betTypes: [
        {
          stake: 2,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '18:00',
      updatedAt: 1655994499604,
      username: 'ladysmocka@gmail.com',
      __key: {
        bookie: 'bwin',
        name: 'ann#campbell',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Aviva Caller',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'United94',
      balance: 0,
      name: 'aviva#caller',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '08:30',
      betTypes: [
        {
          stake: 6,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '17:00',
      updatedAt: 1655994490137,
      username: 'AvivaCall94',
      __key: {
        bookie: 'bwin',
        name: 'aviva#caller',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Barry Holliday',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Beachbod000',
      balance: 230.7,
      name: 'barry#holliday',
      days: [2, 3, 4, 5, 6],
      startTime: '12:10',
      betTypes: [
        {
          stake: 3,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '16:00',
      updatedAt: 1655994592112,
      username: 'HollidayB70',
      __key: {
        bookie: 'bwin',
        name: 'barry#holliday',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Billy Bearder',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'BillyGoat74',
      balance: 0,
      name: 'billy#bearder',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '09:00',
      betTypes: [
        {
          stake: 3,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '12:20',
      updatedAt: 1655994496902,
      username: 'BillsBearder',
      __key: {
        bookie: 'bwin',
        name: 'billy#bearder',
      },
    },
    {
      notes: '',
      displayName: 'Carl Palmer',
      type: 'Active',
      bookie: 'bwin',
      enabled: true,
      password: 'Carlyfloss00',
      balance: 125,
      name: 'carl#palmer',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '12:30',
      betTypes: [
        {
          stake: 3,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '16:45',
      updatedAt: 1655994621266,
      username: 'CPalmer888',
      __key: {
        bookie: 'bwin',
        name: 'carl#palmer',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Cathrine Lounds',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Caletta63',
      balance: 23.0,
      name: 'cathrine#lounds',
      days: [2],
      startTime: '10:00',
      betTypes: [
        {
          stake: 4,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '10:15',
      updatedAt: 1655994485413,
      username: 'Lounds01',
      __key: {
        bookie: 'bwin',
        name: 'cathrine#lounds',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Charles Stock',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Driver77',
      balance: 0,
      name: 'charles#stock',
      days: [2, 3, 4, 5, 6],
      startTime: '12:00',
      betTypes: [
        {
          stake: 4,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '16:00',
      updatedAt: 1655994472265,
      username: 'Charles7766',
      __key: {
        bookie: 'bwin',
        name: 'charles#stock',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Chloe Holmes',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Jaxsons76',
      balance: 0,
      name: 'chloe#holmes',
      days: [1, 2, 3, 4, 5, 6],
      startTime: '09:25',
      betTypes: [
        {
          stake: 10,
          betTypeName: 'single',
          order: 1,
        },
      ],
      endTime: '14:20',
      updatedAt: 1655994599924,
      username: 'ChloHolmes98',
      __key: {
        bookie: 'bwin',
        name: 'chloe#holmes',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Chris Johnson',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Honeypot89',
      balance: 72.0,
      name: 'chris#johnson',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '12:30',
      betTypes: [
        {
          stake: 5,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '16:10',
      updatedAt: 1655994499963,
      username: 'ChrisJohnson11',
      __key: {
        bookie: 'bwin',
        name: 'chris#johnson',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Chris Traill',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Quorn82!',
      balance: 0,
      name: 'chris#traill',
      days: [1, 2, 3, 4, 5],
      startTime: '11:30',
      betTypes: [
        {
          stake: 3,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '14:30',
      updatedAt: 1655994756058,
      username: 'christraill82@gmail.com',
      __key: {
        bookie: 'bwin',
        name: 'chris#traill',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Christie Loannou',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'VinoRed247',
      balance: 0,
      name: 'christie#loannou',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '11:45',
      betTypes: [
        {
          stake: 10,
          betTypeName: 'single',
          order: 1,
        },
      ],
      endTime: '17:15',
      updatedAt: 1655994725030,
      username: 'christiaioa@gmail.com',
      __key: {
        bookie: 'bwin',
        name: 'christie#loannou',
      },
    },
    {
      notes: '',
      displayName: 'Christopher Ashton',
      type: 'Active',
      bookie: 'bwin',
      enabled: true,
      password: 'Airwaves1',
      balance: 100,
      name: 'christopher#ashton',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '14:30',
      betTypes: [
        {
          stake: 3,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '18:30',
      updatedAt: 1655994881970,
      username: 'ca1241105@gmail.com',
      __key: {
        bookie: 'bwin',
        name: 'christopher#ashton',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Colin Wormleighton',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Wormzy40!',
      balance: 0,
      name: 'colin#wormleighton',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '11:00',
      betTypes: [
        {
          stake: 2.5,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '15:10',
      updatedAt: 1655994517726,
      username: 'wormman40',
      __key: {
        bookie: 'bwin',
        name: 'colin#wormleighton',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Craig Henson',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Walkers01',
      balance: 50.0,
      name: 'craig#henson',
      days: [2, 3, 4, 5, 6],
      startTime: '12:15',
      betTypes: [
        {
          stake: 3,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '15:00',
      updatedAt: 1655994522225,
      username: 'Henzy0108',
      __key: {
        bookie: 'bwin',
        name: 'craig#henson',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Craig Kerr',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'TwatBag87',
      balance: 0,
      name: 'craig#kerr',
      days: [1, 2, 3, 4, 5, 6],
      startTime: '13:20',
      betTypes: [
        {
          stake: 4,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '16:50',
      updatedAt: 1655994507631,
      username: 'KerrCraig32',
      __key: {
        bookie: 'bwin',
        name: 'craig#kerr',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Danny Brown',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'YuppyCami106',
      balance: 0,
      name: 'danny#brown',
      days: [1, 2, 3, 4, 5],
      startTime: '12:00',
      betTypes: [
        {
          stake: 3,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '14:00',
      updatedAt: 1655994684202,
      username: 'browndannylee@gmx.co.uk',
      __key: {
        bookie: 'bwin',
        name: 'danny#brown',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'David Froggatt',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Circus27',
      balance: 0,
      name: 'david#froggatt',
      days: [1, 2, 3, 4, 5, 6],
      startTime: '12:30',
      betTypes: [
        {
          stake: 3.5,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '16:00',
      updatedAt: 1655994507245,
      username: 'Froggs49',
      __key: {
        bookie: 'bwin',
        name: 'david#froggatt',
      },
    },
    {
      notes: 'Ended',
      displayName: 'David LeneveRoff',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Roffey18',
      balance: 0,
      name: 'david#leneveroff',
      days: [1, 2, 3, 4, 5],
      startTime: '09:30',
      betTypes: [
        {
          stake: 5,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '10:30',
      updatedAt: 1655994502405,
      username: 'lenroff18',
      __key: {
        bookie: 'bwin',
        name: 'david#leneveroff',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'David Mackessack',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Bagsack59',
      balance: 12.0,
      name: 'david#mackessack',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '13:10',
      betTypes: [
        {
          stake: 3.5,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '17:30',
      updatedAt: 1655994586274,
      username: 'Mackessack81',
      __key: {
        bookie: 'bwin',
        name: 'david#mackessack',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'David Sykes',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Billabong186',
      balance: 0,
      name: 'david#sykes',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '09:30',
      betTypes: [
        {
          stake: 10,
          betTypeName: 'single',
          order: 1,
        },
      ],
      endTime: '12:30',
      updatedAt: 1655994661789,
      username: 'David58Sykes',
      __key: {
        bookie: 'bwin',
        name: 'david#sykes',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Donna Bailey',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Double75',
      balance: 0,
      name: 'donna#bailey',
      days: [1, 2, 3, 4, 5],
      startTime: '08:55',
      betTypes: [
        {
          stake: 0.86,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '18:00',
      updatedAt: 1655994483949,
      username: 'DBailey75',
      __key: {
        bookie: 'bwin',
        name: 'donna#bailey',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Evrol Campbell',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Bovril1950',
      balance: 0,
      name: 'evrol#campbell',
      days: [2, 3, 4, 5],
      startTime: '12:15',
      betTypes: [
        {
          stake: 10,
          betTypeName: 'single',
          order: 1,
        },
      ],
      endTime: '17:15',
      updatedAt: 1655994650910,
      username: 'iambillcampbell@outlook.com',
      __key: {
        bookie: 'bwin',
        name: 'evrol#campbell',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Gary Forsyth',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Blue1110',
      balance: 12.0,
      name: 'gary#forsyth',
      days: [1, 2, 3, 4, 5],
      startTime: '12:15',
      betTypes: [
        {
          stake: 10,
          betTypeName: 'single',
          order: 1,
        },
      ],
      endTime: '16:15',
      updatedAt: 1655994564907,
      username: 'Garyf1976',
      __key: {
        bookie: 'bwin',
        name: 'gary#forsyth',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Gary Thornley',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Sadwas72',
      balance: 0,
      name: 'gary#thornley',
      days: [1, 2, 3, 4, 5, 6],
      startTime: '10:30',
      betTypes: [
        {
          stake: 1.5,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '15:00',
      updatedAt: 1655994479744,
      username: 'thorng1972@gmail.com',
      __key: {
        bookie: 'bwin',
        name: 'gary#thornley',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Gianluca Milani',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Carrots41',
      balance: 0,
      name: 'gianluca#milani',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '11:50',
      betTypes: [
        {
          stake: 10,
          betTypeName: 'single',
          order: 1,
        },
      ],
      endTime: '15:50',
      updatedAt: 1655994493995,
      username: 'lucamil9',
      __key: {
        bookie: 'bwin',
        name: 'gianluca#milani',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Hayley Porter',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Lonely81',
      balance: 0,
      name: 'hayley#porter',
      days: [1, 2, 3, 4, 5],
      startTime: '08:55',
      betTypes: [
        {
          stake: 4,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '11:50',
      updatedAt: 1655994494386,
      username: 'HPorter81',
      __key: {
        bookie: 'bwin',
        name: 'hayley#porter',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Helen Hill',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Africa59',
      balance: 0,
      name: 'helen#hill',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '12:15',
      betTypes: [
        {
          stake: 10,
          betTypeName: 'single',
          order: 1,
        },
      ],
      endTime: '15:20',
      updatedAt: 1655994644328,
      username: 'helenhillrich1959@gmail.com',
      __key: {
        bookie: 'bwin',
        name: 'helen#hill',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Holly Ledgeway',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Gouda1997',
      balance: 0,
      name: 'holly#ledgeway',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '12:10',
      betTypes: [
        {
          stake: 10,
          betTypeName: 'single',
          order: 1,
        },
      ],
      endTime: '14:10',
      updatedAt: 1655994677594,
      username: 'hledgeway@gmx.co.uk',
      __key: {
        bookie: 'bwin',
        name: 'holly#ledgeway',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Ian Green',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Forrest83',
      balance: 0,
      name: 'ian#green',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '12:00',
      betTypes: [
        {
          stake: 4.5,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '16:00',
      updatedAt: 1655994445919,
      username: 'Greenish17',
      __key: {
        bookie: 'bwin',
        name: 'ian#green',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Ian Lilley',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Tarnowskie1',
      balance: 0,
      name: 'ian#lilley',
      days: [1, 2, 3, 4, 5],
      startTime: '09:00',
      betTypes: [
        {
          stake: 0.86,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '17:00',
      updatedAt: 1655994442532,
      username: 'ianlilley495@gmail.com',
      __key: {
        bookie: 'bwin',
        name: 'ian#lilley',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Jade Simpson',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Dunstan90!',
      balance: 0,
      name: 'jade#simpson',
      days: [2, 3, 4],
      startTime: '12:30',
      betTypes: [
        {
          stake: 10,
          betTypeName: 'single',
          order: 1,
        },
      ],
      endTime: '16:30',
      updatedAt: 1655994853890,
      username: 'jade19simpson@gmail.com',
      __key: {
        bookie: 'bwin',
        name: 'jade#simpson',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'James Walton',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Bookpass1926',
      balance: 0,
      name: 'james#walton',
      days: [0, 1, 2, 3, 4, 5, 6],
      startTime: '13:20',
      betTypes: [
        {
          stake: 4,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '16:00',
      updatedAt: 1655994494248,
      username: 'jw187937@gmail.com',
      __key: {
        bookie: 'bwin',
        name: 'james#walton',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Jason Woodward',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Zombie70',
      balance: 0,
      name: 'jason#woodward',
      days: [1, 2, 3, 4, 5],
      startTime: '09:00',
      betTypes: [
        {
          stake: 3,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '11:30',
      updatedAt: 1655994817755,
      username: 'woodworjason@gmail.com',
      __key: {
        bookie: 'bwin',
        name: 'jason#woodward',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'John Grieve',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Deathcure63',
      balance: 73.78,
      name: 'john#grieve',
      days: [1, 2, 3, 4, 5, 6],
      startTime: '13:40',
      betTypes: [
        {
          stake: 3.5,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '16:40',
      updatedAt: 1655994554145,
      username: 'Grievy78',
      __key: {
        bookie: 'bwin',
        name: 'john#grieve',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'John Hales',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Halifax58',
      balance: 0,
      name: 'john#hales',
      days: [1, 2, 3, 4, 5],
      startTime: '10:45',
      betTypes: [
        {
          stake: 10,
          betTypeName: 'single',
          order: 1,
        },
      ],
      endTime: '14:45',
      updatedAt: 1655994651129,
      username: 'halesj490@gmail.com',
      __key: {
        bookie: 'bwin',
        name: 'john#hales',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'John Heeson',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Holiday58',
      balance: 0,
      name: 'john#heeson',
      days: [1, 2, 3, 4, 5, 6],
      startTime: '10:00',
      betTypes: [
        {
          stake: 0.86,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '14:30',
      updatedAt: 1655994486164,
      username: 'HeesonJ58',
      __key: {
        bookie: 'bwin',
        name: 'john#heeson',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'John Whetton',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Whetto69',
      balance: 0,
      name: 'john#whetton',
      days: [1, 2, 3, 4, 5, 6],
      startTime: '12:00',
      betTypes: [
        {
          stake: 4,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '15:30',
      updatedAt: 1655994488186,
      username: 'Jwhetton',
      __key: {
        bookie: 'bwin',
        name: 'john#whetton',
      },
    },
    {
      notes: 'Zeroed',
      displayName: 'Jonathan Hunt',
      type: 'Inactive',
      bookie: 'bwin',
      enabled: false,
      password: 'Deer26',
      balance: 0,
      name: 'jonathan#hunt',
      days: [1, 2, 3, 4, 5, 6],
      startTime: '09:00',
      betTypes: [
        {
          stake: 4,
          betTypeName: 'patent',
          order: 1,
        },
      ],
      endTime: '11:30',
      updatedAt: 1655994507490,
      username: 'Jonohunt26',
      __key: {
        bookie: 'bwin',
        name: 'jonathan#hunt',
      },
    },
  ],
  syndStake: [
    { syndicate: 'Adam Shaw', bookie: 'bet600', stake: 466.15, bets: 123 },
    { syndicate: 'Adam Shaw', bookie: 'fitzdares', stake: 45, bets: 3 },
    { syndicate: 'Adam Shaw', bookie: 'karamba', stake: 21, bets: 3 },
    { syndicate: 'Adam Shaw', bookie: 'mrplay', stake: 56, bets: 8 },
    { syndicate: 'Adam Shaw', bookie: 'paddypower', stake: 251.36, bets: 38 },
    { syndicate: 'Adam Shaw', bookie: 'sportingindex', stake: 89.95, bets: 5 },
    { syndicate: 'Adam Shaw', bookie: 'starsports', stake: 15, bets: 1 },
    { syndicate: 'Adam Shaw', bookie: 'thepools', stake: 216.27, bets: 37 },
  ],
  bookieStake: [
    { bookie: 'bwin', syndicate: 'Chris Traill', stake: 814.73, bets: 39 },
    { bookie: 'bwin', syndicate: 'Danny Brown', stake: 139.86, bets: 7 },
    { bookie: 'bwin', syndicate: 'Evrol Campbell', stake: 428.33, bets: 21 },
    { bookie: 'bwin', syndicate: 'Kavita Kachiwala', stake: 224, bets: 16 },
    { bookie: 'bwin', syndicate: 'Marcel Bechara', stake: 630, bets: 30 },
  ],
  syndBets: [
    {
      betDate: '2022-08-17',
      betDuration: 30,
      displayName: 'Adam Shaw',
      winnings: 87.5,
      type: 'single',
      bookie: '32red',
      stake: 25,
      createdAt: '2022-08-17T09:09:22.744Z',
      selections: [
        {
          qs: 2819.545254303599,
          lp: 3.5,
          atl: [
            { size: 322.39, price: 3.45 },
            { size: 138.75, price: 3.4 },
            { size: 151.46, price: 3.35 },
          ],
          eventName: '15:00 York',
          vp: 1,
          atb: [
            { size: 75.77, price: 3.5 },
            { size: 486.08, price: 3.55 },
            { size: 257.74, price: 3.6 },
          ],
          expectedOdds: '3.5',
          horseName: 'Secret State',
          status: 'win',
        },
      ],
      name: 'adam#shaw',
      returns: 87.5,
      id: '01291d58-bd07-4197-b920-18735c02033b',
      status: 'placed',
      updatedAt: '2022-08-17T09:09:22.744Z',
    },
    {
      betDate: '2022-08-17',
      betDuration: 30,
      displayName: 'Adam Shaw',
      winnings: 1977.9,
      type: 'patent',
      bookie: 'virginbet',
      stake: 1,
      createdAt: '2022-08-17T09:09:22.774Z',
      selections: [
        {
          qs: 9236.118888888888,
          lp: 36,
          atl: [
            { size: 110.84, price: 34 },
            { size: 5, price: 32 },
            { size: 16.62, price: 30 },
          ],
          eventName: '15:00 York',
          vp: 0.9444444444444444,
          atb: [
            { size: 287.63, price: 36 },
            { size: 19.26, price: 38 },
            { size: 71.58, price: 40 },
          ],
          expectedOdds: '34',
          horseName: 'Grand Alliance',
          status: 'win',
        },
        {
          qs: 1173.7949999999998,
          lp: 18,
          atl: [
            { size: 2.33, price: 17.5 },
            { size: 7.27, price: 17 },
            { size: 5.54, price: 14.5 },
          ],
          eventName: '17:25 Sligo',
          vp: 1.1666666666666667,
          atb: [
            { size: 47.91, price: 18 },
            { size: 1, price: 26 },
            { size: 9.89, price: 80 },
          ],
          expectedOdds: '21',
          horseName: 'Angelsworknovrtime',
          status: 'win',
        },
        {
          qs: 142.24896657004962,
          lp: 1.63,
          atl: [
            { size: 1.49, price: 1.61 },
            { size: 12.54, price: 1.6 },
            { size: 27.82, price: 1.52 },
          ],
          eventName: '18:05 Worcester',
          vp: 0.9573170731707318,
          atb: [
            { size: 11.74, price: 1.64 },
            { size: 25.6, price: 1.65 },
            { size: 58.16, price: 1.66 },
          ],
          expectedOdds: '1.57',
          horseName: 'Light N Strike',
          status: 'win',
        },
      ],
      name: 'adam#shaw',
      returns: 1977.9,
      id: '01dc51f3-d510-400a-a46a-e53cd883cbda',
      status: 'placed',
      updatedAt: '2022-08-17T09:09:22.774Z',
    },
    {
      betDate: '2022-08-17',
      betDuration: 30,
      displayName: 'Adam Shaw',
      winnings: 434.9,
      type: 'patent',
      bookie: 'livescorebet',
      stake: 2,
      createdAt: '2022-08-17T09:09:22.807Z',
      selections: [
        {
          qs: 2729.938906649616,
          lp: 3.4,
          atl: [
            { size: 81.01, price: 3.35 },
            { size: 601.96, price: 3.3 },
            { size: 254.19, price: 3.25 },
          ],
          eventName: '14:25 York',
          vp: 0.9558823529411765,
          atb: [
            { size: 599.92, price: 3.4 },
            { size: 282.93, price: 3.45 },
            { size: 352.85, price: 3.5 },
          ],
          expectedOdds: '3.25',
          horseName: 'Local Dynasty',
          status: 'win',
        },
        {
          qs: 964.4716666666668,
          lp: 17,
          atl: [
            { size: 1.64, price: 15 },
            { size: 14.16, price: 14.5 },
            { size: 2.55, price: 10.5 },
          ],
          eventName: '17:25 Sligo',
          vp: 1.0555555555555556,
          atb: [
            { size: 48.09, price: 18 },
            { size: 1, price: 26 },
            { size: 9.89, price: 80 },
          ],
          expectedOdds: '19',
          horseName: 'Angelsworknovrtime',
          status: 'win',
        },
        {
          qs: 142.24896657004962,
          lp: 1.63,
          atl: [
            { size: 1.49, price: 1.61 },
            { size: 12.54, price: 1.6 },
            { size: 15, price: 1.53 },
          ],
          eventName: '18:05 Worcester',
          vp: 0.9573170731707318,
          atb: [
            { size: 11.74, price: 1.64 },
            { size: 25.6, price: 1.65 },
            { size: 58.16, price: 1.66 },
          ],
          expectedOdds: '1.57',
          horseName: 'Light N Strike',
          status: 'win',
        },
      ],
      name: 'adam#shaw',
      returns: 434.9,
      id: '0490d7ff-8efa-4098-9e2d-c1ec5a074e1b',
      status: 'placed',
      updatedAt: '2022-08-17T09:09:22.807Z',
    },
    {
      betDate: '2022-08-17',
      betDuration: 30,
      displayName: 'Adam Shaw',
      winnings: 5697,
      type: 'patent',
      bookie: 'virginbet',
      stake: 3,
      createdAt: '2022-08-17T09:09:22.828Z',
      selections: [
        {
          qs: 240.29641025641024,
          lp: 19.5,
          atl: [
            { size: 1.41, price: 17.5 },
            { size: 2.37, price: 17 },
            { size: 1.59, price: 16.5 },
          ],
          eventName: '20:10 Worcester',
          vp: 0.9743589743589743,
          atb: [
            { size: 3.23, price: 19.5 },
            { size: 10, price: 20 },
            { size: 8.78, price: 22 },
          ],
          expectedOdds: '19',
          horseName: 'Go Fox',
          status: 'win',
        },
        {
          qs: 69.67167721518987,
          lp: 3.95,
          atl: [
            { size: 5, price: 3.9 },
            { size: 15.14, price: 3.85 },
            { size: 7.51, price: 3.8 },
          ],
          eventName: '14:50 Wolverhampton',
          vp: 0.9493670886075949,
          atb: [
            { size: 19.57, price: 3.95 },
            { size: 6.19, price: 4.1 },
            { size: 8.48, price: 4.2 },
          ],
          expectedOdds: '3.75',
          horseName: 'Torvi',
          status: 'win',
        },
        {
          qs: 48.36474358974358,
          lp: 20,
          atl: [
            { size: 2, price: 17 },
            { size: 1.83, price: 15 },
            { size: 1.52, price: 14 },
          ],
          eventName: '20:10 Worcester',
          vp: 0.9743589743589743,
          atb: [
            { size: 1.15, price: 19.5 },
            { size: 1.5, price: 20 },
            { size: 22.39, price: 21 },
          ],
          expectedOdds: '19',
          horseName: 'Merchant House',
          status: 'win',
        },
      ],
      name: 'adam#shaw',
      returns: 5697,
      id: '04b0acb3-2d7f-4edd-ba34-836002504031',
      status: 'placed',
      updatedAt: '2022-08-17T09:09:22.828Z',
    },
    {
      betDate: '2022-08-17',
      betDuration: 30,
      displayName: 'Adam Shaw',
      winnings: 41577,
      type: 'patent',
      bookie: 'livescorebet',
      stake: 3,
      createdAt: '2022-08-17T09:09:22.851Z',
      selections: [
        {
          qs: 9370.343333333332,
          lp: 34,
          atl: [
            { size: 109.3, price: 34 },
            { size: 5, price: 32 },
            { size: 16.62, price: 30 },
          ],
          eventName: '15:00 York',
          vp: 0.9444444444444444,
          atb: [
            { size: 291.81, price: 36 },
            { size: 19.26, price: 38 },
            { size: 71.58, price: 40 },
          ],
          expectedOdds: '34',
          horseName: 'Grand Alliance',
          status: 'win',
        },
        {
          qs: 1173.7949999999998,
          lp: 18,
          atl: [
            { size: 2.33, price: 17.5 },
            { size: 7.27, price: 17 },
            { size: 5.54, price: 14.5 },
          ],
          eventName: '17:25 Sligo',
          vp: 1.1666666666666667,
          atb: [
            { size: 47.91, price: 18 },
            { size: 1, price: 26 },
            { size: 9.89, price: 80 },
          ],
          expectedOdds: '21',
          horseName: 'Angelsworknovrtime',
          status: 'win',
        },
        {
          qs: 118.62761904761905,
          lp: 17,
          atl: [
            { size: 1.82, price: 15 },
            { size: 9.6, price: 14.5 },
            { size: 2.55, price: 10.5 },
          ],
          eventName: '20:00 Sligo',
          vp: 0.9714285714285714,
          atb: [
            { size: 3.1, price: 17.5 },
            { size: 4.2, price: 18 },
            { size: 33.64, price: 19 },
          ],
          expectedOdds: '17',
          horseName: 'Happy Lad',
          status: 'win',
        },
      ],
      name: 'adam#shaw',
      returns: 41577,
      id: '04dd9230-3389-49fb-8a77-02eb19b0f5e8',
      status: 'placed',
      updatedAt: '2022-08-17T09:09:22.851Z',
    },
    {
      betDate: '2022-08-17',
      betDuration: 30,
      displayName: 'Adam Shaw',
      winnings: 4092,
      type: 'patent',
      bookie: 'virginbet',
      stake: 3,
      createdAt: '2022-08-17T09:09:22.869Z',
      selections: [
        {
          qs: 240.29641025641024,
          lp: 19.5,
          atl: [
            { size: 1.41, price: 17.5 },
            { size: 2.37, price: 17 },
            { size: 1.59, price: 16.5 },
          ],
          eventName: '20:10 Worcester',
          vp: 0.9743589743589743,
          atb: [
            { size: 3.23, price: 19.5 },
            { size: 10, price: 20 },
            { size: 8.78, price: 22 },
          ],
          expectedOdds: '19',
          horseName: 'Go Fox',
          status: 'win',
        },
        {
          qs: 139.76716013533073,
          lp: 9.6,
          atl: [
            { size: 3.92, price: 8.8 },
            { size: 18.19, price: 8.6 },
            { size: 8.52, price: 7.2 },
          ],
          eventName: '20:00 Sligo',
          vp: 1.0106382978723405,
          atb: [
            { size: 5, price: 9.4 },
            { size: 5, price: 9.6 },
            { size: 4.86, price: 9.8 },
          ],
          expectedOdds: '9.5',
          horseName: 'Memorable Daise',
          status: 'win',
        },
        {
          qs: 80.39278665413534,
          lp: 5.7,
          atl: [
            { size: 32.32, price: 5.4 },
            { size: 2.26, price: 5.3 },
            { size: 1.72, price: 5.1 },
          ],
          eventName: '17:32 Worcester',
          vp: 0.9821428571428572,
          atb: [
            { size: 2.71, price: 5.6 },
            { size: 12.39, price: 5.7 },
            { size: 2, price: 6.4 },
          ],
          expectedOdds: '5.5',
          horseName: 'Grand Sancy',
          status: 'win',
        },
      ],
      name: 'adam#shaw',
      returns: 4092,
      id: '068af1fd-c2bc-4801-bcf6-378ab40a1cfa',
      status: 'placed',
      updatedAt: '2022-08-17T09:09:22.869Z',
    },
    {
      betDate: '2022-08-17',
      betDuration: 30,
      displayName: 'Adam Shaw',
      winnings: 34017,
      type: 'patent',
      bookie: 'virginbet',
      stake: 3,
      createdAt: '2022-08-17T09:09:22.886Z',
      selections: [
        {
          qs: 9627.232222222221,
          lp: 34,
          atl: [
            { size: 120.18, price: 34 },
            { size: 5, price: 32 },
            { size: 16.61, price: 30 },
          ],
          eventName: '15:00 York',
          vp: 0.9444444444444444,
          atb: [
            { size: 299.81, price: 36 },
            { size: 19.26, price: 38 },
            { size: 71.58, price: 40 },
          ],
          expectedOdds: '34',
          horseName: 'Grand Alliance',
          status: 'win',
        },
        {
          qs: 1083.576025974026,
          lp: 16,
          atl: [
            { size: 1.19, price: 16 },
            { size: 7.49, price: 15.5 },
            { size: 7.01, price: 15 },
          ],
          eventName: '14:25 York',
          vp: 1.0303030303030303,
          atb: [
            { size: 11.91, price: 16.5 },
            { size: 8.59, price: 17 },
            { size: 44.14, price: 17.5 },
          ],
          expectedOdds: '17',
          horseName: 'Shaquille',
          status: 'win',
        },
        {
          qs: 118.62761904761905,
          lp: 17,
          atl: [
            { size: 1.82, price: 15 },
            { size: 9.6, price: 14.5 },
            { size: 2.55, price: 10.5 },
          ],
          eventName: '20:00 Sligo',
          vp: 0.9714285714285714,
          atb: [
            { size: 3.1, price: 17.5 },
            { size: 4.2, price: 18 },
            { size: 33.64, price: 19 },
          ],
          expectedOdds: '17',
          horseName: 'Happy Lad',
          status: 'win',
        },
      ],
      name: 'adam#shaw',
      returns: 34017,
      id: '07d026ce-1527-4178-a4b4-0b487ec15c50',
      status: 'placed',
      updatedAt: '2022-08-17T09:09:22.886Z',
    },
    {
      betDate: '2022-08-17',
      betDuration: 30,
      displayName: 'Adam Shaw',
      winnings: 486.3,
      type: 'patent',
      bookie: 'livescorebet',
      stake: 2,
      createdAt: '2022-08-17T09:09:22.902Z',
      selections: [
        {
          qs: 240.29641025641024,
          lp: 19.5,
          atl: [
            { size: 1.41, price: 17.5 },
            { size: 2.37, price: 17 },
            { size: 1.59, price: 16.5 },
          ],
          eventName: '20:10 Worcester',
          vp: 0.9743589743589743,
          atb: [
            { size: 3.23, price: 19.5 },
            { size: 10, price: 20 },
            { size: 8.78, price: 22 },
          ],
          expectedOdds: '19',
          horseName: 'Go Fox',
          status: 'win',
        },
        {
          qs: 142.24896657004962,
          lp: 1.63,
          atl: [
            { size: 1.49, price: 1.61 },
            { size: 12.54, price: 1.6 },
            { size: 27.82, price: 1.52 },
          ],
          eventName: '18:05 Worcester',
          vp: 0.9573170731707318,
          atb: [
            { size: 11.74, price: 1.64 },
            { size: 25.6, price: 1.65 },
            { size: 58.16, price: 1.66 },
          ],
          expectedOdds: '1.57',
          horseName: 'Light N Strike',
          status: 'win',
        },
        {
          qs: 3.560126582278481,
          lp: 3.9,
          atl: [
            { size: 39.86, price: 3.85 },
            { size: 11.07, price: 3.8 },
            { size: 3.33, price: 3.6 },
          ],
          eventName: '17:32 Worcester',
          vp: 0.9493670886075949,
          atb: [
            { size: 1, price: 3.95 },
            { size: 41, price: 4 },
            { size: 23, price: 4.1 },
          ],
          expectedOdds: '3.75',
          horseName: 'Oscar Montel',
          status: 'win',
        },
      ],
      name: 'adam#shaw',
      returns: 486.3,
      id: '080c3609-eea7-4a3e-8cc3-5c84ff1e6063',
      status: 'placed',
      updatedAt: '2022-08-17T09:09:22.902Z',
    },
    {
      betDate: '2022-08-17',
      betDuration: 30,
      displayName: 'Adam Shaw',
      winnings: 5811725,
      type: 'lucky15',
      bookie: '32red',
      stake: 25,
      createdAt: '2022-08-17T09:09:22.918Z',
      selections: [
        {
          qs: 9627.232222222221,
          lp: 34,
          atl: [
            { size: 120.18, price: 34 },
            { size: 5, price: 32 },
            { size: 16.61, price: 30 },
          ],
          eventName: '15:00 York',
          vp: 0.9444444444444444,
          atb: [
            { size: 299.81, price: 36 },
            { size: 19.26, price: 38 },
            { size: 71.58, price: 40 },
          ],
          expectedOdds: '34',
          horseName: 'Grand Alliance',
          status: 'win',
        },
        {
          qs: 2819.545254303599,
          lp: 3.5,
          atl: [
            { size: 322.39, price: 3.45 },
            { size: 138.75, price: 3.4 },
            { size: 151.46, price: 3.35 },
          ],
          eventName: '15:00 York',
          vp: 1,
          atb: [
            { size: 75.77, price: 3.5 },
            { size: 486.08, price: 3.55 },
            { size: 257.74, price: 3.6 },
          ],
          expectedOdds: '3.5',
          horseName: 'Secret State',
          status: 'win',
        },
        {
          qs: 1083.576025974026,
          lp: 16,
          atl: [
            { size: 1.19, price: 16 },
            { size: 7.49, price: 15.5 },
            { size: 7.01, price: 15 },
          ],
          eventName: '14:25 York',
          vp: 1.0303030303030303,
          atb: [
            { size: 11.91, price: 16.5 },
            { size: 8.59, price: 17 },
            { size: 44.14, price: 17.5 },
          ],
          expectedOdds: '17',
          horseName: 'Shaquille',
          status: 'win',
        },
        {
          qs: 336.57930000000005,
          lp: 40,
          atl: [
            { size: 1.71, price: 38 },
            { size: 1.19, price: 36 },
            { size: 2.11, price: 34 },
          ],
          eventName: '19:00 Sligo',
          vp: 1.08,
          atb: [
            { size: 2.91, price: 75 },
            { size: 1, price: 80 },
            { size: 2.64, price: 180 },
          ],
          expectedOdds: '81',
          horseName: 'La Scala Note',
          status: 'win',
        },
      ],
      name: 'adam#shaw',
      returns: 5811725,
      id: '086a6b30-ef54-45e3-b4b0-fced06225a64',
      status: 'placed',
      updatedAt: '2022-08-17T09:09:22.918Z',
    },
    {
      betDate: '2022-08-17',
      betDuration: 30,
      displayName: 'Adam Shaw',
      winnings: 7919,
      type: 'patent',
      bookie: 'virginbet',
      stake: 1,
      createdAt: '2022-08-17T09:09:22.936Z',
      selections: [
        {
          qs: 1173.7949999999998,
          lp: 18,
          atl: [
            { size: 2.33, price: 17.5 },
            { size: 7.27, price: 17 },
            { size: 5.54, price: 14.5 },
          ],
          eventName: '17:25 Sligo',
          vp: 1.1666666666666667,
          atb: [
            { size: 47.91, price: 18 },
            { size: 1, price: 26 },
            { size: 9.89, price: 80 },
          ],
          expectedOdds: '21',
          horseName: 'Angelsworknovrtime',
          status: 'win',
        },
        {
          qs: 240.29641025641024,
          lp: 19.5,
          atl: [
            { size: 1.41, price: 17.5 },
            { size: 2.37, price: 17 },
            { size: 1.59, price: 16.5 },
          ],
          eventName: '20:10 Worcester',
          vp: 0.9743589743589743,
          atb: [
            { size: 3.23, price: 19.5 },
            { size: 10, price: 20 },
            { size: 8.78, price: 22 },
          ],
          expectedOdds: '19',
          horseName: 'Go Fox',
          status: 'win',
        },
        {
          qs: 118.62761904761905,
          lp: 17,
          atl: [
            { size: 1.82, price: 15 },
            { size: 9.6, price: 14.5 },
            { size: 2.55, price: 10.5 },
          ],
          eventName: '20:00 Sligo',
          vp: 0.9714285714285714,
          atb: [
            { size: 3.1, price: 17.5 },
            { size: 4.2, price: 18 },
            { size: 33.64, price: 19 },
          ],
          expectedOdds: '17',
          horseName: 'Happy Lad',
          status: 'win',
        },
      ],
      name: 'adam#shaw',
      returns: 7919,
      id: '08ce1f94-4c4d-451f-9673-5a83c6cc777a',
      status: 'placed',
      updatedAt: '2022-08-17T09:09:22.936Z',
    },
  ],
  bookieBets: [
    {
      betDate: '2022-08-17',
      betDuration: 30,
      displayName: 'David LeneveRoff',
      type: 'patent',
      bookie: 'bwin',
      stake: 2,
      createdAt: '2022-08-17T09:09:22.952Z',
      selections: [
        {
          qs: 240.29641025641024,
          lp: 19.5,
          atl: [
            { size: 1.41, price: 17.5 },
            { size: 2.37, price: 17 },
            { size: 1.59, price: 16.5 },
          ],
          eventName: '20:10 Worcester',
          vp: 0.9743589743589743,
          atb: [
            { size: 3.23, price: 19.5 },
            { size: 10, price: 20 },
            { size: 8.78, price: 22 },
          ],
          expectedOdds: '19',
          horseName: 'Go Fox',
          status: 'win',
        },
        {
          qs: 142.24896657004962,
          lp: 1.63,
          atl: [
            { size: 1.49, price: 1.61 },
            { size: 12.54, price: 1.6 },
            { size: 27.82, price: 1.52 },
          ],
          eventName: '18:05 Worcester',
          vp: 0.9573170731707318,
          atb: [
            { size: 11.74, price: 1.64 },
            { size: 25.6, price: 1.65 },
            { size: 58.16, price: 1.66 },
          ],
          expectedOdds: '1.57',
          horseName: 'Light N Strike',
          status: 'win',
        },
        {
          qs: 3.560126582278481,
          lp: 3.9,
          atl: [
            { size: 39.86, price: 3.85 },
            { size: 11.07, price: 3.8 },
            { size: 3.33, price: 3.6 },
          ],
          eventName: '17:32 Worcester',
          vp: 0.9493670886075949,
          atb: [
            { size: 1, price: 3.95 },
            { size: 41, price: 4 },
            { size: 23, price: 4.1 },
          ],
          expectedOdds: '3.75',
          horseName: 'Oscar Montel',
          status: 'win',
        },
      ],
      name: 'david#leneveroff',
      returns: 486.3,
      winnings: 486.3,
      id: '08d811a4-af3d-442b-8075-121556e32b8c',
      status: 'placed',
      updatedAt: '2022-08-17T09:09:22.952Z',
    },
    {
      betDate: '2022-08-17',
      betDuration: 30,
      displayName: 'David LeneveRoff',
      type: 'patent',
      bookie: 'bwin',
      stake: 2,
      createdAt: '2022-08-17T09:09:22.966Z',
      selections: [
        {
          qs: 2729.938906649616,
          lp: 3.4,
          atl: [
            { size: 81.01, price: 3.35 },
            { size: 601.96, price: 3.3 },
            { size: 254.19, price: 3.25 },
          ],
          eventName: '14:25 York',
          vp: 0.9558823529411765,
          atb: [
            { size: 599.92, price: 3.4 },
            { size: 282.93, price: 3.45 },
            { size: 352.85, price: 3.5 },
          ],
          expectedOdds: '3.25',
          horseName: 'Local Dynasty',
          status: 'win',
        },
        {
          qs: 964.4716666666668,
          lp: 17,
          atl: [
            { size: 1.64, price: 15 },
            { size: 14.16, price: 14.5 },
            { size: 2.55, price: 10.5 },
          ],
          eventName: '17:25 Sligo',
          vp: 1.0555555555555556,
          atb: [
            { size: 48.09, price: 18 },
            { size: 1, price: 26 },
            { size: 9.89, price: 80 },
          ],
          expectedOdds: '19',
          horseName: 'Angelsworknovrtime',
          status: 'win',
        },
        {
          qs: 142.24896657004962,
          lp: 1.63,
          atl: [
            { size: 1.49, price: 1.61 },
            { size: 12.54, price: 1.6 },
            { size: 15, price: 1.53 },
          ],
          eventName: '18:05 Worcester',
          vp: 0.9573170731707318,
          atb: [
            { size: 11.74, price: 1.64 },
            { size: 25.6, price: 1.65 },
            { size: 58.16, price: 1.66 },
          ],
          expectedOdds: '1.57',
          horseName: 'Light N Strike',
          status: 'win',
        },
      ],
      name: 'david#leneveroff',
      returns: 434.9,
      winnings: 434.9,
      id: '09940dbb-c14b-4c2f-adf1-fc1cf582be99',
      status: 'placed',
      updatedAt: '2022-08-17T09:09:22.966Z',
    },
    {
      betDate: '2022-08-17',
      betDuration: 30,
      displayName: 'David LeneveRoff',
      type: 'patent',
      bookie: 'bwin',
      stake: 2,
      createdAt: '2022-08-17T09:09:22.981Z',
      selections: [
        {
          qs: 9370.343333333332,
          lp: 34,
          atl: [
            { size: 109.3, price: 34 },
            { size: 5, price: 32 },
            { size: 16.62, price: 30 },
          ],
          eventName: '15:00 York',
          vp: 0.9444444444444444,
          atb: [
            { size: 291.81, price: 36 },
            { size: 19.26, price: 38 },
            { size: 71.58, price: 40 },
          ],
          expectedOdds: '34',
          horseName: 'Grand Alliance',
          status: 'win',
        },
        {
          qs: 1173.7949999999998,
          lp: 18,
          atl: [
            { size: 2.33, price: 17.5 },
            { size: 7.27, price: 17 },
            { size: 5.54, price: 14.5 },
          ],
          eventName: '17:25 Sligo',
          vp: 1.1666666666666667,
          atb: [
            { size: 47.91, price: 18 },
            { size: 1, price: 26 },
            { size: 9.89, price: 80 },
          ],
          expectedOdds: '21',
          horseName: 'Angelsworknovrtime',
          status: 'win',
        },
        {
          qs: 118.62761904761905,
          lp: 17,
          atl: [
            { size: 1.82, price: 15 },
            { size: 9.6, price: 14.5 },
            { size: 2.55, price: 10.5 },
          ],
          eventName: '20:00 Sligo',
          vp: 0.9714285714285714,
          atb: [
            { size: 3.1, price: 17.5 },
            { size: 4.2, price: 18 },
            { size: 33.64, price: 19 },
          ],
          expectedOdds: '17',
          horseName: 'Happy Lad',
          status: 'win',
        },
      ],
      name: 'david#leneveroff',
      returns: 27718,
      winnings: 27718,
      id: '099eec96-180a-4750-af30-a26f13d15078',
      status: 'placed',
      updatedAt: '2022-08-17T09:09:22.981Z',
    },
    {
      betDate: '2022-08-17',
      betDuration: 30,
      displayName: 'Andrew McCulloch',
      type: 'single',
      bookie: 'bwin',
      stake: 25,
      createdAt: '2022-08-17T09:09:22.995Z',
      selections: [
        {
          qs: 2560.267741935484,
          lp: 14.5,
          atl: [
            { size: 70.34, price: 14.5 },
            { size: 122.81, price: 14 },
            { size: 38.55, price: 13.5 },
          ],
          eventName: '15:00 York',
          vp: 1,
          atb: [
            { size: 16.92, price: 15 },
            { size: 158.89, price: 15.5 },
            { size: 112.97, price: 16 },
          ],
          expectedOdds: '15',
          horseName: 'El Bodegon',
          status: 'win',
        },
      ],
      name: 'andrew#mcculloch',
      returns: 375,
      winnings: 375,
      id: '09ab7ffa-6e20-4ce5-a218-c2bb0b7f9543',
      status: 'placed',
      updatedAt: '2022-08-17T09:09:22.995Z',
    },
    {
      betDate: '2022-08-17',
      betDuration: 30,
      displayName: 'Simon Collinson',
      type: 'patent',
      bookie: 'bwin',
      stake: 3,
      createdAt: '2022-08-17T09:09:23.003Z',
      selections: [
        {
          qs: 2729.938906649616,
          lp: 3.4,
          atl: [
            { size: 81.01, price: 3.35 },
            { size: 601.96, price: 3.3 },
            { size: 254.19, price: 3.25 },
          ],
          eventName: '14:25 York',
          vp: 0.9558823529411765,
          atb: [
            { size: 599.92, price: 3.4 },
            { size: 282.93, price: 3.45 },
            { size: 352.85, price: 3.5 },
          ],
          expectedOdds: '3.25',
          horseName: 'Local Dynasty',
          status: 'win',
        },
        {
          qs: 964.4716666666668,
          lp: 17,
          atl: [
            { size: 1.64, price: 15 },
            { size: 14.16, price: 14.5 },
            { size: 2.55, price: 10.5 },
          ],
          eventName: '17:25 Sligo',
          vp: 1.0555555555555556,
          atb: [
            { size: 48.09, price: 18 },
            { size: 1, price: 26 },
            { size: 9.89, price: 80 },
          ],
          expectedOdds: '19',
          horseName: 'Angelsworknovrtime',
          status: 'win',
        },
        {
          qs: 142.24896657004962,
          lp: 1.63,
          atl: [
            { size: 1.49, price: 1.61 },
            { size: 12.54, price: 1.6 },
            { size: 15, price: 1.53 },
          ],
          eventName: '18:05 Worcester',
          vp: 0.9573170731707318,
          atb: [
            { size: 11.74, price: 1.64 },
            { size: 25.6, price: 1.65 },
            { size: 58.16, price: 1.66 },
          ],
          expectedOdds: '1.57',
          horseName: 'Light N Strike',
          status: 'win',
        },
      ],
      name: 'simon#collinson',
      returns: 652.35,
      winnings: 652.35,
      id: '09e7ef9d-b71f-4bf8-b6ff-810e31aa442b',
      status: 'placed',
      updatedAt: '2022-08-17T09:09:23.003Z',
    },
    {
      betDate: '2022-08-17',
      betDuration: 30,
      displayName: 'Craig Ellis',
      stake: 30,
      type: 'patent',
      bookie: 'bwin',
      createdAt: '2022-08-17T09:09:23.291Z',
      selections: [
        {
          qs: 240.29641025641024,
          lp: 19.5,
          atl: [
            { size: 1.41, price: 17.5 },
            { size: 2.37, price: 17 },
            { size: 1.59, price: 16.5 },
          ],
          eventName: '20:10 Worcester',
          vp: 0.9743589743589743,
          atb: [
            { size: 3.23, price: 19.5 },
            { size: 10, price: 20 },
            { size: 8.78, price: 22 },
          ],
          expectedOdds: '19',
          horseName: 'Go Fox',
          status: 'win',
        },
        {
          qs: 69.67167721518987,
          lp: 3.95,
          atl: [
            { size: 5, price: 3.9 },
            { size: 15.14, price: 3.85 },
            { size: 7.51, price: 3.8 },
          ],
          eventName: '14:50 Wolverhampton',
          vp: 0.9493670886075949,
          atb: [
            { size: 19.57, price: 3.95 },
            { size: 6.19, price: 4.1 },
            { size: 8.48, price: 4.2 },
          ],
          expectedOdds: '3.75',
          horseName: 'Torvi',
          status: 'win',
        },
        {
          qs: 48.36474358974358,
          lp: 20,
          atl: [
            { size: 2, price: 17 },
            { size: 1.83, price: 15 },
            { size: 1.52, price: 14 },
          ],
          eventName: '20:10 Worcester',
          vp: 0.9743589743589743,
          atb: [
            { size: 1.15, price: 19.5 },
            { size: 1.5, price: 20 },
            { size: 22.39, price: 21 },
          ],
          expectedOdds: '19',
          horseName: 'Merchant House',
          status: 'win',
        },
      ],
      name: 'craig#ellis',
      returns: 5697,
      winnings: 5697,
      id: '0a5f6d6f-cf73-4180-8a2c-60b5ad26b832',
      status: 'placed',
      updatedAt: '2022-08-17T09:09:23.291Z',
    },
    {
      betDate: '2022-08-17',
      betDuration: 30,
      displayName: 'Marcel Bechara',
      type: 'patent',
      bookie: 'bwin',
      stake: 3,
      createdAt: '2022-08-17T09:09:23.018Z',
      selections: [
        {
          qs: 240.29641025641024,
          lp: 19.5,
          atl: [
            { size: 1.41, price: 17.5 },
            { size: 2.37, price: 17 },
            { size: 1.59, price: 16.5 },
          ],
          eventName: '20:10 Worcester',
          vp: 0.9743589743589743,
          atb: [
            { size: 3.23, price: 19.5 },
            { size: 10, price: 20 },
            { size: 8.78, price: 22 },
          ],
          expectedOdds: '19',
          horseName: 'Go Fox',
          status: 'win',
        },
        {
          qs: 93.7741935483871,
          lp: 15.5,
          atl: [
            { size: 3.13, price: 15 },
            { size: 1.69, price: 14 },
            { size: 5.58, price: 13.5 },
          ],
          eventName: '19:40 Worcester',
          vp: 0.967741935483871,
          atb: [
            { size: 6.46, price: 15.5 },
            { size: 3.7, price: 17.5 },
            { size: 10.84, price: 22 },
          ],
          expectedOdds: '15',
          horseName: 'Corrany',
          status: 'win',
        },
        {
          qs: 48.36474358974358,
          lp: 20,
          atl: [
            { size: 2, price: 17 },
            { size: 1.83, price: 15 },
            { size: 1.52, price: 14 },
          ],
          eventName: '20:10 Worcester',
          vp: 0.9743589743589743,
          atb: [
            { size: 1.15, price: 19.5 },
            { size: 1.5, price: 20 },
            { size: 22.39, price: 21 },
          ],
          expectedOdds: '19',
          horseName: 'Merchant House',
          status: 'win',
        },
      ],
      name: 'marcel#bechara',
      returns: 19197,
      winnings: 19197,
      id: '0b2385cf-b4e5-4c32-a6a8-8f27389963b4',
      status: 'placed',
      updatedAt: '2022-08-17T09:09:23.018Z',
    },
    {
      betDate: '2022-08-17',
      betDuration: 30,
      displayName: 'Andy Newham',
      type: 'patent',
      bookie: 'bwin',
      stake: 1,
      createdAt: '2022-08-17T09:09:23.279Z',
      selections: [
        {
          qs: 240.29641025641024,
          lp: 19.5,
          atl: [
            { size: 1.41, price: 17.5 },
            { size: 2.37, price: 17 },
            { size: 1.59, price: 16.5 },
          ],
          eventName: '20:10 Worcester',
          vp: 0.9743589743589743,
          atb: [
            { size: 3.23, price: 19.5 },
            { size: 10, price: 20 },
            { size: 8.78, price: 22 },
          ],
          expectedOdds: '19',
          horseName: 'Go Fox',
          status: 'win',
        },
        {
          qs: 93.7741935483871,
          lp: 15.5,
          atl: [
            { size: 3.13, price: 15 },
            { size: 1.69, price: 14 },
            { size: 5.58, price: 13.5 },
          ],
          eventName: '19:40 Worcester',
          vp: 0.967741935483871,
          atb: [
            { size: 6.46, price: 15.5 },
            { size: 3.7, price: 17.5 },
            { size: 10.84, price: 22 },
          ],
          expectedOdds: '15',
          horseName: 'Corrany',
          status: 'win',
        },
        {
          qs: 57.26415094339623,
          lp: 5.1,
          atl: [
            { size: 1.46, price: 5.1 },
            { size: 8.65, price: 5 },
            { size: 1.31, price: 4.7 },
          ],
          eventName: '20:10 Worcester',
          vp: 0.9433962264150944,
          atb: [
            { size: 12.14, price: 5.3 },
            { size: 19.55, price: 5.4 },
            { size: 3, price: 5.7 },
          ],
          expectedOdds: '5',
          horseName: 'Envol De La Cour',
          status: 'win',
        },
      ],
      name: 'andy#newham',
      returns: 1919,
      winnings: 1919,
      id: '0bd351db-0fa8-4db4-81ef-675a64eefcb9',
      status: 'placed',
      updatedAt: '2022-08-17T09:09:23.279Z',
    },
    {
      betDate: '2022-08-17',
      betDuration: 30,
      displayName: 'Craig Ellis',
      type: 'patent',
      bookie: 'bwin',
      stake: 3,
      createdAt: '2022-08-17T09:09:23.267Z',
      selections: [
        {
          qs: 9236.118888888888,
          lp: 36,
          atl: [
            { size: 110.84, price: 34 },
            { size: 5, price: 32 },
            { size: 16.62, price: 30 },
          ],
          eventName: '15:00 York',
          vp: 0.9444444444444444,
          atb: [
            { size: 287.63, price: 36 },
            { size: 19.26, price: 38 },
            { size: 71.58, price: 40 },
          ],
          expectedOdds: '34',
          horseName: 'Grand Alliance',
          status: 'win',
        },
        {
          qs: 1173.7949999999998,
          lp: 18,
          atl: [
            { size: 2.33, price: 17.5 },
            { size: 7.27, price: 17 },
            { size: 5.54, price: 14.5 },
          ],
          eventName: '17:25 Sligo',
          vp: 1.1666666666666667,
          atb: [
            { size: 47.91, price: 18 },
            { size: 1, price: 26 },
            { size: 9.89, price: 80 },
          ],
          expectedOdds: '21',
          horseName: 'Angelsworknovrtime',
          status: 'win',
        },
        {
          qs: 93.7741935483871,
          lp: 15.5,
          atl: [
            { size: 3.13, price: 15 },
            { size: 1.69, price: 14 },
            { size: 5.58, price: 13.5 },
          ],
          eventName: '19:40 Worcester',
          vp: 0.967741935483871,
          atb: [
            { size: 6.46, price: 15.5 },
            { size: 3.7, price: 17.5 },
            { size: 10.84, price: 22 },
          ],
          expectedOdds: '15',
          horseName: 'Corrany',
          status: 'win',
        },
      ],
      name: 'craig#ellis',
      returns: 36957,
      winnings: 36957,
      id: '0bfe586c-b03d-429c-bef8-07c22a4d99db',
      status: 'placed',
      updatedAt: '2022-08-17T09:09:23.267Z',
    },
    {
      betDate: '2022-08-17',
      betDuration: 30,
      displayName: 'Andy Newham',
      type: 'patent',
      bookie: 'bwin',
      stake: 1,
      createdAt: '2022-08-17T09:09:23.253Z',
      selections: [
        {
          qs: 9236.118888888888,
          lp: 36,
          atl: [
            { size: 110.84, price: 34 },
            { size: 5, price: 32 },
            { size: 16.62, price: 30 },
          ],
          eventName: '15:00 York',
          vp: 0.9444444444444444,
          atb: [
            { size: 287.63, price: 36 },
            { size: 19.26, price: 38 },
            { size: 71.58, price: 40 },
          ],
          expectedOdds: '34',
          horseName: 'Grand Alliance',
          status: 'win',
        },
        {
          qs: 1173.7949999999998,
          lp: 18,
          atl: [
            { size: 2.33, price: 17.5 },
            { size: 7.27, price: 17 },
            { size: 5.54, price: 14.5 },
          ],
          eventName: '17:25 Sligo',
          vp: 1.1666666666666667,
          atb: [
            { size: 47.91, price: 18 },
            { size: 1, price: 26 },
            { size: 9.89, price: 80 },
          ],
          expectedOdds: '21',
          horseName: 'Angelsworknovrtime',
          status: 'win',
        },
        {
          qs: 93.7741935483871,
          lp: 15.5,
          atl: [
            { size: 3.13, price: 15 },
            { size: 1.69, price: 14 },
            { size: 5.58, price: 13.5 },
          ],
          eventName: '19:40 Worcester',
          vp: 0.967741935483871,
          atb: [
            { size: 6.46, price: 15.5 },
            { size: 3.7, price: 17.5 },
            { size: 10.84, price: 22 },
          ],
          expectedOdds: '15',
          horseName: 'Corrany',
          status: 'win',
        },
      ],
      name: 'andy#newham',
      returns: 12319,
      winnings: 12319,
      id: '0c8a7710-805e-44b3-82a2-3c75ee4b7bf0',
      status: 'placed',
      updatedAt: '2022-08-17T09:09:23.253Z',
    },
    {
      betDate: '2022-08-17',
      betDuration: 30,
      displayName: 'Martin Pykett',
      type: 'patent',
      bookie: 'bwin',
      stake: 3,
      createdAt: '2022-08-17T09:09:23.240Z',
      selections: [
        {
          qs: 9370.343333333332,
          lp: 34,
          atl: [
            { size: 109.3, price: 34 },
            { size: 5, price: 32 },
            { size: 16.62, price: 30 },
          ],
          eventName: '15:00 York',
          vp: 0.9444444444444444,
          atb: [
            { size: 291.81, price: 36 },
            { size: 19.26, price: 38 },
            { size: 71.58, price: 40 },
          ],
          expectedOdds: '34',
          horseName: 'Grand Alliance',
          status: 'win',
        },
        {
          qs: 240.29641025641024,
          lp: 19.5,
          atl: [
            { size: 1.41, price: 17.5 },
            { size: 2.37, price: 17 },
            { size: 1.59, price: 16.5 },
          ],
          eventName: '20:10 Worcester',
          vp: 0.9743589743589743,
          atb: [
            { size: 3.23, price: 19.5 },
            { size: 10, price: 20 },
            { size: 8.78, price: 22 },
          ],
          expectedOdds: '19',
          horseName: 'Go Fox',
          status: 'win',
        },
        {
          qs: 57.26415094339623,
          lp: 5.1,
          atl: [
            { size: 1.46, price: 5.1 },
            { size: 8.65, price: 5 },
            { size: 1.31, price: 4.7 },
          ],
          eventName: '20:10 Worcester',
          vp: 0.9433962264150944,
          atb: [
            { size: 12.14, price: 5.3 },
            { size: 19.55, price: 5.4 },
            { size: 3, price: 5.7 },
          ],
          expectedOdds: '5',
          horseName: 'Envol De La Cour',
          status: 'win',
        },
      ],
      name: 'martin#pykett',
      returns: 12597,
      winnings: 12597,
      id: '0d11fc84-ef85-480c-b1b6-7294b8bfa0ae',
      status: 'placed',
      updatedAt: '2022-08-17T09:09:23.240Z',
    },
    {
      betDate: '2022-08-17',
      betDuration: 30,
      displayName: 'Alex Brown',
      type: 'lucky15',
      bookie: 'bwin',
      stake: 25,
      createdAt: '2022-08-17T09:09:23.144Z',
      selections: [
        {
          qs: 12564.825138888888,
          lp: 32,
          atl: [
            { size: 18.62, price: 30 },
            { size: 6.54, price: 29 },
            { size: 10, price: 28 },
          ],
          eventName: '15:00 York',
          vp: 1.0625,
          atb: [
            { size: 3.25, price: 32 },
            { size: 94.45, price: 34 },
            { size: 287.63, price: 36 },
          ],
          expectedOdds: '34',
          horseName: 'Grand Alliance',
          status: 'win',
        },
        {
          qs: 1320.4627777777778,
          lp: 90,
          atl: [
            { size: 10.12, price: 55 },
            { size: 1.23, price: 48 },
            { size: 1.97, price: 40 },
          ],
          eventName: '18:15 Kempton',
          vp: 1.1222222222222222,
          atb: [
            { size: 2.65, price: 90 },
            { size: 10, price: 100 },
            { size: 7.71, price: 110 },
          ],
          expectedOdds: '101',
          horseName: 'Rebel Angel',
          status: 'win',
        },
        {
          qs: 1309.0605270703368,
          lp: 3.55,
          atl: [
            { size: 810.03, price: 3.5 },
            { size: 301.31, price: 3.45 },
            { size: 128.2, price: 3.4 },
          ],
          eventName: '15:00 York',
          vp: 0.9859154929577465,
          atb: [
            { size: 22.92, price: 3.55 },
            { size: 265.75, price: 3.6 },
            { size: 97.04, price: 3.65 },
          ],
          expectedOdds: '3.5',
          horseName: 'Secret State',
          status: 'win',
        },
        {
          qs: 202.2764292380325,
          lp: 8.8,
          atl: [
            { size: 5.72, price: 8.6 },
            { size: 3, price: 7.4 },
            { size: 2.55, price: 7 },
          ],
          eventName: '20:00 Sligo',
          vp: 1.0795454545454544,
          atb: [
            { size: 5.37, price: 8.8 },
            { size: 8.81, price: 9 },
            { size: 6, price: 9.2 },
          ],
          expectedOdds: '9.5',
          horseName: 'Memorable Daise',
          status: 'win',
        },
      ],
      name: 'alex#brown',
      returns: 4217037.5,
      winnings: 4217037.5,
      id: '0d8c20e8-a375-4b88-a905-f568aa793e46',
      status: 'placed',
      updatedAt: '2022-08-17T09:09:23.144Z',
    },
    {
      betDate: '2022-08-17',
      betDuration: 30,
      displayName: 'Simon Collinson',
      type: 'patent',
      bookie: 'bwin',
      stake: 3,
      createdAt: '2022-08-17T09:09:23.129Z',
      selections: [
        {
          qs: 2729.938906649616,
          lp: 3.4,
          atl: [
            { size: 81.01, price: 3.35 },
            { size: 601.96, price: 3.3 },
            { size: 254.19, price: 3.25 },
          ],
          eventName: '14:25 York',
          vp: 0.9558823529411765,
          atb: [
            { size: 599.92, price: 3.4 },
            { size: 282.93, price: 3.45 },
            { size: 352.85, price: 3.5 },
          ],
          expectedOdds: '3.25',
          horseName: 'Local Dynasty',
          status: 'win',
        },
        {
          qs: 964.4716666666668,
          lp: 17,
          atl: [
            { size: 1.64, price: 15 },
            { size: 14.16, price: 14.5 },
            { size: 2.55, price: 10.5 },
          ],
          eventName: '17:25 Sligo',
          vp: 1.0555555555555556,
          atb: [
            { size: 48.09, price: 18 },
            { size: 1, price: 26 },
            { size: 9.89, price: 80 },
          ],
          expectedOdds: '19',
          horseName: 'Angelsworknovrtime',
          status: 'win',
        },
        {
          qs: 142.24896657004962,
          lp: 1.63,
          atl: [
            { size: 1.49, price: 1.61 },
            { size: 12.54, price: 1.6 },
            { size: 15, price: 1.53 },
          ],
          eventName: '18:05 Worcester',
          vp: 0.9573170731707318,
          atb: [
            { size: 11.74, price: 1.64 },
            { size: 25.6, price: 1.65 },
            { size: 58.16, price: 1.66 },
          ],
          expectedOdds: '1.57',
          horseName: 'Light N Strike',
          status: 'win',
        },
      ],
      name: 'simon#collinson',
      returns: 652.35,
      winnings: 652.35,
      id: '0f98775e-98d9-4184-9150-dc667ebbe468',
      status: 'placed',
      updatedAt: '2022-08-17T09:09:23.129Z',
    },
    {
      betDate: '2022-08-17',
      betDuration: 30,
      displayName: 'William Archer',
      type: 'patent',
      bookie: 'bwin',
      stake: 2,
      createdAt: '2022-08-17T09:09:23.109Z',
      selections: [
        {
          qs: 9236.118888888888,
          lp: 36,
          atl: [
            { size: 110.84, price: 34 },
            { size: 5, price: 32 },
            { size: 16.62, price: 30 },
          ],
          eventName: '15:00 York',
          vp: 0.9444444444444444,
          atb: [
            { size: 287.63, price: 36 },
            { size: 19.26, price: 38 },
            { size: 71.58, price: 40 },
          ],
          expectedOdds: '34',
          horseName: 'Grand Alliance',
          status: 'win',
        },
        {
          qs: 240.29641025641024,
          lp: 19.5,
          atl: [
            { size: 1.41, price: 17.5 },
            { size: 2.37, price: 17 },
            { size: 1.59, price: 16.5 },
          ],
          eventName: '20:10 Worcester',
          vp: 0.9743589743589743,
          atb: [
            { size: 3.23, price: 19.5 },
            { size: 10, price: 20 },
            { size: 8.78, price: 22 },
          ],
          expectedOdds: '19',
          horseName: 'Go Fox',
          status: 'win',
        },
        {
          qs: 113.92405063291139,
          lp: 3.95,
          atl: [
            { size: 5, price: 3.9 },
            { size: 15.14, price: 3.85 },
            { size: 7.51, price: 3.8 },
          ],
          eventName: '14:50 Wolverhampton',
          vp: 0.9493670886075949,
          atb: [
            { size: 32, price: 3.95 },
            { size: 6.19, price: 4.1 },
            { size: 8.48, price: 4.2 },
          ],
          expectedOdds: '3.75',
          horseName: 'Torvi',
          status: 'win',
        },
      ],
      name: 'william#archer',
      returns: 6648,
      winnings: 6648,
      id: '0fe66b40-5112-4737-88e6-d8789bab1c9f',
      status: 'placed',
      updatedAt: '2022-08-17T09:09:23.109Z',
    },
    {
      betDate: '2022-08-17',
      betDuration: 30,
      displayName: 'Martin Pykett',
      type: 'patent',
      bookie: 'bwin',
      stake: 3,
      createdAt: '2022-08-17T09:09:23.227Z',
      selections: [
        {
          qs: 9236.118888888888,
          lp: 36,
          atl: [
            { size: 110.84, price: 34 },
            { size: 5, price: 32 },
            { size: 16.62, price: 30 },
          ],
          eventName: '15:00 York',
          vp: 0.9444444444444444,
          atb: [
            { size: 287.63, price: 36 },
            { size: 19.26, price: 38 },
            { size: 71.58, price: 40 },
          ],
          expectedOdds: '34',
          horseName: 'Grand Alliance',
          status: 'win',
        },
        {
          qs: 1173.7949999999998,
          lp: 18,
          atl: [
            { size: 2.33, price: 17.5 },
            { size: 7.27, price: 17 },
            { size: 5.54, price: 14.5 },
          ],
          eventName: '17:25 Sligo',
          vp: 1.1666666666666667,
          atb: [
            { size: 47.91, price: 18 },
            { size: 1, price: 26 },
            { size: 9.89, price: 80 },
          ],
          expectedOdds: '21',
          horseName: 'Angelsworknovrtime',
          status: 'win',
        },
        {
          qs: 93.7741935483871,
          lp: 15.5,
          atl: [
            { size: 3.13, price: 15 },
            { size: 1.69, price: 14 },
            { size: 5.58, price: 13.5 },
          ],
          eventName: '19:40 Worcester',
          vp: 0.967741935483871,
          atb: [
            { size: 6.46, price: 15.5 },
            { size: 3.7, price: 17.5 },
            { size: 10.84, price: 22 },
          ],
          expectedOdds: '15',
          horseName: 'Corrany',
          status: 'win',
        },
      ],
      name: 'martin#pykett',
      returns: 36957,
      winnings: 36957,
      id: '1047bc36-5a49-45c9-b0f6-313617394a6b',
      status: 'placed',
      updatedAt: '2022-08-17T09:09:23.227Z',
    },
  ],
};

export default data;
