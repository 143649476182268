import React, { useState } from 'react';
import subDays from 'date-fns/subDays';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import moment from 'moment';
import { Auth } from 'aws-amplify';
import axiosRetry from 'axios-retry';
import {
  ButtonGroup,
  Button,
  Row,
  Col,
  Spinner,
  Card,
  CardBody,
} from 'reactstrap';
import { CSVLink } from 'react-csv';
import 'react-datepicker/dist/react-datepicker.css';
import { bookieListGenerator } from 'components/dropdownGenerator';
import { syndicateListGenerator } from 'components/dropdownGenerator';
const syndArr = syndicateListGenerator();
const bookieArr = bookieListGenerator('analytics');

import Select from 'react-select';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

axiosRetry(axios, { retries: 10 });

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ComparisonReport = () => {
  const [startDate, setStartDate] = useState(new Date(subDays(new Date(), 2)));
  const [endDate, setEndDate] = useState(new Date(subDays(new Date(), 1)));
  const [selectBookie, setSelectBookie] = useState({ value: '', label: '' });
  const [selectSyndicate, setSelectSyndicate] = useState([]);
  const [syndReportData, setSyndReportData] = useState([]);

  const formattedStart = moment(startDate).format('YYYY-MM-DD');
  const formattedEnd = moment(endDate).format('YYYY-MM-DD');

  const [loading, setLoading] = useState(false);

  const bookieSelectOptions = () => {
    const selectOptions = bookieArr.sort().map((bookie) => {
      return { value: bookie, label: bookie };
    });
    selectOptions.unshift({ value: '', label: '< Unselect Bookie >' });
    return selectOptions;
  };

  const syndicateSelectOptions = () => {
    const selectOptions = syndArr.sort().map((syndicate) => {
      return { value: syndicate, label: syndicate };
    });
    return selectOptions;
  };

  const loadingSpinner = loading ? (
    <>
      {' '}
      <Spinner size="sm"></Spinner>
    </>
  ) : null;

  const addAll = () =>
    setSelectSyndicate(() => {
      const list = syndArr.map((s) => {
        return { value: s, label: s };
      });
      return list;
    });

  const syndCols =
    syndReportData.length > 0
      ? syndReportData.map((report) => {
          return {
            label: report[0].name,
            key: report[0].name,
          };
        })
      : [];

  const syndicateHeaders =
    syndReportData.length > 0
      ? [{ label: 'Date', key: 'reportDate' }].concat(syndCols)
      : [];

  const addAllSyndicatesButton = () => {
    return (
      <Button
        onClick={() => {
          addAll();
        }}
      >
        Retrieve All Syndicates
      </Button>
    );
  };

  const syndReportSubmit =
    selectSyndicate.length > 0 ? (
      <>
        <Button
          color="default"
          onClick={async () => {
            setLoading(true);
            const user = await Auth.currentSession();
            const token = user.getIdToken().getJwtToken();

            const res =
              selectBookie.value !== ''
                ? await Promise.all(
                    selectSyndicate.map(async (item) => {
                      const response = await axios.get(
                        `https://api.valueservices.uk/reporting/getSyndStakeReports/${item.value.toLowerCase()} ${selectBookie.value.toLowerCase()}/${formattedStart}/${formattedEnd}`,
                        { headers: { Authorization: token } }
                      );

                      return response.data;
                    })
                  )
                : await Promise.all(
                    selectSyndicate.map(async (item) => {
                      const response = await axios.get(
                        `https://api.valueservices.uk/reporting/getSyndStakeReports/${item.value.toLowerCase()}/${formattedStart}/${formattedEnd}`,
                        { headers: { Authorization: token } }
                      );

                      return response.data;
                    })
                  );

            setSyndReportData(res);
            setLoading(false);
          }}
        >
          Submit Request
          {loadingSpinner}
        </Button>
      </>
    ) : (
      <>
        <Button disabled color="default">
          Submit Request
        </Button>
      </>
    );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
      },
    },
  };

  const labels =
    syndReportData.length > 0 ? syndReportData[0].map((i) => i.reportDate) : [];

  const syndDataSets =
    syndReportData.length > 0
      ? syndReportData.map((rep) => {
          const stakeData = rep.map((r) => r.totalStake);

          const randomBetween = (min, max) =>
            min + Math.floor(Math.random() * (max - min + 1));
          const r = randomBetween(0, 255);
          const g = randomBetween(0, 255);
          const b = randomBetween(0, 255);

          return {
            label: rep[0].name,
            data: stakeData,
            borderColor: `rgba(${r}, ${g}, ${b}, 0.5)`,
            backgroundColor: `rgba(${r}, ${g}, ${b}, 0.5)`,
          };
        })
      : [];

  const data = {
    labels,
    datasets: syndDataSets,
  };
  const tableHeaders = () => {
    if (syndReportData.length > 0) {
      const dateRange = syndReportData[0].map((r) => {
        return r.reportDate;
      });

      dateRange.unshift('Synd');

      return dateRange;
    }
  };

  tableHeaders();
  const tableRows = () => {
    if (syndReportData.length > 0) {
      const data = syndReportData.map((report) => {
        const cells = report.map((r) => parseFloat(r.totalStake).toFixed(2));
        cells.unshift(report[0].name);
        return cells;
      });

      return data;
    }
  };

  const syndReportLink =
    syndReportData.length > 0 ? (
      <>
        <br />
        <Button>
          <CSVLink
            filename={`Stake_Comparison_Report_${moment(startDate).format(
              'YYYY-MM-DD'
            )}_${moment(endDate).format('YYYY-MM-DD')}${
              selectBookie.value !== '' ? `_${selectBookie.value}` : ''
            }`}
            data={tableRows()}
            headers={tableHeaders()}
          >
            {`Download Report`}
          </CSVLink>
        </Button>
      </>
    ) : (
      <>
        <br />
        <Button disabled color="default">
          Download Report
        </Button>
      </>
    );

  const dateRangePicker = (
    <>
      <div>Start Date:</div>
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        showYearDropdown
        scrollableYearDropdown
        dateFormat="dd/MM/yyyy"
      />

      <div>End Date:</div>
      <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        maxDate={endDate}
        showYearDropdown
        scrollableYearDropdown
        dateFormat="dd/MM/yyyy"
      />
    </>
  );

  return (
    <>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  {dateRangePicker}
                  {addAllSyndicatesButton()}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    onChange={(value) => {
                      setSelectSyndicate(value);
                    }}
                    isMulti
                    value={selectSyndicate}
                    aria-label="Default select example"
                    placeholder="Pick Synds to Compare... "
                    options={syndicateSelectOptions()}
                  />
                  <ButtonGroup>
                    {syndReportSubmit} {syndReportLink}
                  </ButtonGroup>
                </Col>
                <Col>
                  <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    onChange={(value) => {
                      setSelectBookie(value);
                    }}
                    aria-label="Default select example"
                    placeholder="Choose bookie to compare against"
                    options={bookieSelectOptions()}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Line options={options} data={data} />
    </>
  );
};

export default ComparisonReport;
