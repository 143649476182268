import React, { useState } from 'react';
import subDays from 'date-fns/subDays';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import moment from 'moment';
import { Auth } from 'aws-amplify';
import axiosRetry from 'axios-retry';
import {
  ButtonGroup,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Table,
  Spinner,
} from 'reactstrap';
import { CSVLink } from 'react-csv';
import 'react-datepicker/dist/react-datepicker.css';

import { bookieListGenerator } from 'components/dropdownGenerator';
import { syndicateListGenerator } from 'components/dropdownGenerator';
const syndArr = syndicateListGenerator();
const bookieArr = bookieListGenerator('analytics');

import Select from 'react-select';

axiosRetry(axios, { retries: 10 });

const BetReport = () => {
  const [startDate, setStartDate] = useState(new Date(subDays(new Date(), 2)));
  const [endDate, setEndDate] = useState(new Date(subDays(new Date(), 1)));
  const [selectBookie, setSelectBookie] = useState([]);
  const [selectSyndicate, setSelectSyndicate] = useState('');
  const formattedStart = moment(startDate).format('YYYY-MM-DD');
  const formattedEnd = moment(endDate).format('YYYY-MM-DD');
  const [multiTableData, setMultiTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sourceReportData, setSourceReportData] = useState([]);
  const syndReportData = sourceReportData.map((data) => {
    const profit = data.totalWinnings - data.totalStake;
    const sumProfit = data.allTimeWinnings - data.allTimeStake;
    return { ...data, profit: profit, allTimeProfit: sumProfit };
  });

  const bookieSelectOptions = () => {
    const selectOptions = bookieArr.sort().map((bookie) => {
      return { value: bookie, label: bookie };
    });
    return selectOptions;
  };

  const syndicateSelectOptions = () => {
    const selectOptions = syndArr.sort().map((syndicate) => {
      return { value: syndicate, label: syndicate };
    });
    return selectOptions;
  };

  const loadingSpinner = loading ? (
    <>
      {' '}
      <Spinner size="sm"></Spinner>
    </>
  ) : null;

  const tableHeaders = [
    { label: 'Date', key: 'reportDate' },
    { label: 'TotalBets', key: 'totalBets' },
    { label: 'TotalStake', key: 'totalStake' },
    { label: "Sum'Stake", key: 'allTimeStake' },
    { label: 'TotalWin', key: 'totalWinnings' },
    { label: "Sum'Win", key: 'allTimeWin' },
    { label: 'Profit', key: 'profit' },
    { label: "Sum'Profit", key: 'allTimeProfit' },
    { label: 'single/vol', key: 'singleVol' },
    { label: 'single/vol', key: 'singleVol' },
    { label: 'single/stk', key: 'singleStake' },
    { label: 'single/win', key: 'singleWin' },
    { label: 'dbl/vol', key: 'dblVol' },
    { label: 'dbl/stk', key: 'dblStake' },
    { label: 'dbl/win', key: 'dblWin' },
    { label: 'trix/vol', key: 'trixVol' },
    { label: 'trix/stk', key: 'trixStake' },
    { label: 'trix/win', key: 'trixWin' },
    { label: 'pat/vol', key: 'patVol' },
    { label: 'pat/stk', key: 'patStake' },
    { label: 'pat/win', key: 'patWin' },
    { label: 'l15/vol', key: 'l15Vol' },
    { label: 'l15/stk', key: 'l15Stake' },
    { label: 'l15/win', key: 'l15Win' },
    { label: 'l31/vol', key: 'l31Vol' },
    { label: 'l31/stk', key: 'l31Stake' },
    { label: 'l31/win', key: 'l31Win' },
  ];

  const syndBetReport = syndReportData.map((item) => {
    const betData = item.totalsByBetType;
    const single = betData[0];
    const dbl = betData[1];
    const trix = betData[2];
    const pat = betData[3];
    const l15 = betData[4];
    const l31 = betData[5];

    return {
      reportDate: item.reportDate,
      totalBets: item.totalBets,
      totalStake: item.totalStake,
      totalWinnings: item.totalWinnings.toFixed(2),

      allTimeWin: item.allTimeWinnings.toFixed(2),
      allTimeStake: item.allTimeStake.toFixed(2),

      profit: item.profit,
      allTimeProfit: item.allTimeProfit,

      singleVol: single.totalBets,
      singleStake: single.totalStake,
      singleWin: single.totalWinnings.toFixed(2),
      dblVol: dbl.totalBets,
      dblStake: dbl.totalStake,
      dblWin: dbl.totalWinnings.toFixed(2),
      trixVol: trix.totalBets,
      trixStake: trix.totalStake,
      trixWin: trix.totalWinnings.toFixed(2),
      patVol: pat.totalBets,
      patStake: pat.totalStake,
      patWin: pat.totalWinnings.toFixed(2),
      l15Vol: l15.totalBets,
      l15Stake: l15.totalStake,
      l15Win: l15.totalWinnings.toFixed(2),
      l31Vol: l31.totalBets,
      l31Stake: l31.totalStake,
      l31Win: l31.totalWinnings.toFixed(2),
    };
  });

  const syndReportSubmit = selectSyndicate.label ? (
    <>
      <Button
        color="default"
        onClick={async () => {
          setLoading(true);
          const user = await Auth.currentSession();
          const token = user.getIdToken().getJwtToken();

          let processedResponse;

          if (selectBookie.map((b) => b.label).length > 0) {
            const resArray = await Promise.all(
              selectBookie.map(async (item) => {
                const res = await axios.get(
                  `https://api.valueservices.uk/reporting/getSyndStakeReports/${selectSyndicate.value.toLowerCase()} ${item.value.toLowerCase()}/${formattedStart}/${formattedEnd}`,
                  { headers: { Authorization: token } }
                );
                return res.data;
              })
            );

            setMultiTableData(resArray);

            processedResponse = resArray
              .flatMap((item) => item)
              .map((item) => {
                return {
                  totalBets: item.totalBets,
                  reportDate: item.reportDate,
                  name: item.name,
                  totalProfit: item.totalWinnings - item.totalStake,
                  totalStake: item.totalStake,
                  totalWinnings: item.totalWinnings,
                  totalsByBetType: item.totalsByBetType,
                  allTimeProfit: 0,
                  allTimeStake: 0,
                  allTimeWinnings: 0,
                };
              });

            const dates = [
              ...new Set(processedResponse.map((item) => item.reportDate)),
            ];

            const arraySortByDate = dates.map((date) => {
              return processedResponse.filter(
                (item) => item.reportDate === date
              );
            });

            const mergedTotals = arraySortByDate.map((date) => {
              const totalWinnings = parseFloat(
                date.reduce((a, b) => a + b.totalWinnings, 0).toFixed(2)
              );
              const totalStake = parseFloat(
                date.reduce((a, b) => a + b.totalStake, 0).toFixed(2)
              );
              const totalProfit = totalWinnings - totalStake;

              return {
                reportDate: date[0].reportDate,
                totalBets: date.reduce((a, b) => a + b.totalBets, 0),
                allTimeProfit: 0,
                allTimeStake: 0,
                allTimeWinnings: 0,
                totalStake,
                totalWinnings,
                totalProfit,
                totalsByBetType: [
                  {
                    totalBets: date.reduce(
                      (a, b) => a + b.totalsByBetType[0].totalBets,
                      0
                    ),
                    betType: 'single',
                    totalStake: date.reduce(
                      (a, b) => a + b.totalsByBetType[0].totalStake,
                      0
                    ),
                    totalWinnings: date.reduce(
                      (a, b) => a + b.totalsByBetType[0].totalWinnings,
                      0
                    ),
                  },
                  {
                    totalBets: date.reduce(
                      (a, b) => a + b.totalsByBetType[1].totalBets,
                      0
                    ),
                    betType: 'double',
                    totalStake: date.reduce(
                      (a, b) => a + b.totalsByBetType[1].totalStake,
                      0
                    ),
                    totalWinnings: date.reduce(
                      (a, b) => a + b.totalsByBetType[1].totalWinnings,
                      0
                    ),
                  },
                  {
                    totalBets: date.reduce(
                      (a, b) => a + b.totalsByBetType[2].totalBets,
                      0
                    ),
                    betType: 'trixie',
                    totalStake: date.reduce(
                      (a, b) => a + b.totalsByBetType[2].totalStake,
                      0
                    ),
                    totalWinnings: date.reduce(
                      (a, b) => a + b.totalsByBetType[2].totalWinnings,
                      0
                    ),
                  },
                  {
                    totalBets: date.reduce(
                      (a, b) => a + b.totalsByBetType[3].totalBets,
                      0
                    ),
                    betType: 'patent',
                    totalStake: date.reduce(
                      (a, b) => a + b.totalsByBetType[3].totalStake,
                      0
                    ),
                    totalWinnings: date.reduce(
                      (a, b) => a + b.totalsByBetType[3].totalWinnings,
                      0
                    ),
                  },
                  {
                    totalBets: date.reduce(
                      (a, b) => a + b.totalsByBetType[4].totalBets,
                      0
                    ),
                    betType: 'lucky15',
                    totalStake: date.reduce(
                      (a, b) => a + b.totalsByBetType[4].totalStake,
                      0
                    ),
                    totalWinnings: date.reduce(
                      (a, b) => a + b.totalsByBetType[4].totalWinnings,
                      0
                    ),
                  },
                  {
                    totalBets: date.reduce(
                      (a, b) => a + b.totalsByBetType[5].totalBets,
                      0
                    ),
                    betType: 'lucky31',
                    totalStake: date.reduce(
                      (a, b) => a + b.totalsByBetType[5].totalStake,
                      0
                    ),
                    totalWinnings: date.reduce(
                      (a, b) => a + b.totalsByBetType[5].totalWinnings,
                      0
                    ),
                  },
                ],
              };
            });

            mergedTotals[0].allTimeWinnings = mergedTotals[0].totalWinnings;
            mergedTotals[0].allTimeStake = mergedTotals[0].totalStake;

            for (let i = 1; i < mergedTotals.length; i += 1) {
              mergedTotals[i].allTimeWinnings =
                mergedTotals[i - 1].allTimeWinnings +
                mergedTotals[i].totalWinnings;
              mergedTotals[i].allTimeStake =
                mergedTotals[i - 1].allTimeStake + mergedTotals[i].totalStake;
            }
            processedResponse = mergedTotals;
          }

          if (selectBookie.map((b) => b.label).length === 0) {
            const res = await axios.get(
              `https://api.valueservices.uk/reporting/getSyndStakeReports/${selectSyndicate.value
                .toLowerCase()
                .replace(' ', '%20')}/${formattedStart}/${formattedEnd}`,
              { headers: { Authorization: token } }
            );

            processedResponse = res.data;
          }

          setSourceReportData(processedResponse);
          setLoading(false);
        }}
      >
        Submit Request{loadingSpinner}
      </Button>
    </>
  ) : (
    <Button disabled color="default">
      Submit Request
    </Button>
  );

  let tableBetTypes = [];
  if (syndReportData.length > 0) {
    const allBets = syndReportData.map((daysData) => {
      const betTypes = daysData.totalsByBetType.filter((item) => {
        return item.totalBets > 0;
      });
      return betTypes;
    });
    const flatAllBets = allBets.flatMap((item) => item);
    const reducedTableBetTypes = flatAllBets.map((item) => {
      return item.betType;
    });
    tableBetTypes = [...new Set(reducedTableBetTypes)];
  }

  const dynamicHeaders = tableBetTypes.map((item) => {
    return <th colSpan={3}>{`${item}`}</th>;
  });

  const tableTitle = (
    <>
      <h2>{`${selectSyndicate.label} - ${
        selectBookie.length > 1
          ? 'Totals Across All Accounts'
          : selectBookie.length === 1
          ? selectBookie[0].label
          : ''
      }`}</h2>
      <h3>
        Totals for period / Bets:{' '}
        {syndReportData.length > 0
          ? syndReportData.reduce((a, b) => a + b.totalBets, 0)
          : ''}
        {''} / Stake:{' £'}
        {syndReportData.length > 0
          ? syndReportData[syndReportData.length - 1].allTimeStake.toFixed(2)
          : ''}{' '}
        / Winnings:{' £'}
        {syndReportData.length > 0
          ? syndReportData[syndReportData.length - 1].allTimeWinnings.toFixed(2)
          : ''}{' '}
        / Profit:{' £'}
        {syndReportData.length > 0
          ? syndReportData[syndReportData.length - 1].allTimeProfit.toFixed(2)
          : ''}{' '}
      </h3>
    </>
  );

  const multiBookieTable =
    selectBookie.length > 1 ? (
      multiTableData.map((item) => {
        const itemData = item.map((data) => {
          const profit = data.totalWinnings - data.totalStake;
          const sumProfit = data.allTimeWinnings - data.allTimeStake;
          return { ...data, profit: profit, allTimeProfit: sumProfit };
        });

        return (
          <Card>
            <hr />
            <CardHeader>
              <h2>{item[0].name.replaceAll('#', ' ')}</h2>
              <>
                <h3>
                  Totals for period / Bets:{' '}
                  {itemData.length > 0
                    ? itemData.reduce((a, b) => a + b.totalBets, 0)
                    : ''}
                  {''} / Stake:{' £'}
                  {itemData.length > 0
                    ? itemData[itemData.length - 1].allTimeStake.toFixed(2)
                    : ''}{' '}
                  / Winnings:{' £'}
                  {itemData.length > 0
                    ? itemData[itemData.length - 1].allTimeWinnings.toFixed(2)
                    : ''}{' '}
                  / Profit:{' £'}
                  {itemData.length > 0
                    ? itemData[itemData.length - 1].allTimeProfit.toFixed(2)
                    : ''}{' '}
                </h3>
              </>
            </CardHeader>

            <CardBody>
              <Table className="tablesorter" responsive>
                <thead className="text-primary">
                  <tr>
                    <th rowSpan={2} width={100}>
                      Date
                    </th>
                    <th rowSpan={2} width={50}>
                      Total Bets
                    </th>
                    <th rowSpan={2} width={100}>
                      Total Stake
                    </th>
                    <th rowSpan={2} width={100}>
                      Sum'Stake
                    </th>
                    <th rowSpan={2} width={100}>
                      Total Win
                    </th>
                    <th rowSpan={2} width={100}>
                      Sum'Win
                    </th>
                    <th rowSpan={2} width={100}>
                      Profit
                    </th>{' '}
                    <th rowSpan={2} width={100}>
                      Sum'Profit
                    </th>
                    {/* {syndReportData[0].totalsByBetType.map((item) => (
                  <th colSpan={3}>{`${item.betType}`}</th>
                ))} */}
                    {dynamicHeaders}
                  </tr>
                  <tr>
                    {tableBetTypes.map(() => (
                      <>
                        <th>Vol</th>
                        <th>Stake</th>
                        <th>Win</th>
                      </>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {itemData.map((item) => (
                    <tr>
                      <td>{item.reportDate}</td>
                      <td>{item.totalBets}</td>
                      <td>{item.totalStake.toFixed(2)}</td>
                      <td>{item.allTimeStake.toFixed(2)}</td>
                      <td>{item.totalWinnings.toFixed(2)}</td>
                      <td>{item.allTimeWinnings.toFixed(2)}</td>
                      <td>{item.profit.toFixed(2)}</td>
                      <td>{item.allTimeProfit.toFixed(2)}</td>
                      {tableBetTypes.map((type) => {
                        return item.totalsByBetType.map((bet) => {
                          if (bet.betType === type) {
                            return (
                              <>
                                <td width={40}>{`(${bet.totalBets})`}</td>
                                <td width={60}>{bet.totalStake.toFixed(2)}</td>
                                <td>{bet.totalWinnings.toFixed(2)}</td>
                              </>
                            );
                          }
                        });
                      })}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        );
      })
    ) : (
      <>{''}</>
    );

  const reportTable =
    syndReportData.length === 0 ? (
      <Card>
        <CardBody></CardBody>
      </Card>
    ) : (
      <Card>
        <hr />
        <CardHeader>{tableTitle}</CardHeader>

        <hr />
        <CardBody>
          <Table className="tablesorter" responsive>
            <thead className="text-primary">
              <tr>
                <th rowSpan={2} width={100}>
                  Date
                </th>
                <th rowSpan={2} width={50}>
                  Total Bets
                </th>
                <th rowSpan={2} width={100}>
                  Total Stake
                </th>
                <th rowSpan={2} width={100}>
                  Sum'Stake
                </th>
                <th rowSpan={2} width={100}>
                  Total Win
                </th>
                <th rowSpan={2} width={100}>
                  Sum'Win
                </th>
                <th rowSpan={2} width={100}>
                  Profit
                </th>{' '}
                <th rowSpan={2} width={100}>
                  Sum'Profit
                </th>
                {/* {syndReportData[0].totalsByBetType.map((item) => (
                  <th colSpan={3}>{`${item.betType}`}</th>
                ))} */}
                {dynamicHeaders}
              </tr>
              <tr>
                {tableBetTypes.map(() => (
                  <>
                    <th>Vol</th>
                    <th>Stake</th>
                    <th>Win</th>
                  </>
                ))}
              </tr>
            </thead>
            <tbody>
              {syndReportData.map((item) => (
                <tr>
                  <td>{item.reportDate}</td>
                  <td>{item.totalBets}</td>
                  <td>{item.totalStake.toFixed(2)}</td>
                  <td>{item.allTimeStake.toFixed(2)}</td>
                  <td>{item.totalWinnings.toFixed(2)}</td>
                  <td>{item.allTimeWinnings.toFixed(2)}</td>
                  <td>{item.profit.toFixed(2)}</td>
                  <td>{item.allTimeProfit.toFixed(2)}</td>
                  {tableBetTypes.map((type) => {
                    return item.totalsByBetType.map((bet) => {
                      if (bet.betType === type) {
                        return (
                          <>
                            <td width={40}>{`(${bet.totalBets})`}</td>
                            <td width={60}>{bet.totalStake.toFixed(2)}</td>
                            <td>{bet.totalWinnings.toFixed(2)}</td>
                          </>
                        );
                      }
                    });
                  })}
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );

  const syndReportLink =
    syndReportData.length > 0 ? (
      <>
        <br />
        <Button>
          <CSVLink
            filename={`${selectSyndicate.label.replace(' ', '')}_${
              selectBookie.label
            }_${moment(startDate).format('YYYY-MM-DD')}_${moment(
              endDate
            ).format('YYYY-MM-DD')}`}
            data={syndBetReport}
            headers={tableHeaders}
          >
            {`Download Report`}
          </CSVLink>
        </Button>
      </>
    ) : (
      <>
        <br />
        <Button disabled color="default">
          Download Report
        </Button>
      </>
    );

  const dateRangePicker = (
    <>
      <div>Start Date:</div>
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        showYearDropdown
        scrollableYearDropdown
        dateFormat="dd/MM/yyyy"
      />

      <div>End Date:</div>
      <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        maxDate={endDate}
        showYearDropdown
        scrollableYearDropdown
        dateFormat="dd/MM/yyyy"
      />
    </>
  );

  const syndOptions = syndicateSelectOptions();
  const bookieOptions = bookieSelectOptions();
  return (
    <>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col>{dateRangePicker}</Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    onChange={(value) => {
                      setSelectSyndicate(value);
                    }}
                    aria-label="Default select example"
                    placeholder="View Bet report For... (Syndicate)"
                    options={syndOptions}
                  />
                  <ButtonGroup>
                    {syndReportSubmit} {syndReportLink}
                  </ButtonGroup>
                </Col>
                <Col>
                  <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    onChange={(value) => {
                      setSelectBookie(value);
                    }}
                    aria-label="Default select example"
                    placeholder="Choose bookie to see data at account level"
                    options={bookieOptions}
                    isMulti
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>{reportTable}</Row>
      <Row>{multiBookieTable}</Row>
    </>
  );
};

export default BetReport;
