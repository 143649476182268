const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    rtlName: 'لوحة القيادة',
    icon: 'tim-icons icon-components',
    layout: '/admin',
  },
  {
    path: '/valuebets',
    name: 'ValueBets',
    rtlName: 'لوحة القيادة',
    icon: 'tim-icons icon-tv-2',
    layout: '/admin',
  },
  {
    collapse: true,
    name: 'Syndicates',
    rtlName: 'إستمارات',
    icon: 'tim-icons icon-single-02',
    state: 'multicollapse',
    views: [
      {
        path: '/createaccount',
        name: 'Create Account',
        rtlName: 'لوحة القيادة',
        layout: '/admin',
        mini: 'C',
      },
      {
        path: '/syndicates',
        name: 'Syndicate Accounts',
        rtlName: 'لوحة القيادة',
        layout: '/admin',
        mini: 'S',
      },
    ],
  },
  {
    path: '/history',
    name: 'History',
    rtlName: 'لوحة القيادة',
    icon: 'tim-icons icon-notes',
    layout: '/admin',
  },
  {
    path: '/reporting',
    name: 'Reporting',
    rtlName: 'لوحة القيادة',
    icon: 'tim-icons icon-badge',
    layout: '/admin',
  },
  {
    path: '/analytics',
    name: 'Analytics',
    rtlName: 'لوحة القيادة',
    icon: 'tim-icons icon-money-coins',
    layout: '/admin',
  },
  {
    path: '/accountsToOpen',
    name: 'Accounts To Open',
    rtlName: 'لوحة القيادة',
    icon: 'tim-icons icon-spaceship',
    layout: '/admin',
  },
  {
    path: '/finalAccounts',
    name: 'Final Accounts',
    rtlName: 'لوحة القيادة',
    icon: 'tim-icons icon-time-alarm',
    layout: '/admin',
  },
  {
    path: '/turnover',
    name: 'Turnover',
    rtlName: 'لوحة القيادة',
    icon: 'tim-icons icon-coins',
    layout: '/admin',
  },
];

export default routes;
