import React, { useState } from 'react';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import {
  Button,
  Spinner,
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Table,
  TabContent,
  TabPane,
} from 'reactstrap';
import { axiosGet } from '../utils';

import { bookieListGenerator } from 'components/dropdownGenerator';
const bookies = bookieListGenerator('analytics');

const Analytics = () => {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [bookieAccounts, setBookieAccounts] = useState([]);
  const [daysBets, setDaysBets] = useState([]);
  const [potential, setPotential] = useState({ potential: 0, current: 0 });
  const [horizontalTabs, sethorizontalTabs] = useState('syndicateDaily');
  const [selectedDate, setSelectedDate] = useState(new Date());

  const headers = [
    { label: 'Partner', key: 'synd' },
    { label: 'Source', key: 'bookie' },
    { label: 'Balance', key: 'balance' },
    { label: 'Bet Type', key: 'betType' },
    { label: 'Number of Bets', key: 'numBets' },
    { label: 'Total Stake', key: 'stake' },
  ];

  const loadingSpinner = loading ? (
    <>
      {' '}
      <Spinner size="sm"></Spinner>
    </>
  ) : (
    'Load'
  );

  const hideAlert = () => {
    setAlert(null);
  };

  const getTodaysDate = () => {
    const today = new Date();
    const year = String(today.getFullYear());
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const changeActiveTab = (e, tabState, tabName) => {
    e.preventDefault();
    switch (tabState) {
      case 'horizontalTabs':
        sethorizontalTabs(tabName);
        break;
      case 'verticalTabsIcons':
        setverticalTabsIcons(tabName);
        break;
      case 'pageTabs':
        setpageTabs(tabName);
        break;
      case 'verticalTabs':
        setverticalTabs(tabName);
        break;
      default:
        break;
    }
  };

  const basicAlert = (message) => {
    setAlert(
      <ReactBSAlert
        style={{ display: 'block', marginTop: '-100px' }}
        title={`${message}`}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      ></ReactBSAlert>
    );
  };

  const getDaysBets = async () => {
    let bets;
    const formattedDate = getTodaysDate();

    const url = `https://api.valueservices.uk/horsebettingmx/getBets/${moment(
      selectedDate
    ).format('YYYY-MM-DD')}`;
    const response = await axiosGet(url);
    bets = response;
    if (response.name === 'AxiosError') {
      basicAlert(response.message);
      bets = [];
    }

    setDaysBets(bets);
  };

  const getBookieAccounts = async () => {
    setLoading(true);
    const accounts = await Promise.all(
      bookies.map(async (item) => {
        const url = `https://api.valueservices.uk/syndicateMX/getSyndicateAccounts/${item}/*/*`;

        const response = await axiosGet(url);
        if (response.name === 'AxiosError') {
          basicAlert(response.message);
          return [];
        }
        return response;
      })
    );
    setBookieAccounts(accounts);
    setLoading(false);
  };

  const displayTurnoverTable = () => {
    if (bookieAccounts && bookieAccounts.length > 0) {
      const bets = daysBets;

      const filteredActiveAccounts = bookieAccounts.map((account) => {
        const activeAccounts = account.filter((a) => a.type === 'Active');
        return activeAccounts;
      });

      const createTables = filteredActiveAccounts.map((activeAccounts) => {
        let bookie;
        const tableContent = activeAccounts.map((account) => {
          const accountBets = bets.filter(
            (bet) =>
              bet.bookie === account.bookie &&
              bet.name === account.name &&
              bet.status === 'placed'
          );

          bookie = account.bookie;
          const name = account.displayName;
          const betType = account.betTypes[0].betTypeName;
          const accountStake = account.betTypes[0].stake;
          const expectedMaxBets = account.maxBets;
          const successfulBets = accountBets.length;
          const expectedStakePerBet =
            account.betTypes[0].betTypeName === 'lucky15'
              ? 15 * accountStake
              : account.betTypes[0].betTypeName === 'patent'
              ? 7 * accountStake
              : account.betTypes[0].betTypeName === 'trixie'
              ? 4 * accountStake
              : 1 * accountStake;
          const totalExpectedStake = expectedStakePerBet * expectedMaxBets;
          const totalPlacedStake = accountBets.reduce((accumulator, object) => {
            return accumulator + object.stake;
          }, 0);
          const betDifference = expectedMaxBets - successfulBets;
          const rowColour =
            betDifference === 0
              ? 'table-success'
              : betDifference > 0 && betDifference < expectedMaxBets
              ? 'table-warning'
              : 'table-danger';

          return {
            name,
            bookie,
            betType,
            expectedMaxBets,
            successfulBets,
            expectedStakePerBet,
            totalExpectedStake,
            totalPlacedStake,
            rowColour,
          };
        });

        if (tableContent && tableContent.length > 0) {
          return (
            <>
              <h3 className="table-heading">{bookie}</h3>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th className="bookie-cell">Bookie</th>
                    <th className="synd-cell">Syndicate</th>
                    <th>Bet Type</th>
                    <th>Expected Max Bets</th>
                    <th>Successful Bets</th>
                    <th>Expected Stake per Bet</th>
                    <th>Total Expected Stake</th>
                    <th>Total Stake Placed </th>
                  </tr>
                </thead>
                <tbody>
                  {tableContent.map(
                    ({
                      bookie,
                      name,
                      betType,
                      expectedMaxBets,
                      successfulBets,
                      expectedStakePerBet,
                      totalExpectedStake,
                      totalPlacedStake,
                      rowColour,
                    }) => {
                      return (
                        <tr className={rowColour}>
                          <td className="bookie-cell"> {bookie}</td>
                          <td className="synd-cell">{name}</td>
                          <td>{betType}</td>
                          <td>{expectedMaxBets}</td>
                          <td>{successfulBets}</td>
                          <td>{expectedStakePerBet.toFixed(2)}</td>
                          <td>{totalExpectedStake.toFixed(2)}</td>
                          <td>{totalPlacedStake.toFixed(2)}</td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </Table>
            </>
          );
        }
        return <></>;
      });

      return createTables;
    }
    return <></>;
  };

  const loadSyndicates = async () => {
    setLoading(true);
    const accounts = await Promise.all(
      bookies.map(async (item) => {
        const url = `https://api.valueservices.uk/syndicateMX/getSyndicateAccounts/${item}/*/*`;
        const response = await axiosGet(url);
        if (response.name === 'AxiosError') {
          basicAlert(response.message);
          return [];
        }
        return response;
      })
    );
    setAccounts(accounts);
    setLoading(false);
  };

  const tableItem = ({
    bookie,
    numAccounts,
    placingAccounts,
    stakePA,
    betsPA,
    potentialTotalStake,
    currentTotalStake,
  }) => {
    return (
      <tr>
        <td>{bookie}</td>
        <td>{numAccounts}</td>
        <td>{placingAccounts}</td>
        <td>{stakePA.toFixed(0)}</td>
        <td>{betsPA.toFixed(0)}</td>
        <td>{potentialTotalStake}</td>
        <td>{currentTotalStake}</td>
      </tr>
    );
  };

  const totalTable = ({ current, potential }) => {
    return (
      <tr>
        <td>
          Current Max Stake: <b>{current}</b>
        </td>
        <td>
          Potential Max Stake: <b>{potential}</b>
        </td>
      </tr>
    );
  };

  const currentAccounts = () => {
    const totalReduced = [];
    if (accounts && accounts.length > 0) {
      const tableItems = accounts.map((acc) => {
        const bookie = acc[0].bookie;
        acc = acc.filter((a) => a.type === 'Active');
        const numAccounts = acc.length;
        const placingAccounts = acc.filter((a) => a.enabled === true).length;
        if (numAccounts === 0) {
          return tableItem({
            bookie,
            numAccounts: 0,
            placingAccounts: 0,
            stakePA: 0,
            betsPA: 0,
            potentialTotalStake: 0,
            currentTotalStake: 0,
          });
        }
        if (numAccounts === 1) {
          const stakeMultiplier =
            acc[0].betTypes[0].betTypeName === 'lucky15'
              ? 15
              : acc[0].betTypes[0].betTypeName === 'patent'
              ? 7
              : acc[0].betTypes[0].betTypeName === 'trixie'
              ? 4
              : 1;
          return tableItem({
            bookie,
            numAccounts,
            placingAccounts,
            stakePA:
              acc[0].betTypes[0].stake * stakeMultiplier * acc[0].maxBets,
            betsPA: acc[0].maxBets,
            potentialTotalStake:
              acc[0].betTypes[0].stake * stakeMultiplier * acc[0].maxBets,
            currentTotalStake:
              acc[0].enabled === true
                ? acc[0].betTypes[0].stake * stakeMultiplier * acc[0].maxBets
                : 0,
          });
        }
        const reducedData = acc.reduce((a, b) => {
          const stakeMultiplier =
            b.betTypes[0].betTypeName === 'lucky15'
              ? 15
              : b.betTypes[0].betTypeName === 'patent'
              ? 7
              : b.betTypes[0].betTypeName === 'trixie'
              ? 4
              : 1;

          return {
            bookie: a.bookie ? a.bookie : b.bookie,
            numAccounts,
            placingAccounts,
            stakePA: a.stakePA
              ? a.stakePA +
                (b.betTypes[0].stake * stakeMultiplier * b.maxBets) /
                  numAccounts
              : (b.betTypes[0].stake * stakeMultiplier * b.maxBets) /
                numAccounts,
            betsPA: a.betsPA
              ? a.betsPA + b.maxBets / numAccounts
              : b.maxBets / numAccounts,
            potentialTotalStake: a.potentialTotalStake
              ? a.potentialTotalStake +
                b.betTypes[0].stake * stakeMultiplier * b.maxBets
              : b.betTypes[0].stake * stakeMultiplier * b.maxBets,
            currentTotalStake: a.currentTotalStake
              ? a.currentTotalStake +
                (b.enabled === true
                  ? b.betTypes[0].stake * stakeMultiplier * b.maxBets
                  : 0)
              : b.enabled === true
              ? b.betTypes[0].stake * stakeMultiplier * b.maxBets
              : 0,
          };
        });
        if (reducedData) {
          totalReduced.push(reducedData);
          return tableItem(reducedData);
        }
      });

      const totalItem = totalReduced.reduce((a, b) => ({
        potential: a.potential
          ? a.potential + b.potentialTotalStake
          : b.potentialTotalStake,
        current: a.current
          ? a.current + b.currentTotalStake
          : b.currentTotalStake,
      }));
      tableItems.push(totalTable(totalItem));
      return tableItems;
    }
    return <></>;
  };

  const reportDownload = () => {
    const flatData = accounts
      .flatMap((arr) => arr)
      .filter((a) => a.type === 'Active');

    const table = flatData.map((data) => {
      return {
        synd: data.name.replace('#', ' '),
        bookie: data.bookie,
        balance: data.balance,
        betType: data.betTypes[0].betTypeName,
        numBets: daysBets.filter(
          (db) =>
            db.name === data.name &&
            db.bookie === data.bookie &&
            db.status === 'placed'
        ).length,
        stake: daysBets
          .filter(
            (db) =>
              db.name === data.name &&
              db.bookie === data.bookie &&
              db.status === 'placed'
          )
          .reduce((a, b) => a + b.stake, 0),
      };
    });

    return accounts.length && daysBets.length > 0 ? (
      <Button color="secondary">
        <CSVLink filename={`Stake Report`} data={table} headers={headers}>
          Download Days Stake Report
        </CSVLink>
      </Button>
    ) : (
      <Button disabled> Download Days Stake Report</Button>
    );
  };

  return (
    <>
      <div className="content">
        <Nav className="nav-pills-info" pills>
          <NavItem>
            <NavLink
              data-toggle="tab"
              href="#pablo"
              className={horizontalTabs === 'syndicateDaily' ? 'active' : ''}
              onClick={(e) => {
                changeActiveTab(e, 'horizontalTabs', 'syndicateDaily');
              }}
            >
              Syndicate Daily
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              data-toggle="tab"
              href="#pablo"
              className={horizontalTabs === 'activePrediction' ? 'active' : ''}
              onClick={(e) => {
                changeActiveTab(e, 'horizontalTabs', 'activePrediction');
              }}
            >
              Predicted Turnover
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent className="tab-space" activeTab={horizontalTabs}>
          <TabPane tabId="syndicateDaily">
            <Card>
              <DatePicker
                selected={selectedDate}
                className="form-control"
                onChange={(date) => {
                  setSelectedDate(date);
                }}
                dateFormat="dd/MM/yyyy"
              />
              <Col className="mr-auto ml-auto mt-3 mb-2" md="12">
                <CardTitle tag="h3">Syndicate Daily</CardTitle>

                <CardBody>
                  <Row>
                    <Button
                      onClick={async () => {
                        await loadSyndicates(true);
                        await getDaysBets();
                      }}
                    >
                      {loadingSpinner}
                    </Button>
                  </Row>
                  <hr />
                  <Row>
                    <Table striped bordered>
                      <thead>
                        <tr>
                          <th>Bookie</th>
                          <th>No. accounts should be placing</th>
                          <th>No. accounts that are placing</th>
                          <th>Stake per account</th>
                          <th>Bets per account</th>
                          <th>Potential Total stake</th>
                          <th>Current Total stake</th>
                        </tr>
                      </thead>
                      <tbody>{currentAccounts()}</tbody>
                    </Table>
                  </Row>
                </CardBody>
              </Col>
              {reportDownload()}
            </Card>
          </TabPane>
          <TabPane tabId="activePrediction">
            <Card>
              <Col className="mr-auto ml-auto mt-3 mb-2" md="12">
                <CardTitle tag="h3">Placement Data</CardTitle>
                <CardBody>
                  <Row>
                    <Button
                      onClick={async () => {
                        await getBookieAccounts();
                        await getDaysBets();
                      }}
                    >
                      {loadingSpinner}
                    </Button>
                  </Row>
                  <hr />
                  <Row>{displayTurnoverTable()};</Row>
                </CardBody>
              </Col>
            </Card>
          </TabPane>
        </TabContent>
      </div>
      {alert}
    </>
  );
};

export default Analytics;
