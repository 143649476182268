import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import axiosRetry from 'axios-retry';
import axios from 'axios';
import { ButtonGroup, Button, Spinner, CardTitle, Row, Col } from 'reactstrap';

import { syndicateListGenerator } from 'components/dropdownGenerator';
const syndArr = syndicateListGenerator();

axiosRetry(axios, { retries: 10 });

const BalanceReport = () => {
  const [loading, setLoading] = useState(false);
  const [syndBalanceReport, setSyndBalanceReport] = useState([]);
  const headers = [
    { label: 'Partner', key: 'synd' },
    { label: 'Source', key: 'bookie' },
    { label: 'Balance', key: 'balance' },
  ];

  const loadingSpinner = loading ? (
    <>
      {' '}
      <Spinner size="sm"></Spinner>
    </>
  ) : null;

  const handleGetAllSubmitClick = async () => {
    setLoading(true);
    const user = await Auth.currentSession();
    const token = user.getIdToken().getJwtToken();
    let array;
    array = await Promise.all(
      syndArr.map(async (item) => {
        try {
          const res = await axios.get(
            `https://api.valueservices.uk/syndicateMX/getAccountsByName/${item
              .replace(' ', '%23')
              .toLowerCase()}`,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          return res.data;
        } catch (e) {
          basicAlert(`There was an issue with that request: ${e}`);
          return [];
        }
      })
    );

    const flatData = array.flatMap((arr) => arr);
    // setSourceData(flatData);

    const table = flatData.map((data) => {
      return {
        synd: data.name.replace('#', ' '),
        bookie: data.bookie,
        balance: data.balance,
      };
    });

    const grandTotal = flatData
      .map((item) => item.balance)
      .reduce((a, b) => a + b, 0);

    const totals = {
      synd: '',
      bookie: 'TOTAL',
      balance: grandTotal.toFixed(2),
    };

    table.push(totals);

    setSyndBalanceReport(table);
    setLoading(false);
  };

  const GetAllSubmitButton = (
    <Button color="secondary" onClick={handleGetAllSubmitClick}>
      Submit {loadingSpinner}
    </Button>
  );

  const reportDownload =
    syndBalanceReport.length > 0 ? (
      <Button color="secondary">
        <CSVLink
          filename={`Balance_Report_${moment().format('YYYY-MM-DD')}`}
          data={syndBalanceReport}
          headers={headers}
        >
          Download Balance Report
        </CSVLink>
      </Button>
    ) : (
      <Button disabled>Download Balance Report</Button>
    );

  return (
    <>
      <Row>
        <Col md="4">
          <hr />
          <CardTitle tag="h3">Request Balance Report </CardTitle>
          <Row>
            <Col>
              <ButtonGroup>
                {GetAllSubmitButton}
                {reportDownload}
              </ButtonGroup>
            </Col>
          </Row>
          <Row></Row>
        </Col>
      </Row>
    </>
  );
};

export default BalanceReport;
