import React, { useState } from 'react';
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';
import { timeToMillis, millisToTime } from 'utils';

const TimeSlider = ({
  startTime,
  endTime,
  disabled,
  updateNewAccountDetails,
  sliderProps,
}) => {
  const [currentTime, setCurrentTime] = useState([startTime, endTime]);

  const timeUpdate = (value) => {
    setCurrentTime([millisToTime(value[0]), millisToTime(value[1])]);
    updateNewAccountDetails('time', [
      millisToTime(value[0]),
      millisToTime(value[1]),
    ]);
  };

  return (
    <div className="m-2">
      <div>
        <span>{`${currentTime[0]} - `}</span>
        <span>{`${currentTime[1]}`}</span>
        <span>
          <Slider
            range
            disabled={disabled}
            allowCross={false}
            defaultValue={[
              timeToMillis(currentTime[0]),
              timeToMillis(currentTime[1]),
            ]}
            pushable={900000}
            onChange={timeUpdate}
            {...sliderProps}
          />
        </span>
      </div>
    </div>
  );
};

export default TimeSlider;
