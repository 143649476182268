import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { Card, CardBody, Col, Table } from 'reactstrap';

const SourceElement = ({ activityData }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const sourceArray = activityData;

    const sortedData = sourceArray
      .sort((a, b) => a.bookie.localeCompare(b.bookie))
      .map((entry) => {
        return {
          bookie: entry.bookie,
          time: entry.updatedAt,
          sels: entry.selectionCount,
          hasIre: entry.hasIre,
          hasErrors: entry.hasErrors,
        };
      });

    const sorted = sourceArray
      .filter((item) => item.hasIre === true)
      .map((item) => item.selectionCount)
      .sort((a, b) => a - b);

    const sortedNoIre = sourceArray
      .filter((item) => item.hasIre === false)
      .map((item) => item.selectionCount)
      .sort((a, b) => a - b);

    const median =
      sorted.length % 2 === 0
        ? (sorted[sorted.length / 2 - 1] + sorted[sorted.length / 2]) / 2
        : sorted[Math.floor(sorted.length / 2)];

    const medianNoIre =
      sortedNoIre.length % 2 === 0
        ? (sortedNoIre[sortedNoIre.length / 2 - 1] +
            sortedNoIre[sortedNoIre.length / 2]) /
          2
        : sortedNoIre[Math.floor(sortedNoIre.length / 2)];

    const upperLim = median * 1.05;
    const lowerLim = median * 0.9;

    const noIreUpperLim = medianNoIre * 1.05;
    const noIreLowerLim = medianNoIre * 0.9;

    const finalData = sortedData.map((item) => {
      const startTime = moment(item.time);
      const endTime = moment(new Date());
      const duration = moment.duration(endTime.diff(startTime));
      const formatTime = moment(item.time).format('HH:mm:ss');

      const rowClass =
        duration.asMinutes() >= 10 || item.sels === 0
          ? 'table-danger'
          : (item.hasIre === true &&
              (item.sels < lowerLim || item.sels > upperLim)) ||
            (item.hasIre === false &&
              (item.sels < noIreLowerLim || item.sels > noIreUpperLim))
          ? 'table-warning'
          : 'table-success';

      const ireIcon = item.hasIre ? 'bi bi-flag-fill' : 'bi bi-flag';
      const errorIcon = item.hasErrors
        ? 'bi bi-exclamation-circle-fill'
        : 'bi bi-check-circle-fill';

      const highlightedRow = {
        bookie: item.bookie,
        className: 'text-center',
        selections: `${item.sels}`,
        time: formatTime,
        parentClassName: rowClass,
        ireClassName: ireIcon,
        errorClassName: errorIcon,
        hasErrors: item.hasErrors,
      };

      return highlightedRow;
    });

    setItems((prevState) => {
      if (prevState.length === 0) {
        return [...finalData];
      }
      return prevState.map((feature, index) => {
        const newData = finalData.find((fd) => fd.bookie === feature.bookie);
        if (newData && newData.bookie === feature.bookie) {
          feature = {
            ...feature,
            selections: newData.selections,
            time: newData.time,
            parentClassName: newData.parentClassName,
            errorClassName: newData.errorClassName,
            hasErrors: newData.hasErrors,
          };
        }
        return feature;
      });
    });
  }, [activityData]);

  const chunkItems = () => {
    const chunkSize = Math.ceil(items.length / 4);
    let html = [];
    for (let i = 0; i < items.length; i += chunkSize) {
      const chunk = items.slice(i, i + chunkSize);
      html.push(
        <Col md="3">
          <Card>
            <CardBody>
              {chunk && chunk.length > 0 && (
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="header">Bookie</th>
                      <th className="header">Sel</th>
                      <th className="header">Updated</th>
                    </tr>
                  </thead>
                  <tbody>
                    {chunk.map((item) => (
                      <tr className={item.parentClassName}>
                        <td>
                          <i
                            style={
                              item.hasErrors
                                ? { color: '#7F535A' }
                                : { color: '#214655' }
                            }
                            className={item.errorClassName}
                          ></i>
                          &nbsp;
                          <i className={item.ireClassName}></i>
                          &nbsp;&nbsp;
                          {item.bookie}
                        </td>
                        <td className="text-center">{item.selections}</td>
                        <td>{item.time}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </CardBody>
          </Card>
        </Col>
      );
    }
    return html;
  };

  return <>{items && items.length > 0 && chunkItems(items)}</>;
};

export default SourceElement;
