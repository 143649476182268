import React, { useState, useEffect } from 'react';
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';
import { timeToMillis } from 'utils';

const DumbTimeSlider = ({
  startTime,
  endTime,
  disabled,
  displayDays,
  sliderProps,
}) => {
  const [time, setTime] = useState([startTime, endTime]);

  const currentDays = displayDays();

  useEffect(() => {
    setTime([startTime, endTime]);
  }, [startTime, endTime]);

  return (
    <div className="m-2">
      <div>
        <span>{`${time[0]} - `}</span>
        <span>{`${time[1]} `}</span>
        <span>
          <Slider
            range
            disabled={disabled}
            allowCross={false}
            defaultValue={[timeToMillis(time[0]), timeToMillis(time[1])]}
            pushable={900000}
            value={[timeToMillis(time[0]), timeToMillis(time[1])]}
            {...sliderProps}
          />
        </span>

        <div style={{ marginTop: '25px' }}>{currentDays}</div>
      </div>
    </div>
  );
};

export default DumbTimeSlider;
