import React, { useState } from 'react';
import {
  ButtonGroup,
  Button,
  Spinner,
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
  Table,
} from 'reactstrap';
import { axiosPut, axiosGet } from '../utils';

import { bookieListGenerator } from 'components/dropdownGenerator';
import { syndicateListGenerator } from 'components/dropdownGenerator';
const bookies = bookieListGenerator('accountsToOpen');
const syndicates = syndicateListGenerator('accountsToOpen');

const AccountsToOpen = () => {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [accounts, setAccounts] = useState([]);

  const loadingSpinner = loading ? (
    <>
      {' '}
      <Spinner size="sm"></Spinner>
    </>
  ) : (
    'Load'
  );

  const hideAlert = () => {
    setAlert(null);
  };

  const basicAlert = (message) => {
    setAlert(
      <ReactBSAlert
        style={{ display: 'block', marginTop: '-100px' }}
        title={`${message}`}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      ></ReactBSAlert>
    );
  };

  const loadSyndicates = async () => {
    setLoading(true);
    const accounts = await Promise.all(
      syndicates.map(async (item) => {
        const url = `https://api.valueservices.uk/syndicateMX/getAccountsByName/${item
          .toLowerCase()
          .replace(/ /g, '%23')}`;
        let response = await axiosGet(url);
        if (response.name === 'AxiosError') {
          basicAlert(response.message);
          return [];
        }
        if (response.length === 0) {
          response = [
            {
              days: [],
              maxBets: 0,
              name: item.toLowerCase().replace(' ', '#'),
              enabled: false,
              startTime: '',
              displayName: item,
              balance: 0,
              password: '',
              proxy: '0.0.0.0',
              endTime: '',
              updatedAt: '',
              notes: [],
              bookie: 'defaultBookie',
              username: '',
              betTypes: [],
              type: 'Inactive',
            },
          ];
        }
        return response;
      })
    );
    setAccounts(accounts);
    setLoading(false);
  };

  const tableItem = ({ bookie, name, stake }) => {
    return (
      <tr>
        <td>{bookie}</td>
        <td>{name}</td>
        <td>{stake}</td>
      </tr>
    );
  };

  const totalTable = ({ current, potential }) => {
    return (
      <tr>
        <td>
          Current Max Stake: <b>{current}</b>
        </td>
        <td>
          Potential Max Stake: <b>{potential}</b>
        </td>
      </tr>
    );
  };

  const currentAccounts = () => {
    const missingAccounts = [];

    if (accounts && accounts.length > 0) {
      for (let i = 0; i < accounts.length; i += 1) {
        const syndicate = accounts[i];
        if (syndicate && syndicate.length > 0) {
          const proxy = syndicate[0].proxy;
          if (proxy) {
            const missingBookies = bookies.filter(
              (b) => !syndicate.find((s) => b.name === s.bookie)
            );
            const tempMissingAccounts = missingBookies.map((mb) => ({
              bookie: mb.name,
              name: syndicate[0].displayName,
              stake: mb.stake,
            }));
            missingAccounts.push(...tempMissingAccounts);
          }
        }
      }
    }

    const sortedAccounts = missingAccounts.sort((a, b) => b.stake - a.stake);
    const tableItems = sortedAccounts.map((acc) => {
      return tableItem(acc);
    });

    if (tableItems) {
      return tableItems;
    }
    return <></>;
  };

  return (
    <>
      <div className="content">
        <Card>
          <Col className="mr-auto ml-auto mt-3 mb-2" md="12">
            <CardTitle tag="h3">Account Value</CardTitle>
            <CardBody>
              <Row>
                <Button
                  onClick={async () => {
                    await loadSyndicates();
                  }}
                >
                  {loadingSpinner}
                </Button>
              </Row>
              <Row>
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>Bookie</th>
                      <th>Name</th>
                      <th>Accounts daily turnover (£)</th>
                    </tr>
                  </thead>
                  <tbody>{currentAccounts()}</tbody>
                </Table>
              </Row>
            </CardBody>
          </Col>
        </Card>
      </div>
      {alert}
    </>
  );
};

export default AccountsToOpen;
