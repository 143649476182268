/*eslint-disable*/
import React, { useState } from 'react';
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  useFlexLayout,
} from 'react-table';
import { Buffer } from 'buffer';
import classnames from 'classnames';
// A great library for fuzzy filtering/sorting items
import { matchSorter } from 'match-sorter';
// react plugin used to create DropdownMenu for selecting items
import Select from 'react-select';
// reactstrap components
import {
  FormGroup,
  Input,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';

const AWS = require('aws-sdk');
AWS.config.update({
  accessKeyId: 'AKIA4PREI6VL6NAIX6UM',
  secretAccessKey: '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',
  region: 'eu-west-1',
});

let albumBucketName = 'vs-screenshots';
let s3 = new AWS.S3({ params: { Bucket: 'vs-screenshots' } });

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <FormGroup>
      <Input
        type="email"
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={``}
      />
    </FormGroup>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
  const [modal, setModal] = useState(false);
  const [shot, setShot] = useState(null);
  const [numberOfRows, setNumberOfRows] = React.useState(10);
  const [pageSelect, handlePageSelect] = React.useState(0);
  const toggle = () => setModal(!modal);

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    pageOptions,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      autoResetSortBy: false,
      autoResetPage: false,
      autoResetFilters: false,
      initialState: {
        pageSize: 50,
        pageIndex: 0,
        sortBy: [
          {
            id: 'time',
            desc: true,
          },
        ],
      },
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination,
    useFlexLayout
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // const firstPageRows = rows.slice(0, 10);
  let pageSelectData = Array.apply(null, Array(pageOptions.length)).map(
    function () {}
  );
  let numberOfRowsData = [5, 10, 20, 25, 50, 100];

  const screenShotModal = () => {
    return (
      <div>
        {/* <Button color="danger" onClick={toggle}>
          Click Me
        </Button> */}
        <Modal isOpen={modal} toggle={toggle} size={'xl'} fade={true}>
          <ModalHeader toggle={toggle}>Error Screenshot</ModalHeader>
          <ModalBody>
            <div>
              <img alt="Screenshot" src={shot} />{' '}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  };

  const modecomp = screenShotModal();

  const screenShot = async (name, bookie) => {
    var params = {
      Bucket: 'vs-screenshots',
      Prefix: `${name}/${bookie}`,
    };
    s3.getObject({
      Bucket: 'vs-screenshots',
      Key: params.Prefix,
    })
      .promise()
      .then((result) => {
        const buffer = Buffer.from(result.Body);
        const base64ImageData = buffer.toString('base64');
        const imgSrc = 'data:image/png;base64,' + base64ImageData;

        setShot(imgSrc);
      });
  };

  return (
    <>
      {modecomp}
      <div className="ReactTable -striped -highlight">
        <div className="pagination-top"></div>
        <table {...getTableProps()} className="rt-table">
          <thead className="rt-thead -header">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                {headerGroup.headers.map((column, key) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={classnames('rt-th rt-resizable-header', {
                      '-cursor-pointer': headerGroup.headers.length !== key,
                      '-sort-asc': column.isSorted && !column.isSortedDesc,
                      '-sort-desc': column.isSorted && column.isSortedDesc,
                    })}
                  >
                    <div className="rt-resizable-header-content">
                      {column.render('Header')}
                    </div>
                    {/* Render the columns filter UI */}
                    <div>
                      {headerGroup.headers.length === key
                        ? null
                        : column.canFilter
                        ? column.render('Filter')
                        : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="rt-tbody">
            {page.map((row, i) => {
              prepareRow(row);
              // console.log(row);

              return (
                <div
                  {...row.getRowProps()}
                  className={classnames(
                    'rt-tr',
                    { ' -odd': i % 2 === 0 },
                    { ' -even': i % 2 === 1 }
                  )}
                  onClick={() => {
                    if (
                      row.original.status === 'failed' ||
                      row.original.status === 'rejected'
                    ) {
                      screenShot(row.original.name, row.original.bookie);
                      toggle();
                    }
                  }}
                >
                  {row.cells.map((cell) => {
                    let bgColor, textColor;

                    if (cell.column.Header === 'Status') {
                      bgColor =
                        cell.value === 'placed'
                          ? '#1C8680'
                          : cell.value === 'rejected'
                          ? '#D7A31A'
                          : cell.value === 'placing'
                          ? 'black'
                          : '#7F535A';

                      return (
                        <div
                          {...cell.getCellProps()}
                          className="rt-td"
                          style={{
                            flexGrow: 60,
                            flexShrink: 0,
                            flexBasis: 'auto',
                            backgroundColor: bgColor,
                            boxSizing: 'border-box',
                            width: 60,
                          }}
                        >
                          {cell.render('Cell')}
                        </div>
                      );
                    }

                    if (cell.column.Header === 'Message') {
                      return (
                        <div
                          {...cell.getCellProps()}
                          className="rt-td"
                          style={{
                            flexGrow: 60,
                            flexShrink: 0,
                            flexBasis: 'auto',
                            display: 'flex',
                            whiteSpace: 'break-spaces',
                            boxSizing: 'border-box',
                            width: 150,
                          }}
                        >
                          {cell.render('Cell')}
                        </div>
                      );
                    }

                    return (
                      <div {...cell.getCellProps()} className="rt-td">
                        {cell.render('Cell')}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </tbody>
        </table>
        <div className="pagination-bottom">
          <div className="-pagination">
            <div className="-previous">
              <button
                type="button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className="-btn"
              >
                Previous
              </button>
            </div>
            <div className="-center flex-nowrap">
              <Select
                className="react-select info mx-5 w-100"
                classNamePrefix="react-select"
                name="singleSelect"
                value={pageSelect}
                onChange={(value) => {
                  gotoPage(value.value);
                  handlePageSelect(value);
                }}
                options={pageSelectData.map((prop, key) => {
                  return {
                    value: key,
                    label: 'Page ' + (key + 1),
                  };
                })}
                placeholder="Select page"
              />
              <Select
                className="react-select info mx-5 w-100"
                classNamePrefix="react-select"
                name="singleSelect"
                value={numberOfRows}
                onChange={(value) => {
                  setPageSize(value.value);
                  setNumberOfRows(value);
                }}
                options={numberOfRowsData.map((prop) => {
                  return {
                    value: prop,
                    label: prop + ' rows',
                  };
                })}
                placeholder="Select #rows"
              />
            </div>
            <div className="-next">
              <button
                type="button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="-btn"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== 'number';

export default Table;
