import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from 'reactstrap';
import Select from 'react-select';
import axios from 'axios';
import moment from 'moment';
import { Auth } from 'aws-amplify';
import ReactTable from '../components/Tables/ReactTableValuebets';

import { bookieListGenerator } from 'components/dropdownGenerator';
const bookieArr = bookieListGenerator('vbDisplay');

const filterL15 = (originalData) => {
  const appliedFilters = originalData.filter((d) => {
    return d.odds >= 5.5 && d.odds <= 34 && parseFloat(d.valuePercentage) >= 90;
  });
  return appliedFilters;
};

const filterPat = (originalData) => {
  const appliedFilters = originalData.filter((d) => {
    const vp = parseInt(d.valuePercentage);
    const qs = parseInt(d.qs);

    return (
      (vp >= 100 && d.odds >= 1.7) ||
      (vp < 100 &&
        vp >= 94 &&
        qs >= 300 &&
        d.odds >= d.lastPrice &&
        d.odds >= 1.7)
    );
  });
  return appliedFilters;
};

const tickList = [
  { lowerPrice: 0, upperPrice: 2, tick: 0.01 },
  { lowerPrice: 2, upperPrice: 3, tick: 0.02 },
  { lowerPrice: 3, upperPrice: 4, tick: 0.05 },
  { lowerPrice: 4, upperPrice: 6, tick: 0.1 },
  { lowerPrice: 6, upperPrice: 10, tick: 0.2 },
  { lowerPrice: 10, upperPrice: 20, tick: 0.5 },
  { lowerPrice: 20, upperPrice: 30, tick: 1 },
  { lowerPrice: 30, upperPrice: 50, tick: 2 },
  { lowerPrice: 50, upperPrice: 100, tick: 5 },
  { lowerPrice: 100, upperPrice: 10000, tick: 10 },
];

const numberOfTicks = (firstPrice, secondPrice) => {
  const firstTick = tickList.filter(
    ({ lowerPrice, upperPrice }) =>
      firstPrice >= lowerPrice && firstPrice < upperPrice
  )[0];
  const secondTick = tickList.filter(
    ({ lowerPrice, upperPrice }) =>
      secondPrice >= lowerPrice && secondPrice < upperPrice
  )[0];

  let count = 0;
  while (firstPrice < secondPrice) {
    if (firstPrice < firstTick.upperPrice) {
      firstPrice += Number(firstTick.tick);
      firstPrice = Number(firstPrice.toFixed(2));
    } else {
      firstPrice += Number(secondTick.tick);
      firstPrice = Number(firstPrice.toFixed(2));
    }
    count += 1;
    if (count >= 5) {
      break;
    }
  }

  return count;
};

const isStrong = (vb) => {
  if (vb.atl && vb.atl.length > 1) {
    let numTicks = numberOfTicks(vb.atl[0].price, vb.atl[1].price);

    if (vb.atl[0].size <= 2) {
      let atlIndex = 0;
      const sizeLessThanBookie = vb.atl.reduce((a, b, currentIndex) => {
        if (b.size && b.price && vb.odds / b.price >= 0.94) {
          if (a + b.size > 2) {
            atlIndex = currentIndex;
          }
          return a + b.size;
        }
        return a;
      }, 0);
      if (sizeLessThanBookie <= 2) {
        return false;
      }
      if (atlIndex < 2) {
        numTicks = numberOfTicks(
          vb.atl[atlIndex].price,
          vb.atl[atlIndex + 1].price
        );
      } else {
        return false;
      }
    }
    if (numTicks < 5) {
      return true;
    }
    return false;
  }
  return false;
};

const bookieSelectOptions = () => {
  const defaultOption = [
    {
      value: '',
      label: 'Select bookies...',
      isDisabled: true,
    },
  ];
  const opts = bookieArr
    .sort((a, b) => a.localeCompare(b))
    .map((bookie) => {
      return { value: bookie, label: bookie };
    });

  return defaultOption.concat(opts);
};

const options = bookieSelectOptions();

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return 0;
  }, [delay]);
};

export const ValueBets = () => {
  const [data, setData] = useState([]);
  const [multipleSelect, setmultipleSelect] = useState([]);
  const [betTypeSelect, setBetTypeSelect] = useState([]);
  const [paused, setPaused] = useState(false);

  const handleVBPause = () => {
    if (!paused) {
      setPaused(true);
    } else {
      setPaused(false);
    }
  };

  const enableButton = () => {
    const button = paused ? (
      <Button color="success" className="float-right" onClick={handleVBPause}>
        <i className="bi bi-play-btn fa-lg" />
      </Button>
    ) : (
      <Button color="danger" className="float-right" onClick={handleVBPause}>
        <i className="bi bi-pause-btn fa-lg" />
      </Button>
    );

    return button;
  };

  const getVBs = useCallback(
    async (paused) => {
      if (!paused) {
        let bookies = [];
        bookies = multipleSelect;
        const user = await Auth.currentSession();
        const token = user.getIdToken().getJwtToken();

        const allVBs = await Promise.all(
          bookies.map(async (bookie) => {
            const res = axios.post(
              'https://api.valueservices.uk/horsemx/getValueBets',
              {
                bookie: bookie.value,
                vp: 0.9,
              },
              { headers: { Authorization: token } }
            );
            return res;
          })
        );
        const flatData = allVBs.flatMap((vb) => vb.data);

        // check all entries and dtermine weak selections

        const weakProcessedData = flatData.map((sel) => ({
          ...sel,
          isWeak: !isStrong(sel),
        }));

        const parsedData = weakProcessedData
          .filter((sel) =>
            moment(sel.updatedAt).isAfter(moment().subtract(5, 'minutes'))
          )
          .map((sel) => {
            return {
              bookie: sel.bookie,
              eventName: sel.eventName,
              name: sel.name,
              lastPrice: sel.lastPrice,
              odds: sel.odds,
              atlSize: sel.atl[0].size,
              atlPrice: sel.atl[0].price,
              updatedAt: moment(sel.updatedAt).fromNow(),
              valueBet: sel.valueBet,
              valuePercentage: (sel.vp * 100).toFixed(2),
              qs: sel.qs.toFixed(2),
              isWeak: sel.isWeak,
            };
          });

        setData(parsedData);
      } else {
        console.log('polling paused');
      }
    },
    [multipleSelect]
  );

  useEffect(() => {
    getVBs(paused);
  }, [getVBs]);

  useInterval(async () => {
    getVBs(paused);
  }, 30000);

  const finalData =
    betTypeSelect.value === 'lucky15'
      ? filterL15(data)
      : betTypeSelect.value === 'patent'
      ? filterPat(data)
      : betTypeSelect.value === undefined || betTypeSelect.value === 'no filter'
      ? data
      : data;

  return (
    <>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Valuebets</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      placeholder="Choose Bookies to Track..."
                      name="multipleSelect"
                      closeMenuOnSelect={false}
                      isMulti
                      value={multipleSelect}
                      onChange={(value) => setmultipleSelect(value)}
                      options={[{ options }]}
                    />
                  </Col>
                  <Col>
                    {' '}
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      name="Bet Type"
                      value={betTypeSelect}
                      onChange={(value) => setBetTypeSelect(value)}
                      options={[
                        {
                          value: '',
                          label: 'Bet Type',
                          isDisabled: true,
                        },
                        { value: 'lucky15', label: 'Lucky15' },
                        { value: 'patent', label: 'Patent' },
                        { value: 'no filter', label: 'No Filter' },
                      ]}
                      placeholder="Select Bet Type"
                    />
                  </Col>
                  <Col className="col col-lg-1 pause-btn">{enableButton()}</Col>
                </Row>
                <br />
                <Row className="mt-3">
                  <Col>
                    <ReactTable
                      data={finalData}
                      filterable
                      resizable={false}
                      columns={[
                        {
                          Header: 'Bookie',
                          accessor: 'bookie',
                          width: 140,
                          disableFilters: true,
                        },
                        {
                          Header: 'Event',
                          accessor: 'eventName',
                          width: 150,
                          disableFilters: true,
                        },
                        {
                          Header: 'Selection',
                          accessor: 'name',
                          width: 150,
                          disableFilters: true,
                        },
                        {
                          Header: 'Last Price',
                          accessor: 'lastPrice',
                          width: 80,
                          disableFilters: true,
                        },
                        {
                          Header: 'Bookie',
                          accessor: 'odds',
                          width: 80,
                          disableFilters: true,
                        },
                        {
                          Header: 'Betfair Lay',
                          accessor: 'atlPrice',
                          width: 90,
                          disableFilters: true,
                        },
                        {
                          Header: 'Betfair Lay £',
                          accessor: 'atlSize',
                          width: 100,
                          disableFilters: true,
                        },
                        {
                          Header: 'Value',
                          accessor: 'valueBet',
                          width: 80,
                          disableFilters: true,
                        },
                        {
                          Header: 'Value %',
                          accessor: 'valuePercentage',
                          width: 80,
                          disableFilters: true,
                        },
                        {
                          Header: 'QS',
                          accessor: 'qs',
                          width: 80,
                          disableFilters: true,
                        },
                        {
                          Header: 'Updated',
                          accessor: 'updatedAt',
                          width: 200,
                          disableFilters: true,
                        },
                      ]}
                      // defaultPageSize={50}
                      showPaginationTop
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
