import React, { useState } from 'react';
import data from '../__mocks__/reportRes';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
} from 'reactstrap';
import { CSVLink } from 'react-csv';
import SyndProfitReport from 'components/Accounts/SyndProfitReport';
import BetReport from '../components/Accounts/BetReport';
import BookieBetReport from 'components/Accounts/BookieBetReport';
import ComparisonReport from 'components/Accounts/ComparisonReport';
import BalanceReport from 'components/Accounts/BalanceReports';
import StakeReport from 'components/Accounts/StakeReports';
const fullBookieList = data.fullBookieList;
const fullSyndList = data.fullSyndList;

const Accounts = () => {
  const [selectBookie, setSelectBookie] = useState('');
  const [selectSyndicate, setSelectSyndicate] = useState('');
  const [horizontalTabs, sethorizontalTabs] = React.useState('syndicateBets');
  const syndAccounts = data.syndRes;
  const bookieAccounts = data.bookieRes;

  const changeActiveTab = (e, tabState, tabName) => {
    e.preventDefault();
    switch (tabState) {
      case 'horizontalTabs':
        sethorizontalTabs(tabName);
        break;
      case 'verticalTabsIcons':
        setverticalTabsIcons(tabName);
        break;
      case 'pageTabs':
        setpageTabs(tabName);
        break;
      case 'verticalTabs':
        setverticalTabs(tabName);
        break;
      default:
        break;
    }
  };

  const active = () => {
    const activeSyndAccounts = syndAccounts.filter(
      (account) =>
        selectSyndicate === account.displayName &&
        fullBookieList.indexOf(account.bookie) !== -1 &&
        account.type === 'Active'
    );
    return activeSyndAccounts.map((account) => {
      return { bookie: account.bookie, type: account.type };
    });
  };

  const inactive = () => {
    const inactiveSyndAccounts = syndAccounts.filter(
      (account) =>
        selectSyndicate === account.displayName &&
        fullBookieList.indexOf(account.bookie) !== -1 &&
        account.type === 'Inactive'
    );
    return inactiveSyndAccounts.map((account) => {
      return { bookie: account.bookie, type: account.type };
    });
  };

  const nonExistingSyndAccounts = () => {
    const syndicateBookies = syndAccounts.map((account) => {
      return account.bookie;
    });
    // console.log(syndicateBookies);
    const list = fullBookieList.filter(
      (bookie) => syndicateBookies.indexOf(bookie) === -1
    );
    const unopenedList = list.map((item) => {
      return { bookie: item, type: 'unopened' };
    });
    return unopenedList;
  };

  const activeAccounts = active();
  const inactiveAccounts = inactive();
  const unopenedAccounts = nonExistingSyndAccounts();

  const syndAccountReport = activeAccounts.concat(
    inactiveAccounts,
    unopenedAccounts
  );
  // console.log(syndAccountReport);

  const headers = [
    { label: 'Bookie', key: 'bookie' },
    { label: 'Status', key: 'type' },
  ];

  const bookieSelectOptions = () => {
    const bookieArr = ['bwin'];
    const selectOptions = bookieArr.sort().map((bookie) => {
      return (
        <option key={bookie} value={bookie}>
          {bookie}
        </option>
      );
    });
    return selectOptions;
  };

  const syndReportLink =
    selectSyndicate.length > 0 ? (
      <CSVLink
        filename={`${selectSyndicate}`}
        data={syndAccountReport}
        headers={headers}
      >
        Download Syndicate Report
      </CSVLink>
    ) : (
      ''
    );

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h3">Syndicate & Bookie Reports</CardTitle>
              </CardHeader>
              <CardBody>
                <Nav className="nav-pills-info" pills>
                  <NavItem>
                    <NavLink
                      data-toggle="tab"
                      href="#pablo"
                      className={
                        horizontalTabs === 'syndicateBets' ? 'active' : ''
                      }
                      onClick={(e) =>
                        changeActiveTab(e, 'horizontalTabs', 'syndicateBets')
                      }
                    >
                      Syndicate Bet Reports
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      data-toggle="tab"
                      href="#pablo"
                      className={
                        horizontalTabs === 'bookieBets' ? 'active' : ''
                      }
                      onClick={(e) =>
                        changeActiveTab(e, 'horizontalTabs', 'bookieBets')
                      }
                    >
                      Bookie Bet Reports
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      data-toggle="tab"
                      href="#pablo"
                      className={
                        horizontalTabs === 'syndicateProfit' ? 'active' : ''
                      }
                      onClick={(e) =>
                        changeActiveTab(e, 'horizontalTabs', 'syndicateProfit')
                      }
                    >
                      Syndicate Profit Reports
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      data-toggle="tab"
                      href="#pablo"
                      className={horizontalTabs === 'compare' ? 'active' : ''}
                      onClick={(e) =>
                        changeActiveTab(e, 'horizontalTabs', 'compare')
                      }
                    >
                      Compare Accounts
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      data-toggle="tab"
                      href="#pablo"
                      className={
                        horizontalTabs === 'balanceReport' ? 'active' : ''
                      }
                      onClick={(e) =>
                        changeActiveTab(e, 'horizontalTabs', 'balanceReport')
                      }
                    >
                      Balance Report
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      data-toggle="tab"
                      href="#pablo"
                      className={
                        horizontalTabs === 'stakeReport' ? 'active' : ''
                      }
                      onClick={(e) =>
                        changeActiveTab(e, 'horizontalTabs', 'stakeReport')
                      }
                    >
                      Stake Report
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent className="tab-space" activeTab={horizontalTabs}>
                  <TabPane tabId="syndicateBets">
                    <Row>
                      <Col>
                        <BetReport />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="bookieBets">
                    <Row>
                      <Col>
                        <BookieBetReport />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="compare">
                    <Row>
                      <Col>
                        <ComparisonReport />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="syndicateProfit">
                    <Row>
                      <Col>
                        <SyndProfitReport />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="balanceReport">
                    <Row>
                      <Col>
                        <BalanceReport />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="stakeReport">
                    <Row>
                      <Col>
                        <StakeReport />
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Accounts;
