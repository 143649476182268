import React from 'react';

import Chart from 'react-apexcharts';

const DoughnutChartApex = ({ bets, title }) => {
  // console.log(bets);

  const placed = bets.filter((bet) => bet.status === 'placed');
  const rejected = bets.filter((bet) => bet.status === 'rejected');
  const failed = bets.filter((bet) => bet.status === 'failed');
  const placing = bets.filter((bet) => bet.status === 'placing');

  const betCounts = [
    placed.length,
    rejected.length,
    failed.length,
    placing.length,
  ];

  const options = {
    tooltip: {
      enabled: false,
    },
    series: betCounts,
    labels: ['Placed', 'Rejected', 'Failed', 'Placing'],
    colors: ['#1C8680', '#D7A31A', '#7F535A', '#434343'],
    stroke: {
      show: true,
      width: 1,
      colors: ['#333333'],
    },
    plotOptions: {
      pie: {
        customScale: 1.0,
        expandOnClick: false,
        donut: {
          size: '50px',
          labels: {
            show: true,
            total: {
              show: false,
              showAlways: true,
            },
          },
        },
      },
    },

    legend: { show: false },
    dataLabels: {
      enabled: true,
      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        return series[seriesIndex];
      },
    },
  };
  const series = betCounts;

  return (
    <div>
      <h1>{title}</h1>
      <Chart type="donut" series={series} options={options} />
    </div>
  );
};

export default DoughnutChartApex;
